import i18n from '@/locale'

import router from './router/index.js'
import store from './store/index.js'

import { getToken } from '@/utils/auth' // get token from cookie

// 使用cdn引入elementui 在js中使用message
// this.$message.error('错误');

router.beforeEach(async (to, from, next) => {
  // 获取token
  const hasToken = getToken()
  // 公共访问的路由
  let adminRouter = to.meta.role && to.meta.role.indexOf('admin') > -1
  let isLogin = store.getters.isLogin
  if (hasToken) {
    // 获取用户角色
    const hasRoles = store.getters.roles && store.getters.roles.length > 0
    const roles = store.getters.roles
    document.title = to.meta.title ? to.meta.title : i18n.t('汉语可比')
    // 有token的情况下 去公共页 清空token 重新登入
    if (adminRouter) {
      // 判断是否是移动端
      const isMobile =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      if (!isMobile) {
        if (
          to.path == '/' ||
          to.path == '/login' ||
          to.path == '/hwjy-web/login'
        ) {
          switch (roles) {
            case 'student':
              next('/student')
              break
            case 'teacher':
              next('/teacher')
              break
            default:
              next()
              break
          }
          return
        }
      }
      next()
      if (to.path != '/wxBind' && to.path != '/' && isLogin) {
        await store.dispatch('user/logout')
      }

      // 后台页面
    } else {
      // 判断是否有用户角色 并且登入
      if (hasRoles && isLogin) {
        next()
      } else {
        try {
          const { userType } = await store.dispatch('user/getUserInfo', roles)
          const accessRoutes = await store.dispatch(
            'permission/generateRoutes',
            userType
          )
          for (let item of accessRoutes) {
            router.addRoute(item)
          }
          // router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          // 获取用户信息失败就会清空token值
          // this.$message.error(error || 'Has Error')
          await store.dispatch('user/logout')
          next('/login')
        }
      }
    }
    // 没有token的情况下
  } else {
    if (adminRouter) {
      next()
    } else {
      next('/')
    }
  }
})

router.afterEach(async (to, from) => {
  // to: 去的路由
  // from: 来的路由
  // 生成设备码
  if (to.path === '/login') {
    await store.dispatch('user/createDeviceId')
  }
})
