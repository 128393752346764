import i18n from '@/locale'
import router from '@/router'
import defaultSettings from '@/settings'
import store from '@/store'
import { browser } from '@/utils/browser.js'
import { Message } from 'element-ui'
import qs from 'qs'
const { proVersion } = defaultSettings
const data = browser()
// 在后端设置了跨域，则设置基地址
const instance = axios.create({
  // 请求基地址
  baseURL: '',
  timeout: 15000, // 默认值是 `0` (永不超时)
})
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = store.getters.token
    // 设备码
    const deviceId = store.getters.deviceId
    // 时区
    const timeZone = (new Date().getTimezoneOffset() / 60) * -1
    // console.log(getLanguageFn());
    //版本号
    config.headers['Accept-Language'] = localStorage.getItem('lang')
    //语言
    config.headers.proVersion = proVersion
    config.headers.manufacturer = data.manufacturer
    config.headers.deviceModel = data.deviceModel
    if (config.method == 'post' && !config.headers['content-type']) {
      config.headers['content-type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data, { encode: false, arrayFormat: 'repeat' })
    }

    if (deviceId) {
      config.headers.deviceId = deviceId
    } else {
      store.dispatch('user/createDeviceId')
      config.headers.deviceId = store.getters.deviceId
    }
    // 教师端登入 携带请求头
    if (
      config.url == '/api-tch/user/login' ||
      config.url == '/api-tch/user/loginByMobileValidCode' ||
      config.url == '/api-tch/user/loginByMobliePassword' ||
      config.url == '/api-tch/user/phoneBind'
    ) {
      config.headers.Authorization =
        'Basic aHdqeS13ZWItdGNoOiQyYSQxMCQ1YzBHT3BPbGVLNFZIUGJRb0plbE0uTS5XLnVGbTByUUMwcEg5aElwVGJmSDg1ZnZQQ0o3aQ=='
    } else if (
      // 学生端登入 携带请求头
      config.url == '/api-user/user/login' ||
      config.url == '/api-user/user/loginByMobliePassword' ||
      config.url == '/api-user/user/loginByMobileValidCode'
    ) {
      config.headers.Authorization =
        'Basic aHdqeS13ZWItdXNlcjokMmEkMTAkb1VzNHNwTnRWUVIwY09JN3MxZjFBdXRZeGN6UGdpSklJVFdLa0pxV0Exb1FUZGdwaEx0bGk='
    } else {
      if (
        token &&
        config.url != '/api-tch/user/code/slide' &&
        config.url != '/api-user/user/code/slide' &&
        config.url != '/api-user/common/getPhoneByNation' &&
        config.url != '/api-user/common/phoneZoneList'
      ) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }
    if (timeZone) {
      config.headers.timeZone = timeZone
    }
    config.headers.clientType = '014005'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
instance.interceptors.response.use(
  // response是服务器响应回来的所有数据
  (response) => {
    const res = response.data
    if (
      res.code != 200 &&
      res.code != 50021 &&
      res.code != 50023 &&
      res.code != -1 &&
      res.code != 401
    ) {
      Message.error(res.message || `Error-${res.code}`)
      return Promise.reject(response)
    } else if (res.code == 50021 || res.code == 50023) {
      //50021和50023属于B端账号登录设备达到上线，需要弹出强行验证
      store.commit('user/SET_COMPULSION_LOGIN', res.data)
    } else if (res.code == -1) {
      Message.error(res.message || `Error-${res.code}`)
    } else if (res.code == 401) {
      Message.error(res.message || `Error-${res.code}`)
      store.dispatch('user/logout')
      router.replace({
        path: '/login',
      })
    }
    return res
  },
  (error) => {
    console.log('err' + error)
    let { message } = error
    if (message == 'Network Error') {
      message = i18n.t('后端接口连接异常')
    } else if (message.includes('timeout')) {
      message = i18n.t('系统接口请求超时')
    } else if (message.includes('Request failed with status code')) {
      message =
        i18n.t('系统接口') + message.substr(message.length - 3) + i18n.t('异常')
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)
export default instance
