
const state = {
  isSignalReady: false,
  isTiwReady: false, // 白板是否已经Ready
  classInfo: {
    sdkAppId: 1400127140,
    userId: null,
    userSig: null,
    classId: null,
    nickname:null,
    imGroupId: null,
    dowmGradeTimes: 0,
    teachingType:null,
    lessonId:null,
    courseId: null,
  },
  current: {
    toolType: 0,
    fileInfo: {},
  },

  boardSetting: {},

  rightBarShow: false,
}

const mutations = {
  classInfo(state, payload) {
    state.classInfo.sdkAppId = payload.sdkAppId;
    state.classInfo.userId = payload.userId;
    state.classInfo.classId = payload.classId;
    state.classInfo.userSig = payload.userSig;
    state.classInfo.nickname = payload.nickname;
    state.classInfo.imGroupId = payload.imGroupId;
    state.classInfo.dowmGradeTimes = payload.dowmGradeTimes;
    state.classInfo.teachingType = payload.teachingType;
    state.classInfo.lessonId = payload.lessonId;
    state.classInfo.courseId = payload.courseId;
  },

  setSignalReady(state, payload) {
    state.isSignalReady = payload;
  },

  setTiwReady(state, payload) {
    state.isTiwReady = payload;
  },

  setRightBarShow(state, payload) {
    state.rightBarShow = payload;
  },

  setCurrentFile(state, payload) {
    state.current.fileInfo = payload;
  },

  updateBoardSetting(state, payload) {
    const t = state.current.boardSetting;
    state.current.boardSetting = {
      ...t,
      ...payload,
    };
  },
}

const actions = {
  setSignalReady({ commit }, payload) {
    commit('setSignalReady', payload);
  },

  setTiwReady({ commit }, payload) {
    commit('setTiwReady', payload);
  },

  setRightBarShow({ commit }, payload) {
    commit('setRightBarShow', payload);
  },

  setCurrentFile({ commit }, payload) {
    commit('setCurrentFile', payload);
  },

  updateBoardSetting({ commit }, payload) {
    commit('updateBoardSetting', payload);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
