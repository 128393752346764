import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import customEnUs from './lang/en-US'
import customZhCn from './lang/zh-CN'

Vue.use(VueI18n)

// 自动根据浏览器系统语言设置语言
const navLang = navigator.language
const localLang = navLang === 'zh-CN' ? navLang : 'en-US'
let lang = localStorage.getItem('lang') || localLang
Vue.config.lang = lang
if(!localStorage.getItem('lang')){
  localStorage.setItem('lang', lang)
}
// vue-i18n 6.x+写法
Vue.locale = () => {}
const messages = {
  'zh-CN': Object.assign(zhLocale, customZhCn),
  'en-US': Object.assign(enLocale, customEnUs)
}
const i18n = new VueI18n({
  locale: lang,
  messages
})

export default i18n

