module.exports = {

  title: 'Vue Admin Template',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  tagsView: false,
  proVersion: '1.0.7.1'//产品版本号
  // a 主版本号
  // b 次版本号（上限9）
  // c 迭代版本号（上限99）
  // d 修订版本号（上限99）
}
