import i18n from '@/locale'

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import { mobileRouter } from './mobileView'

// 默认路由   这里写官网的路由
export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/hycooby/index/index.vue'),
    hidden: true,
    meta: {
      title: i18n.t('官网'),
      role: 'admin',
    },
  },
  {
    path: '/login',
    component: () => import('@/views/hycooby/login/index.vue'),
    hidden: true,
    meta: {
      title: i18n.t('登入'),
      role: 'admin',
    },
  },
  {
    path: '/wxlogin',
    component: () => import('@/views/hycooby/login/wxLogin/index.vue'),
    hidden: true,
    meta: {
      title: i18n.t('微信登入'),
      role: 'admin',
    },
  },
  {
    path: '/wxBind',
    component: () => import('@/views/hycooby/login/wxLogin/wxBind.vue'),
    hidden: true,
    meta: {
      title: i18n.t('微信绑定'),
      role: 'admin',
    },
  },
  {
    path: '/agreement',
    component: () => import('@/views/hycooby/webService/agreement.vue'),
    hidden: true,
    meta: {
      title: i18n.t('隐私政策'),
      role: 'admin',
    },
  },
  {
    path: '/privacyPolicy',
    component: () => import('@/views/hycooby/webService/privacyPolicy.vue'),
    hidden: true,
    meta: {
      title: i18n.t('用户协议'),
      role: 'admin',
    },
  },
  {
    path: '/refund',
    component: () => import('@/views/hycooby/webService/refund.vue'),
    hidden: true,
    meta: {
      title: i18n.t('退款规则'),
      role: 'admin',
    },
  },
  {
    path: '/demo',
    component: () => import('@/views/hycooby/demo'),
    hidden: true,
    meta: {
      title: 'demo',
      role: 'admin',
    },
  },
  // 添加手机端的路由
  ...mobileRouter,
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push
let routerReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err)
}

export default new Router({
  mode: 'history', // require service support
  base: '/hwjy-web',
  scrollBehavior() {
    document.getElementById('#app').scrollTop = 0
    if (document.querySelector('.main-container')) {
      document.querySelector('.main-container').scrollTop = 0
    }
  },
  routes: constantRoutes,
})

// const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

// export default router
