<template>
  <div id="tags-view-container" class="tags-view-container">
    <scroll-pane
      ref="scrollPane"
      class="tags-view-wrapper"
      @scroll="handleScroll"
    >
      <!-- 
        @click.middle.native:监听鼠标中键 判断是否是固定tag 不是的话就删除当前tag
        @contextmenu.prevent.native 监听鼠标右键 打开当前tag的菜单
      -->
      <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="isActive(tag) ? 'active' : ''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        @contextmenu.prevent.native="openMenu(tag, $event)"
      >
        {{ tag.title }}
        <!-- 不是固定tag就显示删除按钮 -->
        <span
          v-if="!isAffix(tag)"
          class="el-icon-close"
          @click.prevent.stop="closeSelectedTag(tag)"
        />
      </router-link>
    </scroll-pane>
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li @click="refreshSelectedTag(selectedTag)">{{ $t('刷新') }}</li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">
        {{ $t('关闭') }}
      </li>
      <li @click="closeOthersTags">{{ $t('关闭其他') }}</li>
      <li @click="closeAllTags(selectedTag)">{{ $t('关闭所有') }}</li>
    </ul>
  </div>
</template>
<script>
import ScrollPane from './ScrollPane'
import path from 'path'
export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}, // 在右键打开菜单时赋值的
      affixTags: [],
    }
  },
  computed: {
    visitedViews() {
      // 获取所有的tags
      return this.$store.state.tagsView.visitedViews
    },
    routes() {
      // 获取所有的路由信息
      return this.$store.state.permission.routes
    },
  },
  watch: {
    // 触发添加tag 条件就是是否触发了路由 触发了路由 就添加路由 并且前往当前的tag
    $route() {
      this.addTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
  },
  mounted() {
    this.initTags()
    this.addTags()
  },
  methods: {
    // 是否是当前激活的状态
    isActive(route) {
      return route.path === this.$route.path
    },
    // 是否是固定tag
    isAffix(tag) {
      return tag.meta && tag.meta.affix
    },
    // 过滤出固定tag
    filterAffixTags(routes, basePath = '/') {
      let tags = []
      // 循环 和 递归 找出固定tag的值
      routes.forEach((route) => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta },
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    initTags() {
      // 获取固定tag的数量
      const affixTags = (this.affixTags = this.filterAffixTags(this.routes))
      // 循环添加到tag里
      for (const tag of affixTags) {
        // tag必须有name值
        if (tag.name) {
          // 这个是只添加tag  没有添加tag的缓存
          this.$store.dispatch('tagsView/addVisitedView', tag)
        }
      }
    },
    addTags() {
      const { name } = this.$route
      if (name) {
        // 这个是tag 和缓存都添加 上下两个add的方法就差别在这
        this.$store.dispatch('tagsView/addView', this.$route)
      }
      return false
    },
    moveToCurrentTag() {
      // 获取当前所有的tag的值
      const tags = this.$refs.tag
      this.$nextTick(() => {
        // 循环tag
        for (const tag of tags) {
          // 如果tag的path地址和当前的路由地址一致
          if (tag.to.path === this.$route.path) {
            // 就滚动到这个匹配到的path的tag位置
            this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            // 当查询不同时，则更新 就是参数不同的话就会更新当前的路由信息
            // pullPach 是全地址,携带路由参数  path 不带参数
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.$route)
            }
            break
          }
        }
      })
    },
    // 刷新页面 就是跳到另一个页面再跳转回来
    refreshSelectedTag(view) {
      this.$store.dispatch('tagsView/delCachedView', view).then(() => {
        const { fullPath } = view
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirect' + fullPath,
          })
        })
      })
    },
    // 删除选中的tag
    closeSelectedTag(view) {
      this.$store
        .dispatch('tagsView/delView', view)
        .then(({ visitedViews }) => {
          // 判断是否是当前激活的tag页面
          if (this.isActive(view)) {
            // 如果是当前选中的tag 删除后 跳到最后一个tag页面
            // 传入tag数组和当前的tag值
            this.toLastView(visitedViews, view)
          }
        })
    },
    // 关闭其他tag
    closeOthersTags() {
      // 先前往当前路由地址 当路由发生变化 就会触发addtag方法添加当前的tag信息
      this.$router.push(this.selectedTag)

      this.$store
        .dispatch('tagsView/delOthersViews', this.selectedTag)
        .then(() => {
          // 移动到当前的tag页面
          this.moveToCurrentTag()
        })
    },
    // 关闭所有的除了固定的tag和缓存
    closeAllTags(view) {
      this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
        // 如果是固定的tag 就直接return
        if (this.affixTags.some((tag) => tag.path === view.path)) {
          return
        }
        // 如果不是固定的tag 就去最后一个tag
        this.toLastView(visitedViews, view)
      })
    },
    // 去往最后一个tag页面
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        // 我加了一个首页固定的tag标签 就不会走到这里
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push({ path: '/teacher' })
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    // 在滚动的时候 关闭tag菜单
    closeMenu() {
      this.visible = false
    },
    // 滚动的监听事件
    handleScroll() {
      this.closeMenu()
    },
  },
}
</script>
<style lang="scss" scoped>
.tags-view-container {
  height: 34px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;
        &::before {
          content: '';
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>
<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
