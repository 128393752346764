import i18n from '@/locale'
import {
  AccountLoginApi,
  AccountLoginStu,
  PhoneLoginApi,
  PhoneLoginStu,
  getUserInfoApi,
  getUserInfoStu,
  loginByMobliePassword,
  loginByMobliePasswordStu,
} from '@/api/hycooby/login.js'
import { getRefreshToken, getToken, removeToken, setToken } from '@/utils/auth'
// import router, {
//   resetRouter
// } from '@/router'

import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { Message } from 'element-ui'

const state = {
  token: getToken() || '',
  refreshToken: getRefreshToken() || '',
  // 用户信息 如果页面刷新  就会重新请求用户信息接口获取数据
  // 所以不做持久化
  userInfo: null,
  roles: localStorage.getItem('roles') || '', // 登入后的用户角色
  deviceId: localStorage.getItem('deviceId') || '',
  areaCode: localStorage.getItem('areaCode') || '', // 手机区号
  loginStatus: '', // 储存登入信息 4009 需要唤醒滑动验证码 进行验证
  isLogin: false, // 是否登入标识
  buttons: [],
}

const mutations = {
  // 修改是否登入信息
  SET_ISLOGIN_STATUS: (state, isLogin) => {
    state.isLogin = isLogin
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESH_TOKEN: (state, refreshToken) => {
    state.refreshToken = refreshToken
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },

  SET_ROLES: (state, roles) => {
    state.roles = roles
    localStorage.setItem('roles', roles)
  },
  SET_DEVICEID: (state, deviceId) => {
    state.deviceId = deviceId
  },
  SET_AREA_CODE: (state, areaCode) => {
    state.areaCode = areaCode
  },
  SET_BUTTONS: (state, buttons) => {
    state.buttons = buttons
  },
  //设备上线，强制验证
  SET_COMPULSION_LOGIN: (state, compulsionLogin) => {
    state.loginStatus = compulsionLogin
  },
}

const actions = {
  setAreaCode({ commit }, areaCode) {
    commit('SET_AREA_CODE', areaCode)
  },
  // 账号密码  手机号密码 登入
  // 将业务代码重复的抽离出来 动态方法名去请求接口
  async accountLoginRes({ commit }, obj) {
    const { username, password, captchaVerification, zone, phone } =
      obj.userInfo
    const params =
      zone && phone
        ? { zone, mobile: phone, password, captchaVerification }
        : { username, password, captchaVerification }
    const loginApi = obj.apiName
    try {
      const response = await loginApi(params)
      const { data, message } = response
      if (data.status != 50021 && data.status != 50023) {
        commit('SET_LOGIN_STATUS', data.status)
      }
      if (data.status == '200') {
        Message.success(i18n.t('登入成功'))
        commit('SET_TOKEN', data.info.accessToken)
        setToken(data.info.accessToken)
        return response
      } else if (data.status == 50021 || data.status == 50023) {
        return Promise.reject(response)
      } else {
        // Message.error(message);
        return Promise.reject(response)
      }
    } catch (error) {
      // 提示登入失败
      // Message.error("登入失败");
      return Promise.reject(error)
    }
  },
  // 使用 async/await 替代 Promise 链
  async accountLogin({ commit, dispatch }, userInfo) {
    const { role } = userInfo
    // 将不同角色的登录逻辑分离到单独的函数中
    if (role === 'teacher') {
      return userInfo.zone && userInfo.phone
        ? dispatch('accountLoginRes', {
          apiName: loginByMobliePassword,
          userInfo: userInfo,
        })
        : dispatch('accountLoginRes', {
          apiName: AccountLoginApi,
          userInfo: userInfo,
        })
    } else if (role === 'student') {
      return userInfo.zone && userInfo.phone
        ? dispatch('accountLoginRes', {
          apiName: loginByMobliePasswordStu,
          userInfo: userInfo,
        })
        : dispatch('accountLoginRes', {
          apiName: AccountLoginStu,
          userInfo: userInfo,
        })
    }
  },
  // 手机号码登入
  async Phonelogin({ commit }, userInfo) {
    const { mobile, smsCode, operation, zone, role, password } = userInfo
    try {
      const response =
        role === 'teacher'
          ? await PhoneLoginApi({ mobile, smsCode, zone, operation })
          : userInfo.zone && userInfo.isOr == false
            ? await loginByMobliePasswordStu({
              mobile,
              smsCode,
              zone,
              operation,
              password,
            })
            : await PhoneLoginStu({ mobile, smsCode, zone, operation, password })
      const { data, message } = response
      if (data.status === '200') {
        Message.success(i18n.t('登入成功'))
        commit('SET_TOKEN', data.info.accessToken)
        // 持久化token
        setToken(data.info.accessToken)
        return response
      } else if (data.status == 50021 || data.status == 50023) {
        return Promise.reject(response)
      } else {
        // Message.error(message);
        return Promise.reject(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 获取老师用户的信息
  async getUserInfo({ commit, state }, roles) {
    try {
      let response
      if (roles == 'teacher') {
        response = await getUserInfoApi()
      } else if (roles == 'student') {
        response = await getUserInfoStu({ accountType: '017001' })
        if (response.code == 200) {
          commit('userdata/SET_STUDENT', response.data, {
            root: true,
          })
        }
      }
      const { code, data } = response
      if (code != '200') {
        return Promise.reject(response.msg)
      }
      if (!data) {
        return Promise.reject(i18n.t('验证失败, 请重新登录.'))
      }
      // 解构出用户的信息
      const { userType } = data
      if (userType === '016002') {
        data.userType = 'teacher'
        if (roles == 'teacher') {
          data.userType = 'teacher'
        } else if (roles == 'student') {
          data.userType = 'student'
        }
      } else if (userType === '016001') {
        data.userType = 'student'
      } else {
        let type = state.roles
        data.userType = type
      }
      if (!userType || userType.length <= 0) {
        return Promise.reject(i18n.t('角色信息不能为空'))
      }
      commit('SET_ROLES', data.userType)
      commit('SET_USER_INFO', data)

      commit('SET_ISLOGIN_STATUS', true)
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  // 退出登入
  async logout({ commit, state, dispatch }) {
    await commit('SET_TOKEN', '')
    // await commit('SET_ROLES', '')
    await commit('SET_ISLOGIN_STATUS', false)
    await removeToken()
    await dispatch('tagsView/delAllViews', null, {
      root: true,
    })
    await dispatch('removeDeviceId')
    await dispatch('removeAreaCode')
    // 没办法清空路由 只好刷新页面初始化路由
    location.reload()
  },

  // 生成设备号
  createDeviceId({ commit }) {
    return new Promise((resolve, reject) => {
      let deviceId = localStorage.getItem('deviceId')
      if (!deviceId) {
        FingerprintJS.load()
          .then((fpPromise) => {
            fpPromise
              .get()
              .then((result) => {
                const visitorId = result.visitorId
                commit('SET_DEVICEID', visitorId)
                localStorage.setItem('deviceId', visitorId)
                resolve(visitorId)
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        commit('SET_DEVICEID', deviceId)
      }
    })
  },
  // 删除设备号
  removeDeviceId({ commit }) {
    commit('SET_DEVICEID', '')
    localStorage.removeItem('deviceId')
  },
  // 删除手机区号
  removeAreaCode({ commit }) {
    commit('SET_AREA_CODE', '')
    localStorage.removeItem('areaCode')
  },
}

export default {
  namespaced: true, //成为带命名空间的模块
  state,
  mutations,
  actions,
}
