
const state = {
  token: localStorage.getItem('mobileToken') || '',
  MdeviceId: '',
  MuserInfo: {},
  MclientType: '',
  MorgDesc: '',
  McourseDetail: null,
  Mlanguage: '',
  Mtimezone: ''

}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    localStorage.setItem('mobileToken', token)
  },
  SET_DEVICEID: (state, deviceId) => {
    state.MdeviceId = deviceId
  },
  SET_USERINFO: (state, userInfo) => {
    state.MuserInfo = userInfo
  },
  SET_CLIENTTYPE: (state, clientType) => {
    state.MclientType = clientType
  },
  SET_ORG_DESC: (state, MorgDesc) => {
    state.MorgDesc = MorgDesc
  },
  SET_COURSE_DETAIL: (state, McourseDetail) => {
    state.McourseDetail = McourseDetail
  },
  SET_LANGUAGE: (state, Mlanguage) => {
    state.Mlanguage = Mlanguage
  },
  SET_TIMEZONE: (state, Mtimezone) => {
    state.Mtimezone = Mtimezone
  },
  SET_HEADERS: (state, headers) => {
    state.Headers = headers
  },
}

const actions = {


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
