export
  default {
    '1.分类思维法2': '1. Categorical thinking method 2',
    '3.1保存题目后，题目类型不可修改': '3.1 After saving the question, the question type cannot be modified.',
    确定: 'Sure',
    取消: 'Cancel',
    提示: 'Reminder',
    请选择: 'Please select',
    手机区号: 'Code',
    无匹配数据: 'No matching data',
    上传图片: 'Upload pictures',
    点击上传: 'Click to upload',
    '请等待所有图片上传成功。如果出现网络问题，请刷新页面重新上传!': 'Please wait until all images are uploaded successfully. If there is a network problem, please refresh the page and upload again!',
    上传视频: 'Upload video',
    请先上传视频: 'Please upload the video first',
    图片批量上传: 'Image batch upload',
    '正在转换中...': 'Converting...',
    '目前仅支持docx文件格式的Word文档，若为doc格式的文档，请通过word另存为docx格式的文件后重试': 'Currently, only Word documents in docx file format are supported. If it is a document in doc format, please save it as a docx format file through word and try again.',
    图片剪裁: 'Picture cropping',
    '图片未加载完成，请稍后再试': 'The image has not been loaded, please try again later.',
    上传: 'upload',
    图片预览: 'Preview',
    浏览: 'Browse',
    请上传正确的文件格式: 'Please upload the correct file format',
    上传文件大小不能超过: 'Upload file size cannot exceed',
    'MB哦!': 'MB!',
    '上传100%': 'Upload 100%',
    上传成功: 'Successful',
    删除: 'Delete',
    预览: 'Preview',
    '支持.mp4,.avi,.mov,.mpg,.mpeg格式': 'Support .mp4, .avi, .mov, .mpg, .mpeg formats',
    视频预览: 'Video preview',
    '视频格式有误，上传失败': 'Video format error, upload failed.',
    '请上传文件大小不超过500MB的视频哦!': 'Please upload a video with a file size not exceeding 500MB!',
    成功: 'success',
    '确定要删除?': 'Are you sure you want to delete?',
    '将图片拖到此处，或': 'Drag an image here, or',
    请选择封面图片: 'Please select a cover image',
    选择封面图: 'Select cover image',
    图片上传成功: 'Image uploaded successfully',
    请上传正确的图片格式: 'Please upload the correct image format',
    上传图片大小不能超过: 'The size of the uploaded image cannot exceed',
    内容中无有效的图片: 'There are no valid images in the content',
    请先选择一张图片: 'Please select a picture first',
    取消上传: 'Cancel upload',
    请上传正确的视频格式: 'Please upload the correct video format',
    上传视频大小不能超过: 'The uploaded video size cannot exceed',
    key值不存在: 'key value does not exist',
    请完成安全验证: 'Please complete the security verification',
    验证成功: 'Verification successful',
    验证失败: 'Authentication failed',
    '请依次点击【': 'Please click [',
    向右滑动完成验证: 'Swipe right to complete verification',
    '{slot1}s验证成功': '{slot1}s verification successful',
    汉语可比: 'Hycooby',
    官网: 'Official website',
    登入: 'login',
    微信登入: 'WeChat login',
    微信绑定: 'WeChat binding',
    隐私政策: 'privacy policy',
    用户协议: 'User Agreement',
    退款规则: 'Refund rules',
    日常作业: 'Homework',
    日常作业批改: 'Correction',
    日常作业查看: 'Review',
    写作业: 'Do homework',
    提交成功: 'Successful',
    作业点评: 'Comments',
    批改: 'Correct',
    课程简介: 'Introduction',
    上课流程: 'Class process',
    学习报告: 'Learning Report',
    作业预览: 'Job preview',
    下载: 'Download',
    注销账号: 'Cancel account',
    华校: 'School',
    华校详情: 'Details',
    华校详情简介: 'Introduction',
    课程详情: 'Course details',
    家长监督: 'Parental supervision',
    家长监督详情: 'Parental supervision details',
    购买课程: 'Buy course',
    签订合同: 'Sign a contract',
    首页: 'Study',
    上课中心: 'Study',
    我的课表: 'Schedule',
    我的课程: 'Courses',
    设置中心: 'Settings',
    通知中心: 'Notification',
    作业中心: 'Homework',
    待完成: 'Pending',
    已完成: 'Completed',
    课程目录: 'Catalog',
    我的资源: 'Resources',
    学生直播: 'Live broadcast',
    总课表: 'Schedule',
    体系课: 'System class',
    公开课: 'Public class',
    班级目录: 'Contents',
    作业系统: 'Homework',
    作业管理: 'Manage',
    作业编辑: 'Editing',
    作业批改: 'Correct',
    模板中心: 'Template center',
    题库中心: 'Question Bank Center',
    批改作业: 'Correct homework',
    编辑作业: 'Edit assignment',
    编辑题库: 'Edit question bank',
    编辑模板: 'Edit template',
    资源中心: 'Resource',
    资源广场: 'Plaza',
    资源搜索: 'Resource search',
    我的收藏: 'Collection',
    书本详情: 'Details',
    查看文件: 'View files',
    课程回放: 'Replay',
    上课历史: 'Class history',
    账号设置: 'Account settings',
    账号信息: 'Information',
    账号绑定: 'Binding',
    直播: 'live streaming',
    登入成功: 'Login successful',
    '验证失败, 请重新登录': 'Verification failed, please log in again.',
    角色信息不能为空: 'Role information cannot be empty',
    退出: 'Log Out',
    零: 'Sun',
    一: 'Mon',
    二: 'Tue',
    三: 'Wed',
    四: 'Thu',
    五: 'Fri',
    六: 'Sat',
    七: 'Sun',
    八: 'Eight',
    九: 'Nine',
    十: 'Ten',
    '您已经登出，您可以取消留在本页，或再次登录': 'You have logged out, you can cancel to stay on this page, or log in again',
    确认注销: 'Confirm logout',
    重新登录: 'Log in again',
    后端接口连接异常: 'Backend interface connection abnormality',
    系统接口请求超时: 'System interface request timeout',
    系统接口: 'System interface',
    异常: 'abnormal',
    'MM月DD日 HH:mm': 'MM month DD day HH:mm',
    复制成功: 'Copied successfully',
    复制失败: 'Copy failed',
    上传失败: 'Upload failed',
    '只支持上传 JPG/PNG 格式的图片': 'Only supports uploading images in JPG/PNG format',
    最多上传9个文件: 'Up to 9 files can be uploaded',
    上传图片格式错误: 'Uploaded image format error',
    上传文件格式错误: 'Upload file format error',
    该资料下载地址无效: 'The data download address is invalid',
    下载文件失败: 'Download file failed',
    页面跳转: 'Page jump',
    德馨双语学校: '德馨双语学校',
    富兰克林教育: '富兰克林教育',
    龙之门教育: '龙之门教育',
    奥地利学校: '奥地利学校',
    '机构：': 'mechanism:',
    元: '元',
    我要购买: 'Buy',
    更多课程: 'More',
    伯尔尼华夏中文学校: '伯尔尼华夏中文学校',
    罗马中华语言学校: '罗马中华语言学校',
    南非华教国际学校: '南非华教国际学校',
    毛里求斯教育机构: '毛里求斯教育机构',
    中华文化传播: '中华文化传播',
    汉字的演变: ' 汉字的演变',
    唐诗三百首: '   唐诗三百首',
    轻松学拼音: ' 轻松学拼音',
    请前往汉语可比app中购买: 'Please go to the Hycooby app to purchase',
    请前往汉语可比app中查看更多课程: 'Please go to the Hycooby app to view more courses',
    带: '带',
    你: '你',
    快: '快',
    乐: '乐',
    学: '学',
    中: '中',
    文: '文',
    带你快乐学中文: 'Take you to learn Chinese happily',
    '请用手机扫码下载，或去应用市场搜索“Hycooby”下载即可': 'Please use your mobile phone to scan the QR code to download, or search for "Hycooby" in the app store to download.',
    扫描二维码下载: 'Scan the QR code to download',
    iphnoe下载: 'Iphnoe download',
    Google下载: 'Google download',
    服务协议: 'Service Agreement',
    营业执照: 'Business license',
    增值电信业务经营许可证: 'Value-added telecommunications business license',
    '汉语可比（福建）数字科技有限公司版权所有': 'Chinese Comparable (Fujian) Digital Technology Co., Ltd. all rights reserved  ',
    'ICP许可证号：闽ICP备2023005036号-1': 'ICP license number Fujian ICP No. 2023005036-1',
    '违法和不良信息举报电话(同时受理未成年人保护举报相关的问题):0591-83771172举报邮箱: hrtt@52hrtt.com': 'Hotline for reporting illegal and harmful information (also accepting reports related to the protection of minors): 0591-83771172 Reporting email: hrtt@52hrtt.com',
    语言与文化密不可分: 'Language and culture are inseparable',
    '语言从来就是与文化紧密联系在一起的，学习一个民族的语言必须了解这个民族的文化，必须学习与这种语言有关的文化背景。': '语言从来就是与文化紧密联系在一起的，学习一个民族的语言必须了解这个民族的文化，必须学习与这种语言有关的文化背景.',
    少儿文化教育: "Children's cultural education",
    '少儿华文教育作为一种特殊的语言教学，同样离不开对文化的传授。华裔少儿学习者也只有从一定程度上理解了与汉语密切相关的中华文化，才能真正掌握并得体、地道地运用所获得的汉语知识。': '少儿华文教育作为一种特殊的语言教学，同样离不开对文化的传授。华裔少儿学习者也只有从一定程度上理解了与汉语密切相关的中华文化，才能真正掌握并得体、地道地运用所获得的汉语知识。',
    客户端下载: 'Client download',
    立即登入: 'Login now',
    中文: '中文',
    '欢迎来到汉语可比!请选择语言开始使用~': 'Welcome to  Hycooby! Please select a language to get started~',
    简体中文: 'Chinese',
    '*为了更好地展示页面，建议使用': '*In order to better display the page, it is recommended to use',
    浏览器: 'Browser',
    身份验证: 'Authentication',
    账号登录需验证手机号: 'Account login requires verification of mobile phone number',
    '账号登录设备达到上线，请验证后登录': 'The account login device has reached online, please log in after verification.',
    请输入关键词: 'Please enter keywords',
    请输入手机号: 'phone number',
    请输入验证码: 'verification code',
    获取验证码: 'Get code',
    提交并登录: 'log in',
    请输入手机号码: 'phone number',
    长度在100以内: 'Length within 100',
    '长度在4-6以内': 'Length within 4-6',
    请输入账号: 'account',
    请输入密码: 'password',
    忘记密码: 'Forget the password',
    登录: 'Log in',
    账号或密码错误: 'Incorrect account or password',
    找回密码: 'Retrieve password',
    '验证中...': 'Verifying...',
    设置新密码: 'Set new password',
    请再次输入密码: 'Please enter password again',
    '密码由6-12位数字、字母、符号任意组合而成': 'The password is composed of any combination of 6-12 digits, letters, and symbols.',
    提交: 'Submit',
    '两次输入密码不一致!': 'The passwords entered twice are inconsistent!',
    请设置新密码: 'Please set a new password',
    '长度在6-12以内': 'Length within 6-12',
    修改密码成功: 'Password changed successfully',
    '您已90天未更换密码，请及时更换': 'You have not changed your password for 90 days, please change it in time',
    密码登录: 'Password login',
    验证码登录: 'Verification code login',
    '尊敬的用户，您好!': 'Dear user, hello!',
    '为了更好地展示页面效果，建议您使用以下浏览器(点击图标会跳转到相关浏览器的官方网站下载页面)。': 'In order to better display the page effect, it is recommended that you use the following browsers (clicking the icon will jump to the official website download page of the relevant browser).',
    关闭提示: 'Close prompt',
    微信手机号绑定: 'WeChat mobile number binding',
    绑定微信: 'Bind WeChat',
    '确认关闭？': 'Confirm to close?',
    微信绑定成功: 'WeChat binding successful',
    学生端: "Stu's",
    教师端: "Tch's",
    学生登录: 'Student login',
    教师登录: 'Teacher login',
    账号登录: 'Account login',
    手机号登录: 'Phone Login',
    第三方登录: 'Third party login',
    登录即代表您已同意: 'By logging in you agree',
    '《用户协议》': '"User Agreement"',
    和: 'and',
    '《隐私政策》': '"Privacy Policy"',
    微信登录: 'WeChat login',
    全部: 'All',
    待上课: 'Upcoming Classes',
    课堂回顾: 'Course Review',
    请输入课程号加入公开课: 'Please enter the course number to join the public class',
    分钟: 'minute',
    正在上课: 'In Session',
    进入教室: 'Enter',
    课堂表现: 'Classroom performance',
    看回放: 'watch replay',
    待调课: 'Class to be adjusted',
    上课时间: 'Class time',
    请提前十分钟进入教室: 'Please enter the classroom ten minutes early',
    请进入教室: 'Please enter the classroom',
    距开始: 'left to begin',
    无更多数据: 'No more data',
    '正在加载中...': 'Loading...',
    加入公开课: 'Join the open class',
    您未参与该章节的学习: 'You have not participated in the study of this chapter',
    课程类型: 'Course Type',
    课程状态: 'course status',
    课次: 'Lessons',
    '正在为您加速排班中，请耐心等候，有疑问请': 'We are accelerating the scheduling for you. Please wait patiently. If you have any questions, please',
    '联系客服 ': 'Contact customer service',
    '客服热线:': 'Srvice hotline:',
    进行中: 'In progress',
    待开课: 'To be started',
    已结束: 'Ended',
    已过期: 'Expired',
    今日: 'Today',
    今: '今',
    日: 'Sun',
    YYYY年MM月: 'DD/MM/YYYY',
    '暂时没有课程呢~': 'There are no courses at the moment~',
    '课程号:': 'Course ID:',
    '*已默认为您添加至课程列表中': '*Already added to your course list by default',
    进入课堂: 'Enter',
    课程已结束: 'Course has ended',
    提醒: 'remind',
    '已完成？？？': 'Completed? ? ?',
    去完成: 'Go',
    待批改: 'To be corrected',
    '教师寄语：': 'Teacher’s message:',
    创建下载成功: 'Created and downloaded successfully',
    播放列表: 'playlist',
    暂无介绍: 'No introduction yet',
    '该课程还未到开课时间,可自行预习该课程哦～': 'The course has not started yet, so you can preview the course by yourself~',
    课程回放暂未生成: 'Course replay has not been generated yet',
    '该课程已过期，学习视频无法查看...': 'The course has expired and the learning videos cannot be viewed...',
    '视频加载中...': 'Video loading...',
    章节学习: 'Chapter study',
    课后作业: 'Homework',
    未发布: 'Unpublished',
    课程资料: 'course materials',
    置顶: 'Pin',
    更新于: 'Updated on',
    暂无发布: 'No releases yet',
    执教: 'Coach',
    年: 'Year',
    上课中: 'In class',
    已上课: 'Class Started',
    该章节还未到开课时间哦: 'Not started.',
    下载教材: 'Download Materials',
    上次学到: 'Last learned',
    观看至: 'Watch to',
    暂无观看: 'No views yet',
    课程通知: 'Course Notice',
    '更多 >': 'More >',
    暂无公告: 'No notice yet',
    已学完: 'Completed',
    未观看: 'Not watched',
    师资介绍: 'Introduction',
    暂无教师: 'No teacher yet',
    已失效: 'Expired',
    我的作业: 'Homework',
    未完成: 'Not completed',
    切换: 'Switch',
    岁: 'age',
    已选择: 'Selected',
    消息中心: 'Message',
    退出登录: 'Log out',
    '功能完善中，请前往app查看~': 'The function is being improved, please go to the app to check~',
    HYCOOBY学生端: 'HYCOOBY student version',
    刷新: 'Refresh',
    关闭: 'Close',
    关闭其他: 'Close other',
    关闭所有: 'Close all',
    常见问题: 'FAQ',
    教师: 'Tch',
    '(我)': '(I)',
    发生错误信息: 'An error message occurred',
    返回首页: 'Return to home page',
    禁言中: 'Banned',
    '请输入聊天内容...': 'Please enter chat content...',
    快捷语: 'Shortcut',
    发送: 'Send',
    条新消息: 'new messages',
    聊天室连接错误: 'Chat room error',
    请: 'Please',
    点击重连: 'click to reconnect',
    获取历史消息失败: 'Failed to obtain historical messages',
    学生: 'Student',
    '处于禁言状态，无法发言': 'Being muted and unable to speak',
    '处于全体禁言状态，无法发言': 'All muted and unable to speak',
    发送消息失败: 'Failed to send message',
    网络优: 'Excellent',
    网络良: 'Good',
    网络一般: 'Average',
    网络差: 'Poor',
    未知: 'Unknown',
    '教师请求开启摄像头，是否开启？': 'The teacher has requested to turn on the microphone. Do you agree?',
    拒绝: 'No',
    开启: 'Yes',
    '教师请求开启麦克风，是否开启？': 'The teacher requested to turn on the microphone. Do you want to turn it on?',
    '教师邀请您连麦，是否连麦？': 'Teacher requests microphone connection, agree?',
    连麦: 'Call up',
    弹幕: 'Barrage',
    '未加入群组，请点击重新加入群组': 'Not joined the group, please click to rejoin the group',
    网络极佳: 'Excellent',
    网络较好: 'Good',
    网络极差: 'Poor',
    断开: 'Disconnect',
    '下行丢包率: ': 'Downlink packet loss rate:',
    '上行丢包率: ': 'Uplink packet loss rate:',
    '音频码率:': 'Audio bit rate:',
    '视频码率:': 'Video bit rate:',
    '视频帧率:': 'Video frame rate:',
    '房间加载中...': 'Room loading...',
    '课程类型错误:': 'Wrong course type:',
    '直播类型错误:': 'Live broadcast type error:',
    'im 登入失败 请重新连接': 'im login failed please reconnect',
    '房间 Token 错误': 'Room Token error',
    '房间 Token 过期': 'Room Token expired',
    房间内已经有同名用户: 'There is already a user with the same name in the room',
    '设备不支持指定的音视频格式，无法进行连麦的操作': 'The device does not support the specified audio and video format, and the microphone connection operation cannot be performed.',
    加入房间认证失败: 'Failed to join room authentication',
    '30s 内未能重连成功或者重连时房间已被销毁': 'Failure to reconnect successfully within 30s or the room has been destroyed when reconnecting',
    '需要检查接口调用、设备状态、网络状态等是否正常': 'It is necessary to check whether the interface call, device status, network status, etc. are normal.',
    请刷新页面重新进入房间: 'Please refresh the page and re-enter the room',
    '(我)进入房间': '(I) enter the room',
    请点击确定重新播放直播: 'Please click OK to play the live broadcast again',
    '没有访问媒体设备的权限,请在设置-隐私与安全-网站设置-权限,重新打开摄像头权限': 'There is no permission to access the media device. Please go to Settings-Privacy and Security-Website Settings-Permissions and reopen the camera permissions.',
    '没有访问媒体设备的权限,请在设置-隐私与安全-网站设置-权限,重新打开麦克风权限': 'There is no permission to access the media device. Please go to Settings-Privacy and Security-Website Settings-Permissions and reopen the microphone permission.',
    '获取摄像头/麦克风权限被拒绝，请手动打开摄像头/麦克风权限后重新进入房间': 'Obtaining camera/microphone permission is denied. Please manually enable camera/microphone permission and re-enter the room.',
    '抱歉，您的浏览器不支持屏幕共享，请使用 Chrome 或者 Firefox': 'Sorry, your browser does not support screen sharing, please use Chrome or Firefox',
    请刷新页面手动重新发布: 'Please refresh the page and repost manually',
    请手动选择系统声音: 'Please select system sound manually',
    '无法生成摄像头/麦克风/共享屏幕轨道，错误代码: {slot1}': 'Unable to generate camera/microphone/shared screen track, error code: {slot1}',
    主动退出房间: 'Exit the room actively',
    'qn-您被踢出房间': 'you were kicked out of the room',
    您被踢出房间: 'you were kicked out of the room',
    请刷新页面: 'Please refresh the page',
    '正在重连中...': 'Reconnecting...',
    重连成功: 'Reconnection successful',
    '老师{name}进入教室': 'Teacher {name} enters the classroom',
    '{name}进入教室': '{name} enters the classroom',
    '{slot1}进入教室': '{slot1}Enter the classroom',
    '{slot1}离开教室': '{slot1}Leave the classroom',
    您的连麦已结束: 'Your session has ended',
    连麦已结束: 'The wheat streak has ended',
    '{name}离开教室': '{name} leaves the classroom',
    '{name}连麦已结束': "{name}'s wheat streak has ended",
    '{name}正在请求连麦已结束': '{name} is requesting that the wheat connection has ended.',
    '请<span>刷新</span>试试': 'Please <span>refresh</span> and try',
    登入失败: 'Login failed',
    获取房间号失败: 'Failed to get room number',
    询问老师是否有人上台失败: 'Ask the teacher if anyone failed to get on stage',
    多终端登录被踢: 'Multi-terminal login kicked out',
    发起了举手: 'raised hands',
    '{stuName}结束了连麦': '{stuName} ended the wheat streak',
    '{stuName}请求连麦': '{stuName} requests continuous wheat',
    教师未接听您的连麦: 'The teacher did not answer your call',
    '老师拒绝了{stuName}连麦': 'The teacher rejected {stuName}’s even wheat',
    '{stuName}取消了连麦': '{stuName} canceled the link',
    老师解除禁言: 'Teacher unbans',
    老师解除了全体禁言: 'The teacher lifted the ban on everyone',
    老师禁言了: 'The teacher has banned',
    老师开启了全体禁言: 'The teacher has activated all bans',
    您已被老师请出教室: 'You have been asked to leave the classroom by the teacher',
    该课程已经停用: 'This course has been deactivated',
    您已被移出课堂: 'You have been removed from the class',
    多实例登录被踢: 'Multiple instance login kicked out',
    签名过期被踢: 'Signature expired and kicked out',
    'REST API kick 接口踢出': 'REST API kick interface kick out',
    IM重连成功: 'IM reconnected successfully',
    IM连接断开: 'IM connection disconnected',
    '无麦克风摄像头设备,无法进行连麦': 'There is no microphone camera device, and the microphone cannot be connected.',
    关闭连麦失败: 'Failed to close the microphone',
    拒绝老师连麦失败: 'Rejecting the teacher and failing to connect with wheat',
    拒绝打开摄像头失败: 'Failed to refuse to open camera',
    拒绝打开麦克风失败: 'Refuse to open microphone failed',
    '当前有学生正在请求连麦中,无法再发起连麦': 'Currently, there are students who are requesting to join the wheat program, and they can no longer initiate the wheat streak.',
    '其他同学正在连麦中,无法再发起连麦': 'Other students are currently in the process of connecting to another class and cannot initiate another class.',
    '{stuName}(我)取消了连麦': '{stuName}(I) canceled the wheat connection',
    '{stuName}(我)取消了连麦失败': '{stuName}(I) canceled the wheat connection failure',
    '{stuName}(我)发起了连麦': '{stuName}(I) initiated the Lianmai',
    请求老师连麦失败: 'Requesting the teacher to connect to the microphone failed',
    '正在发起举手，请勿多次点击': 'Raise hand is being initiated, please do not click multiple times',
    '{stuName}（我）发起了举手': '{stuName} (I) initiated a raise of hands',
    '{stuName}（我）放下手失败': '{stuName} (I) failed to put down my hand',
    '没有麦克风设备,请插入麦克风设备': 'No microphone device, please plug in the microphone device',
    '您还未发起连麦 故无法开启麦克风': 'You have not initiated a microphone connection, so you cannot turn on the microphone.',
    '没有摄像头设备,请插入摄像头设备': 'No camera device, please insert the camera device',
    '您还未发起连麦 故无法开启摄像头': 'You have not initiated the microphone connection, so you cannot turn on the camera.',
    '正在开启老师摄像头中,请勿多次点击,请稍等...': "The teacher's camera is being opened. Please do not click multiple times. Please wait...",
    开启老师摄像头成功: 'Teacher camera turned on successfully',
    '没有麦克风设备,无法进行连麦': 'Without a microphone device, connection cannot be made',
    '没有摄像头设备,无法进行连麦': 'Without camera equipment, microphone connection is not possible',
    '{name}（教师）进入教室': '{name} (teacher) enters the classroom',
    '{slot1}（教师）离开教室': '{slot1} (teacher) leaves the classroom',
    同意老师连麦失败: 'Agree with the teacher’s failure to reconnect with wheat',
    '(我){stuName}取消了连麦': '(I) {stuName} canceled the continuous wheat',
    '(我){stuName}取消了连麦失败': '(I) {stuName} canceled the wheat connection failure',
    '(我){stuName}发起了连麦': '(I) {stuName} initiated the Lianmai',
    '（我）发起举手失败': '(I) Failed to initiate raising of hands',
    '未和老师连麦 无法切换设备': 'I am not connected to the teacher and cannot switch devices.',
    '未开启麦克风,正在为您开启麦克风，请勿多次点击,请稍等...': 'The microphone has not been turned on. The microphone is being turned on for you. Please do not click multiple times. Please wait...',
    '正在开启摄像头中,请勿多次点击,请稍等...': 'The camera is opening, please do not click multiple times, please wait...',
    开启摄像头成功: 'Camera turned on successfully',
    '教师：': 'Tch:',
    已下课: 'Get out of class has ended',
    退出教室: 'Exit',
    屏幕共享暂未开启: 'Screen sharing is not enabled yet',
    '再等一会,教师正在快马加鞭赶到～': 'Wait a moment, the teacher is rushing there~',
    在线: 'Online',
    离线: 'Offline',
    '学生在线人数（': 'Online (',
    '等待连麦(': 'Waiting(',
    连麦中: 'Chating',
    发起连麦: 'Call up',
    举手: 'Hands up',
    '举手倒计时(': 'Hands up countdown (',
    麦克风: 'Microphone',
    摄像头: 'Camera',
    无麦克风设备: 'No MIC',
    音频设备: 'Audio',
    无摄像头设备: 'No camera',
    视频设备: 'Video',
    全屏: 'Full',
    '距离上课：': 'Distance to class:',
    '已上课：': 'Already attended:',
    摄像头预览: 'Camera preview',
    '摄像头-': 'Camera-',
    开: 'open',
    关: 'close',
    '麦克风-': 'microphone-',
    '直播初始化中...': 'Live broadcast initializing...',
    '预览失败 请刷新页面': 'Preview failed. Please refresh the page.',
    老师解除全体静音: 'The teacher unmutes all',
    老师邀请: 'teacher invitation',
    上台: 'speaking',
    结束上台: 'End of speaking',
    '老师{name}离开教室': 'Teacher {name} leaves the classroom',
    不同意开启摄像头失败: 'Failed to turn on the camera if not agreed',
    '教师已开启全体禁言，故无法开启麦克风': 'The teacher has turned on all muting, so the microphone cannot be turned on',
    重新申请加入房间成功: 'Reapplying to join the room was successful',
    '播放失败,': 'Play failed,',
    老师解除全体禁言: 'The teacher lifts all bans',
    生成本地视频失败: 'Failed to generate local video',
    '（我）进入房间': 'Enter the room',
    '无摄像头或者麦克风 无法进行上台': 'No camera or microphone, unable to go on stage',
    '没有麦克风设备,无法更换设备': 'Without a microphone device, the device cannot be replaced',
    '没有摄像头设备,无法更换设备': 'There is no camera device and the device cannot be replaced',
    综合评价: 'Overall Evaluation',
    较差: 'Poor',
    合格: 'Qualified',
    良好: 'Good',
    优秀: 'Excellent',
    完美: 'Perfect',
    '你已经评价过了，不要重复操作': 'You have already evaluated it, do not repeat it',
    未开发: 'undeveloped',
    '教室ID：': 'Classroom ID:',
    已开课: 'Classes have started',
    下课: 'After class',
    请选择授课方式: 'Please select the teaching method',
    您可在上课期间随时调整授课方式: 'You can adjust the teaching method at any time during the class',
    麦克风加屏幕分享: 'Microphone plus screen sharing',
    麦克风加摄像头: 'Microphone plus camera',
    开始授课: 'Start teaching',
    距开课还剩: 'There’s still time left before class starts',
    ',请提前进入教室准备': ', please enter the classroom in advance to prepare',
    '已经上课,请进入教室上课': 'Class has started, please enter the classroom to start class',
    还未选择直播类型: 'No live broadcast type selected yet',
    无法获取到麦克风: 'Unable to get microphone',
    无法获取到麦克风或者视频设备: 'Unable to obtain microphone or video device',
    成员列表: 'Member list',
    学生未开启摄像头: 'Students do not turn on the camera',
    '屏幕共享暂未开启～': 'Screen sharing is not enabled yet~',
    默认视图: 'Default',
    全部视图: 'All views',
    直播视图: 'Live view',
    '教师:': 'Tch:',
    演讲中: 'Speeching',
    '当前浏览器不支持TRTC Web SDK，请使用最新版的 Chrome 浏览器。': 'The current browser does not support TRTC Web SDK, please use the latest version of Chrome browser.',
    '强制进入房间失败,原因:': 'Forced entry into the room failed due to:',
    'im登入失败，请重新登入 ': 'IM login failed, please log in again',
    教师已为您开启白板权限: 'The teacher has enabled whiteboard permission for you',
    '播放视频失败，请联系老师': 'Failed to play the video, please contact the teacher',
    通知老师学生被顶号失败: 'Notifying the teacher that the student’s account has been blocked failed',
    您的白板权限已被关闭: 'Your whiteboard permission has been turned off',
    'qn-正在重连中...': 'Reconnecting...',
    直播间连接失败: 'Live broadcast room connection failed',
    直播间连接中断: 'Live broadcast room connection interrupted',
    '您的麦克风/摄像头接触不良导致错误，请您确认下摄像头/麦克风接口是否会松动': 'Your microphone/camera contact is poor, causing the error. Please confirm whether the camera/microphone interface is loose.',
    直播发生错误: 'An error occurred during live broadcast',
    '直播发生错误,请刷新页面': 'An error occurred during live broadcast, please refresh the page',
    '您被踢出房间, reason:{slot1}, message:{slot2}': 'You were kicked out of the room, reason:{slot1}, message:{slot2}',
    '正在开启麦克风中,请勿多次点击,请稍等...': 'Opening the microphone, please do not click multiple times, please wait...',
    开启麦克风成功: 'Microphone turned on successfully',
    开启麦克风失败: 'Failed to turn on microphone',
    开启摄像头失败: 'Failed to turn on camera',
    学生询问老师所有状态失败: 'Student asked teacher all status failed',
    白板实时信令同步失败: 'Whiteboard real-time signaling synchronization failed',
    聊天室: 'Chat room',
    退出全屏: 'Exit',
    '没有视频播放设备,请接入摄像头': 'There is no video playback device, please connect the camera',
    '没有麦克风设备,请接入麦克风': 'There is no microphone device, please connect the microphone',
    '多实例登录被踢+666': 'Multiple instance login was kicked',
    学生询问老师是否当前有人上台失败: 'The student asked the teacher if anyone currently failed to come to the stage',
    '教师已开启全体禁音，故无法开启麦克风': 'The teacher has turned on all muting, so the microphone cannot be turned on',
    关闭麦克风成功: 'Microphone turn off successfully',
    更新学生状态失败: 'Failed to update student status',
    '您对本堂课感受如何?请为教师打个分吧~': 'How do you feel about this class? Please rate the teacher~',
    极差: 'Very poor',
    'IM离线 本机网络掉线 请检查网络': 'IM is offline. The local network is offline. Please check the network.',
    'IM离线本机网络掉线 请检查网络': 'IM is offline. The local network is offline. Please check the network.',
    'im 网络连接成功': 'im network connection successful',
    请求服务超时: 'Request service timeout',
    'im-请求服务超时--': 'im-request service timeout--',
    默认未区分类型的错误: 'Default untyped error',
    'im-默认未区分类型的错误--': 'im - default untyped error --',
    参数错误: 'Parameter error',
    'im-参数错误--': 'im-parameter error--',
    取消请求: 'Cancel request',
    'im-取消请求--': 'im-cancel request--',
    '登录失败：获取 token 接口请求失败或 Token 无效。': 'Login failed: The request to obtain the token interface failed or the token is invalid.',
    'im-登录失败：获取 token 接口请求失败或 Token 无效。--': 'im-Login failed: The request to obtain the token interface failed or the Token is invalid. --',
    '鉴权失败：调用 API 时校验 App Key 失败，App Key 不合法。': 'Authentication failure: Verification of the App Key failed when calling the API, and the App Key is illegal.',
    'im-鉴权失败：调用 API 时校验 App Key 失败，App Key 不合法。--': 'im-Authentication failed: Verification of the App Key failed when calling the API, and the App Key is illegal. --',
    '获取 Chat token 失败：通过 Agora token 置换 Chat token 失败。': 'Failed to obtain Chat token: Failed to replace Chat token with Agora token.',
    'im-获取 Chat token 失败：通过 Agora token 置换 Chat token 失败。--': 'im-Failed to obtain Chat token: Failed to replace Chat token with Agora token. --',
    'WebSocket 断开连接：由于断网等原因 WebSocket 已经断开。': 'WebSocket disconnected: WebSocket has been disconnected due to network disconnection and other reasons.',
    'im-WebSocket 断开连接：由于断网等原因 WebSocket 已经断开。--': 'im-WebSocket disconnected: WebSocket has been disconnected due to network disconnection and other reasons. --',
    '未设置 App Key：设置的 App Key 错误。': 'App Key not set: The wrong App Key is set.',
    'im-未设置 App Key：设置的 App Key 错误。--': 'im-App Key not set: The wrong App Key is set. --',
    '未传 token：调用 API 时没有携带 token': 'Token not passed: No token was carried when calling the API',
    'im-未传 token：调用 API 时没有携带 token --': 'im-Token not passed: No token was carried when calling the API --',
    '消息发送成功的回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。': 'Internal error in the callback function of successfully sending the message: There is an error in the callback of receiving the message and the subsequent processing function.',
    'im-消息发送成功的回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。--': 'im-Internal error in the callback function of successful message sending: There is an error in the callback of receiving the message and the subsequent processing function. --',
    '当前用户未登录。': 'The current user is not logged in.',
    'im-当前用户未登录。--': 'im-The current user is not logged in. --',
    '退出或未登录：未登录或掉线后发送消息。': 'Log out or not logged in: Send message after not logged in or offline.',
    'im-退出或未登录：未登录或掉线后发送消息。--': 'im-Logged out or not logged in: Send message after not logged in or offline. --',
    用户鉴权失败: 'User authentication failed',
    'im-用户鉴权失败 --': 'im-User authentication failed --',
    '达到上限，例如 Reaction 数量已达到限制、翻译用量达到上限、应用的日活跃用户数（DAU）超限、在线用户数量超限和月活跃用户数（MAU）。': 'The upper limit has been reached, for example, the number of Reactions has reached the limit, the translation usage has reached the upper limit, the number of daily active users (DAU) of the application has exceeded the limit, the number of online users and the number of monthly active users (MAU) have exceeded the limit.',
    'im-达到上限，例如 Reaction 数量已达到限制、翻译用量达到上限、应用的日活跃用户数（DAU）超限、在线用户数量超限和月活跃用户数（MAU）。--': 'im-The upper limit has been reached, for example, the number of Reactions has reached the limit, the translation usage has reached the upper limit, the number of daily active users (DAU) of the application has exceeded the limit, the number of online users and the number of monthly active users (MAU) have exceeded the limit. --',
    没查到消息: 'No news found',
    用户对当前操作没有权限: 'The user does not have permission for the current operation',
    不支持的操作: 'Unsupported operation',
    上传文件失败: 'File upload failed',
    '上传文件未携带 token': 'The uploaded file does not carry token',
    用户不存在: 'User does not exist',
    消息参数错误: 'Message parameter error',
    用户在其他设备登录: 'User logs in from other devices',
    'im-用户在其他设备登录--': 'im-User logs in on other devices--',
    用户已经被注销: 'The user has been logged out',
    用户密码更新: 'User password update',
    用户被踢下线: 'User kicked offline',
    'im-用户被踢下线 --': 'im-The user was kicked offline --',
    用户被全局禁言: 'User is globally banned',
    非好友禁止发消息: 'It is forbidden to send messages to non-friends',
    服务器忙碌: 'Server busy',
    'im-服务器忙碌--': 'im-server busy--',
    消息含有非法内容: 'Message contains illegal content',
    消息被拦截: 'Message intercepted',
    消息发送失败未知错误: 'Message sending failed with unknown error',
    撤回消息时超出限定时间: 'Time limit exceeded when withdrawing message',
    服务未开启: 'Service is not enabled',
    'im-服务未开启': 'im-service is not started',
    用户未在白名单中: 'User is not in whitelist',
    当前用户被禁言: 'The current user is banned',
    '第三方内容审核服务的消息审核结果为“拒绝”': 'Message moderation result from third-party content moderation service is "Deny"',
    '单个用户 ID 发送消息超出频率限制。': 'A single user ID sent messages exceeding the frequency limit.',
    '网络断开连接导致消息发送失败。': 'Message delivery failed due to network disconnection.',
    'im-网络断开连接导致消息发送失败。--': 'im-Network disconnect caused message delivery to fail. --',
    '消息体大小超过限制。': 'Message body size exceeds limit.',
    '已在群组内：当前用户已在该群组中。': 'Already in the group: The current user is already in the group.',
    'im-不在群组内：用户发送群消息时未加入该群组。--': 'im-Not in the group: The user did not join the group when sending the group message. --',
    '不在群组内：用户发送群消息时未加入该群组。': 'Not in the group: The user did not join the group when sending the group message.',
    '用户无权限：例如如果用户被封禁，发送消息时会提示该错误。': 'The user does not have permission: For example, if the user is banned, this error will be prompted when sending a message.',
    '消息回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。': 'Internal error in the message callback function: There is an error in the callback and subsequent processing functions that receive the message.',
    '群组不存在：发送消息时群组 ID 不正确。': 'Group does not exist: The group ID was incorrect when sending the message.',
    '群组人数达到上限。': 'The group size limit has been reached.',
    '设置的群组最大人数超过限制：创建群组，群成员数量超出了群组设置的最大数量。': 'The maximum number of people in the group exceeds the limit: Create a group and the number of group members exceeds the maximum number of group members.',
    '没有 token 或 App Key。': 'There is no token or App Key.',
    'im-没有 token 或 App Key。--': 'im-no token or app key. --',
    被操作的人员不在聊天室: 'The person being manipulated is not in the chat room',
    '聊天室已满：聊天室已经达到人数上限。': 'Chat room is full: The chat room has reached the maximum number of people.',
    聊天室不存在: 'Chat room does not exist',
    'Websocket 发送消息错误。': 'Websocket sending message error.',
    '发布自定义在线状态时，参数长度超出限制。': 'When publishing a custom online status, the parameter length exceeded the limit.',
    'Reaction 重复添加': 'Reaction repeated addition',
    '创建 Reaction 时，其他人正在创建。': 'While the Reaction is being created, others are creating it.',
    '用户对该 Reaction 没有操作权限': 'The user does not have operation permissions for this Reaction',
    翻译参数错误: 'Translation parameter error',
    翻译的文本过长: 'Translated text is too long',
    获取翻译服务失败: 'Failed to obtain translation service',
    '子区不存在：未找到该子区': 'Subsection does not exist: The subsection was not found',
    '该消息 ID 下子区已存在，重复添加子区。': 'The subarea under this message ID already exists. Add the subarea repeatedly.',
    数据异常: 'Data anomaly',
    最近一周: 'Last week',
    最近一月: 'Last month',
    '查看详情 >>': 'View details >>',
    查看回复: 'View replies',
    课程: 'lesson',
    资源名称: 'Name',
    '请输入...': 'Please enter...',
    '请输入': 'Please enter',
    查询: 'Query',
    重置: 'Reset',
    格式: 'Format',
    资源类型: 'Type',
    来源: 'From',
    添加时间: 'Time',
    学科: 'Discipline',
    操作: 'Operate',
    查看: 'Check',
    设置密码: 'Set password',
    '请设置密码(由8-16位数字、字母、符号任意组合而成)': 'Please set a password (any combination of 8-16 digits, letters, and symbols)',
    密码设置成功: 'Password set successfully',
    请设置密码: 'Please set a password',
    '密码长度需在8~16个字符之间': 'The password length must be between 8~16 characters',
    '密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符': 'The password can use 8-16 digits, letters or common symbols, and contain at least three or more characters',
    密码修改成功: 'Password changed successfully',
    修改密码: 'Change password',
    手机号验证: 'Mobile phone number verification',
    密码验证: 'Password verification',
    请输入原密码: 'Please enter the original password',
    '请输入新密码(由8-16位数字、字母、符号任意组合而成)': 'Please enter a new password (any combination of 8-16 digits, letters, and symbols)',
    请再次输入新密码: 'Please enter new password again',
    您的密码更改成功: 'Your password was changed successfully',
    退出登陆: 'Log out',
    完成: 'Finish',
    下一步: 'Next step',
    请输入新密码: 'Please enter new password',
    长度在11以内: 'Length within 11',
    验证码已发送: 'Verification code sent',
    修改手机号: 'Modify mobile phone number',
    请输入新手机号: 'Please enter new mobile phone number',
    修改手机号成功: 'Mobile phone number modified successfully',
    绑定成功: 'Binding successful',
    '密码长度需在6~20个字符之间': 'Password length must be between 6~20 characters',
    '密码只能由字母、数字或下划线组成': 'Password must only consist of letters, numbers or underscores',
    待开发: 'To be developed',
    修改成功: 'Modification successful',
    '您当前绑定的手机号：': 'Your currently bound mobile phone number:',
    请确认新密码: 'Please confirm new password',
    '* 密码由6-12位数字、字母、符号任意组合而成': '* The password is composed of any combination of 6-12 digits, letters, and symbols.',
    请输入区号: 'Please enter area code',
    请正确输入手机号: 'Please enter your mobile phone number correctly',
    '当前手机号与绑定号码不一致，请检查': 'The current mobile phone number is inconsistent with the bound number, please check',
    安全中心: 'Security',
    头像管理: 'Avatar',
    更换: 'replace',
    身份管理: 'Identity',
    姓名不能为空: 'Name cannot be empty',
    姓名必须为字符: 'Name must be characters',
    母亲: 'Mother',
    父亲: 'Father',
    其他监护人: 'Other guardians',
    昵称管理: 'Nickname',
    昵称不能为空: 'Nickname cannot be empty',
    昵称必须为字符: 'Nickname must be a character',
    请输入昵称: 'Please enter a nickname',
    姓名管理: 'Name',
    我的微信: 'WeChat',
    保存: 'Save',
    '上传头像图片只能是 JPG 格式!': 'Upload avatar pictures can only be in JPG format!',
    '上传头像图片大小不能超过 2MB!': 'The size of the uploaded avatar image cannot exceed 2MB!',
    '保存成功，资料待审核': 'Saved successfully, data is pending review',
    警告: 'warn',
    我的账号: 'My account',
    手机号码: 'Phone number',
    '已绑定：': 'Already bound:',
    未绑定: 'Not bound',
    登录密码: 'Login password',
    已设置: 'Already set',
    未设置: 'Not set',
    微信: 'WeChat',
    解绑账号: 'Unbind account',
    去绑定: 'Go to binding',
    '此操作解除微信绑定?': 'Is this operation to unbind WeChat?',
    微信解绑成功: 'WeChat unbinding successfully',
    已取消: 'Canceled',
    近期课程: 'Recently',
    今日课程: "Today's",
    开始上课: 'Start class',
    请进入课堂开始上课: 'Please enter the classroom to start the class',
    布置作业: 'Assign homework',
    学生考勤: 'Attendance',
    云端资源: 'Cloud',
    '当前课程暂未开始，请在开课前十分钟进入': 'The current course has not started yet. please enter ten minutes in advance.',
    当前课程暂未开始: 'The current course has not started yet',
    '您已缺课，请前往调课': 'You are absent from class, please go to class adjustment',
    课表: 'Curriculum',
    班级: 'Class',
    今日课堂: "Courses",
    我的学员: 'Students',
    作业待批改: 'Awaiting correction',
    作业新留言: 'New message',
    '温馨提示:浏览器向您获取摄像头、麦克风、音频等权限时，您需要允许或同意才能正常进入直播间。': 'Warm reminder: When the browser obtains camera, microphone, audio, etc. permissions from you, you need to allow or agree to enter the live broadcast room normally.',
    我已知晓: 'I already know',
    上一题: 'Previous question',
    下一题: 'Next question',
    我是标题1: 'I am title 1',
    几百教师端卡哈萨克记得你好快建设爱江山大家好: 'Hundreds of teachers Duanka Kazak remember you so quickly build love country Hello everyone',
    我是标题2: 'I am title 2',
    我是标题3: 'I am title 3',
    获取题目数据失败: 'Failed to obtain question data',
    '确定要退出答题吗？': 'Are you sure you want to exit the quiz?',
    所授课程: 'Lesson',
    '所授课程：': 'Lesson:',
    学员: 'Student',
    申请调课: 'Apply for course transfer',
    '课程简介：': 'Course Introduction:',
    '回放视频正在生成...': 'Playback video is being generated...',
    管理作业: 'Manage jobs',
    写反馈: 'Write',
    待反馈: 'Awaiting feedback',
    管理课程资料: 'Course materials',
    管理云端资源: 'Cloud resources',
    '教师专用  ': 'For teachers only',
    共: 'Common',
    份文件: 'documents',
    课程回放未生成: 'Course replay not generated',
    新增排课: 'Add',
    不可删除: 'Cannot be deleted',
    上课日期: 'Class date',
    选择日期: 'Select date',
    起始时间: 'start time',
    至: 'to',
    结束时间: 'end time',
    课堂名称: 'Class name',
    排课管理: 'Schedule',
    请选择日期: 'Please select a date',
    请选择时间: 'Please select a time',
    请填写活动形式: 'Please fill in the activity form',
    的课: 'class',
    '无法删除,课程不得少于一节课': 'Cannot be deleted, the course must not be less than one class',
    '是否确认删除<span style=_##_color:#ff8b02_##_>{slot1}</span>堂课?': 'Are you sure to delete the <span style="color:#ff8b02">{slot1}</span> class?',
    删除后无法恢复相关数据: 'Relevant data cannot be recovered after deletion',
    第二节: 'Section 2',
    '已上课 2024.05.01 周一 20:00-21:00': 'Already in class 2024.05.01 Monday 20:00-21:00',
    上传中: 'Uploading',
    暂无数据: 'No data yet',
    管理: 'Manage',
    '文件上传成功!': 'File uploaded successfully!',
    '确 定': 'Sure',
    '文件上传失败，请重新上传！': 'File upload failed, please upload again!',
    '取 消': 'Cancel',
    重新上传: 'Reupload',
    '文件上传中 0%，请不要关闭此页面': 'File uploading 0%, please do not close this page',
    '文件上传中 {percent}%，请不要关闭此页面': 'File uploading {percent}%, please do not close this page',
    '正在转码中 0%，请不要关闭此页面': 'Transcoding 0%, please do not close this page',
    '正在转码中 {slot1}%，请不要关闭此页面': 'Transcoding {slot1}%, please do not close this page',
    删除成功: 'Delete successfully',
    '上传文件只能是 【ppt】【pptx】【doc】【docx】【pdf】 格式!': 'Uploaded files can only be in [ppt][pptx][doc][docx][pdf] format!',
    '【doc】【docx】【pdf】格式上传文件大小不能超过 200MB!': 'The size of the uploaded file in [doc][docx][pdf] format cannot exceed 200MB!',
    '【ppt】【pptx】格式上传文件大小不能超过 500MB!': 'The size of the uploaded file in [ppt][pptx] format cannot exceed 500MB!',
    班级作业批改: 'Class homework correction',
    未提交: 'Not submitted',
    一键提醒: 'One click reminder',
    已批改: 'Corrected',
    '{slot1}提醒成功': '{slot1} reminder successful',
    一键提醒成功: 'One-click reminder successful',
    每节课只能使用一次: 'Can only be used once per class',
    通知: 'Notify',
    通讯录: 'Address book',
    教材: 'Textbook',
    课程列表: 'Course List',
    '缺课，请前往调课': 'If you are absent from class, please go to make a class adjustment',
    '待完成：': 'To be completed:',
    ' 待完成： ': ' To be completed:',
    '距开始：': 'From start:',
    '分 请至少提前十分钟进入课堂准备上课': 'Please enter the classroom at least ten minutes early to prepare for class',
    '已开课，请在30分钟内进入课堂': 'Class has started, please enter the class within 30 minutes',
    ' 待完成：': ' To be completed:',
    分请至少提前十分钟进入课堂准备上课: 'Please enter the classroom at least ten minutes early to prepare for class',
    暂无课程安排: 'No course schedule yet',
    该课程未上传教材: 'No teaching materials have been uploaded for this course',
    当前课程未开始: 'The current course has not started',
    '-发布日常作业': '-Publish daily assignments',
    作业标题: 'Title',
    '示例：5.14作业': 'Example: 5.14 Assignment',
    作业内容: 'Homework content',
    '示例 :请写一篇作文，主题是...': 'Example: Please write an essay on the topic...',
    满分: 'Full marks',
    发布: 'release',
    请输入作业标题: 'Please enter a title for the assignment',
    作业标题不能超过10个字符: 'Assignment title cannot exceed 10 characters',
    请输入作业内容: 'Please enter the assignment content',
    作业内容不能超过1000个字符: 'Assignment content cannot exceed 1000 characters',
    请输入满分分数: 'Please enter the perfect score',
    '请输入有效的分数，范围在0到100之间': 'Please enter a valid score, ranging from 0 to 100',
    请等待文件上传完成: 'Please wait for the file upload to complete',
    发布成功: 'Posted successfully',
    已上传: 'Uploaded',
    '上传失败：': 'Upload failed:',
    未找到斜杠: 'slash not found',
    '当前限制选择{maxFilesLimit}个文件，本次选择了{selectedFilesCount}个文件，共选择了{totalFilesCount}个文件': 'The current limit is {maxFilesLimit} files. This time, {selectedFilesCount} files are selected. A total of {totalFilesCount} files are selected.',
    上传文件类型错误: 'Wrong file type uploaded',
    '上传文件不能超过 500MB!': 'Uploaded files cannot exceed 500MB!',
    未反馈: 'Not rated',
    反馈: 'Rating',
    已反馈: 'Rated',
    '测验/试卷': 'Test',
    发布日常作业: 'Post a homework',
    '*快速发布简易作业': '*Quickly publish simple assignments',
    '发布测验/试卷': 'Post a quiz/exam',
    '*多种题型选择、限时试卷设置等': '*Multiple question types to choose from, time-limited test paper settings, etc.',
    '待批改(': 'Not corrected (',
    '未提交(': 'Uncommitted(',
    '已批改(': 'Graded (',
    分: 'point',
    管理课后作业: 'Manage homework',
    提醒成功: 'Reminder successful',
    '您确认直接关闭?数据将无法保存': 'Are you sure to close directly? The data will not be saved.',
    '确认删除作业吗？': 'Are you sure to delete the job?',
    '学生若开始做作业时，删除后作业立即失效，请谨慎操作!': 'If students start doing homework and the homework becomes invalid immediately after deleting it, please proceed with caution!',
    作业删除成功: 'Deleted successfully',
    详情: 'Details',
    班级作业: 'classwork',
    '-管理作业': '-Manage jobs',
    '注意:学生若开始做作业时，删除后作业立即失效,请谨慎操作!': 'Note: If students start doing homework, the homework will become invalid immediately after deletion, so please operate with caution!',
    '确定要删除作业吗？': 'Are you sure you want to delete the job?',
    课堂资料: 'Class materials',
    移除: 'Remove',
    '昵称：': 'Nick name:',
    '手机：': 'cell phone:',
    '年龄：': 'age:',
    '年级：': 'grade:',
    '家长：': 'Parents:',
    '微信：': 'WeChat:',
    '确认移除此学生吗？': 'Are you sure you want to remove this student?',
    任意时间点: 'Select time',
    确认: 'Confirm',
    请正确选择调课时间: 'Please choose the correct class adjustment time',
    '请正确选择调课时间，不得早于当前': 'Please choose the correct class adjustment time, it must not be earlier than the current time',
    您本月调课次数为0次: 'Your number of class adjustments this month is 0',
    '确定调课吗?': 'Are you sure you want to change the course?',
    调课成功: 'Course adjustment successful',
    已取消调课: 'Class adjustment has been cancelled',
    发布公告: 'Make an announcement',
    请输入发布内容: 'Please enter the content to publish',
    消息推送: 'Push message',
    '当前内容量较少，请您确认': 'The current content is small, please confirm',
    '当前内容量过少，是否发布？': 'The current content is too small. Do you want to publish it?',
    已取消发布: 'Unpublished',
    '发布失败：{slot1}': 'Publishing failed: {slot1}',
    '-发布测试/作业': '-Post tests/assignments',
    该课程共有: 'This course has a total of',
    '个匹配的题库，请选择其中一个，选择完成后不可更改。': 'There are matching question banks, please select one of them. Once the selection is completed, it cannot be changed.',
    '题库介绍：': 'Question bank introduction:',
    点击新建: 'Click New',
    预览题库: 'Preview question bank',
    作业布置成功: 'Homework assigned successfully',
    请正确选择作业: 'Please choose the assignment correctly',
    '人，缺课': 'People, absent from class',
    '人，迟到': 'Man, late',
    人: 'people',
    '已反馈(': 'Feedbacked(',
    '未反馈(': 'No feedback (',
    无: 'None',
    '课堂评分：': 'Class rating:',
    '说一说本堂课学生的表现吧~': 'Let’s talk about the performance of the students in this class~',
    '期末寄语：': 'Final message:',
    '最后一堂课了，评论下学生的表现吧~': 'It’s the last class, please comment on the students’ performance~',
    '*因学生缺课，故无课堂评分': '*Due to the student’s absence from class, there is no classroom grading',
    请输入教师评语: 'Please enter teacher comments',
    请输入期末评语: 'Please enter your final comments',
    修改课堂名称: 'Modify class name',
    '课程：': 'Course:',
    '章节：': 'Chapter:',
    '课次：': 'Lessons:',
    '(共': '( common',
    '题,': 'question, ',
    '分)': 'point )',
    题目: 'topic',
    答案解析: 'Answer analysis',
    '[解析]': '[Analysis]',
    暂无解析: 'No resolution yet',
    学生答案: 'Student answers',
    该学生本题未作答: 'The student did not answer this question',
    作业交流: 'Homework exchange',
    '确定删除留言吗？': 'Are you sure you want to delete the message?',
    暂无交流: 'No communication yet',
    发表留言: 'Leave a message',
    是否跳过选择题: 'Whether to skip multiple choice questions',
    单选题: 'Multiple choice',
    多选题: 'Multiple-choice',
    朗读题: 'Reading questions',
    主观题: 'Subjective questions',
    留言不能为空: 'Message cannot be empty',
    留言成功: 'Message successfully',
    '请输入留言...': 'Please enter a message...',
    '查看图片({slot1}/{slot2})': 'View pictures ({slot1}/{slot2})',
    上课周期: 'Class cycle',
    新增时间: 'Add time',
    请选择开始上课时间: 'Please select the start time of class',
    周日: 'Sunday',
    周一: 'Monday',
    周二: 'Tuesday',
    周三: 'Wednesday',
    周四: 'Thursday',
    周五: 'Friday',
    周六: 'Saturday',
    请选择上课时间: 'Please select a class time',
    '您有一份报告未填写，请尽快填写': 'You have a report that has not been filled in, please fill it out as soon as possible',
    新增通知: 'Add',
    老师: 'Tch',
    于: 'At',
    更新: 'renew',
    编辑: 'edit',
    通知置顶: 'Notification pinned to top',
    编辑通知: 'Edit notification',
    是否删除该公告: 'Whether to delete this announcement',
    修改公开课: 'Modify classes',
    创建公开课: 'Create a class',
    是否周期课: 'Periodic class?',
    请选择是否周期课: 'Periodic class',
    科目选择: 'Subject',
    请选择科目: 'Subject',
    课程名称: 'Name',
    '请输入（15字以内）': 'Please enter (within 15 characters)',
    '请输入（20字以内）': 'Please enter (within 20 words)',
    '请输入（50字以内）': 'Please enter (within 50 words)',
    课程封面: 'Cover',
    '小班课上限人数16人;大班课上限人数500人': 'The maximum number of students in a small class is 16; the maximum number in a large class is 500',
    '班级类型 ': 'Type',
    请选择类型: 'Type',
    学生限制: 'Restrictions',
    请输入学生人数: 'Enter the number of students',
    总课次: 'Total number of lessons',
    开始时间: 'Start time',
    选择日期时间: 'Select date time',
    '时长/课': 'Duration/class',
    请选择时长: 'Please select duration',
    是: 'yes',
    否: 'no',
    请输入课堂名称: 'Please enter a class name',
    课堂名称不能超过50个字符: 'Class name cannot exceed 50 characters',
    请上传课程封面: 'Please upload course cover',
    请选择教材: 'Please select textbook',
    请填写课次: 'Please fill in the course number',
    请填写开课时间: 'Please fill in the class start time',
    请正确填写上课时长: 'Please fill in the class duration correctly',
    请输入课堂简介: 'Please enter class introduction',
    课堂名称不能超过20个字符: 'Class name cannot exceed 20 characters',
    请选择班级类型: 'Please select class type',
    '请正确填写人数，且不得超过500': 'Please fill in the number of people correctly and cannot exceed 500',
    超出小班课人数限制: 'Exceeding the small class size limit',
    超出大班课人数限制: 'Exceeded class size limit',
    '上课时间冲突，请重新选择': 'Class time conflict, please choose again',
    请设置上课时间: 'Set a class time',
    创建成功: 'Created successfully',
    文件教材: 'Documents and teaching materials',
    课堂简介: 'Class introduction',
    班级类型: 'Class type',
    请输入关键字: 'Search',
    创建时间: 'Creation time',
    顺序: 'Order',
    分享: 'Share',
    修改: 'Revise',
    开课时间: 'Class start time',
    正序: 'Order',
    倒序: 'Reverse order',
    去调课: 'Go to adjust classes',
    '分 请提前十分钟加入课堂准备': 'Please join the class ten minutes in advance to prepare',
    '已开课，请在30分钟内开课': 'Class has started, please start within 30 minutes',
    '【汉语可比】课程名称: ': '【Chinese Comparable】Course Name:',
    '#课程号：': '#course number:',
    '复制该信息，打开手机汉语可比即可查看': 'Copy this information and open Chinese Comparable on your mobile phone to view it',
    '使用[汉语可比]APP扫描二维码,添加课程至列表': 'Use [Chinese Comparable] APP to scan the QR code and add courses to the list',
    复制信息: 'Copy information',
    复制课程号: 'Copy course number',
    二维码生成失败: 'QR code generation failed',
    只支持图片文件: 'Only supports image files',
    '确定删除该图片吗？': 'Are you sure you want to delete this image?',
    '最多上传{slot1}张图片': 'Upload up to {slot1} pictures',
    调课: 'Adjust',
    '课堂介绍：': 'Introduction:',
    已布置: 'Arranged',
    份: '',
    管理课堂资料: 'Manage classroom materials',
    文件: 'Document',
    更多: 'More',
    作业: 'Homework',
    时间: 'Time',
    开始日期: 'Start date',
    结束日期: 'End date',
    科目: 'Suject',
    年级: 'Grade',
    序号: 'Number',
    章节: 'Chapter',
    评价: 'Evaluate',
    五星: 'Five stars',
    四星: 'four stars',
    三星: 'Samsung',
    二星: 'Two stars',
    一星: 'One star',
    难度: 'Difficulty',
    状态: 'State',
    机构: 'Institution',
    查看课程: 'View courses',
    撤销: 'Cancel',
    恢复: 'Recover',
    旋转: 'Rotate',
    清空: 'Clear',
    指针: 'Pointer',
    手写: 'Writing',
    框选: 'choose',
    文字: 'Word',
    完成批改: 'Complete the correction',
    双击可编辑: 'Double click to edit',
    回放管理: 'Playback',
    视频管理: 'Video management',
    系统默认视频: 'System default video',
    手动上传视频: 'Manually upload videos',
    '支持MP4格式，限制1GB': 'Support MP4 format, limit 1GB',
    '操作提示：': 'Operation tips:',
    '1、视频来源二选一。默认来源为“系统默认视频”，系统自动生成视频；教师个人可选择“手动上传视频”，上传后需等待平台审核': '1. Choose one of two video sources. The default source is "System Default Video", and the system automatically generates videos; teachers can choose to "manually upload videos" and need to wait for platform review after uploading.',
    '2、“手动上传视频”时需等待视频上传完成后才能点击确定等待审核，上传途中关闭和退出页面将无法保存视频上传进度': '2. When "manually uploading video", you need to wait until the video upload is completed before clicking OK and waiting for review. Closing and exiting the page during the upload will not save the video upload progress.',
    请先等待视频上传完成: 'Please wait for the video to be uploaded first',
    '请上传文件大小不超过1GB的视频哦!': 'Please upload videos with file sizes no larger than 1GB!',
    请手动上传视频: 'Please upload the video manually',
    首次上课时间: 'First class time',
    类别: 'Category',
    计划上课时间: 'Plan time',
    展开: 'Expand',
    收起: 'Close',
    计划时间: 'Plan time',
    操作者: 'Operator',
    回放审核状态: 'Review status',
    系统审核通过: 'Review passed',
    人工审核通过: 'Review passed',
    审核不通过: 'Review failed',
    待审核: 'Pending review',
    回放预览: 'Playback preview',
    自学课: 'self-study course',
    教学资源: 'Teaching resources',
    课件名称: 'Courseware name',
    标题: 'Title',
    审核状态: 'Status',
    后台: 'Backstage',
    已审核: 'Reviewed',
    未通过: 'Failed',
    文件类型: 'File type',
    '云端（白板）资源': 'Cloud (whiteboard) resources',
    '此操作将永久删除该文件, 是否继续?': 'This operation will permanently delete the file. Do you want to continue?',
    '删除成功!': 'Deletion successful!',
    取消删除: 'Undelete',
    '退出登入成功！': 'Logout and login successfully!',
    HYCOOBY教师端: 'Tch',
    '切换新的共享将会关闭当前共享内容，是否切换?': 'Switching to a new share will close the current shared content. Do you want to switch?',
    不在提醒: 'No reminder',
    关闭画板: 'Close artboard',
    鼠标: 'Mouse',
    画笔: 'Brush',
    '笔画变大 红': 'Strokes become larger red',
    '笔画变小 黑': 'Strokes become smaller and black',
    上一步: 'Previous step',
    橡皮擦: 'Eraser',
    橡皮擦大小: 'Eraser size',
    添加图片: 'add picture',
    添加文字: 'Add text',
    修改文字大小: 'Modify text size',
    修改文字颜色: 'Modify text color',
    清除: 'Clear',
    销毁: 'destroy',
    '下课时间到，您是否继续上课?': 'get out of class time is over, do you want to continue the class?',
    '超过下课时间半小时后，直播间将自动下课': 'Half an hour after the end of get out of class, the live broadcast room will automatically end the get out of class',
    继续上课: 'continue class',
    结束上课: 'End of class',
    '（缺课）': '(absent)',
    '请求连麦，请确认': 'Requesting to connect to wheat, please confirm',
    同意: 'agree',
    当前有学生请求连麦: 'There are currently students requesting continuous wheat',
    '忽略(': 'Ignore(',
    查看详情: 'Check',
    '麦克风码率uplinkBitrate：': 'Microphone bit rate:',
    '麦克风丢包率uplinkLostRate：': 'Microphone packet loss rate:',
    '麦克风rtt：': 'Microphone rtt:',
    '摄像头码率uplinkBitrate：': 'Camera bit rate:',
    '摄像头帧率uplinkFrameRate：': 'Camera frame rate:',
    '摄像头丢包率：': 'Camera packet loss rate:',
    '摄像头rtt：': 'Camera rtt:',
    '分享屏幕码率uplinkBitrate：': 'Share screen bitrate:',
    '分享屏幕帧率uplinkFrameRate：': 'Share screen frame rate:',
    '分享屏幕丢包率uplinkLostRate：': 'Sharing screen packet loss rate:',
    '分享屏幕rtt：': 'Share screen rtt:',
    '当前播放器播放质量差，是否更换播放器': 'The current player has poor playback quality. Should you change the player?',
    '将会退出教室(包括学生），需重新进入教室': 'Will exit the classroom (including students) and need to re-enter the classroom',
    切换播放器: 'Switch player',
    设置: 'Set up',
    提示音: 'Prompt sound',
    正在讲话: 'Speaking',
    只看老师和自己: 'Just look at the teacher and myself',
    '正在讲话：': 'Speaking:',
    '年龄:': 'Age:',
    学生资料: 'student information',
    '家长姓名：': 'Parent’s name:',
    '学生姓名:': 'Student name:',
    请输入内容: 'Please enter content',
    解除全体禁言: 'Unban all',
    全体禁言: 'All muted',
    '在线(': 'Online (',
    '举手(': 'Raised hand(',
    是否连麦: 'Whether to connect wheat',
    是否禁言: 'Chat',
    踢出管理: 'Kick out',
    挂断: 'Off',
    接听: 'Answer',
    邀请连麦: 'Call up',
    解除移出: 'Release removal',
    移除课堂: 'Remove class',
    '上传中0%': 'Uploading 0%',
    '撤销：ctrl+z': 'Undo: ctrl+z',
    '恢复：ctrl+y': 'Recovery: ctrl+y',
    '清空：ctrl+c': 'Clear: ctrl+c',
    缩小: 'zoom out',
    放大: 'enlarge',
    '上一页：←': 'Previous page: ←',
    '下一页：→': 'Next page: →',
    添加页面: 'Add page',
    删除页面: 'Delete page',
    跳转页面: 'Jump page',
    跳转到指定页面: 'Jump to specified page',
    第: 'No.',
    页: 'Page',
    '首页无法删除只能清空，是否清空？': 'The homepage cannot be deleted and can only be cleared. Do you want to clear it?',
    '是否删除当前页？': 'Delete current page?',
    实线: 'solid line',
    虚线: 'dotted line',
    细: 'thin',
    粗: 'thick',
    '画笔涂鸦元素(包括画笔，荧光笔)': 'Brush graffiti elements (including brushes, highlighters)',
    '图形涂鸦元素(包括直线，圆，椭圆，矩形，圆锥，三角形等几何图形)': 'Graphic graffiti elements (including straight lines, circles, ellipses, rectangles, cones, triangles and other geometric shapes)',
    文本元素: 'text element',
    学科公式: 'subject formula',
    自定义图形元素: 'Custom graphic elements',
    图片元素: 'Picture elements',
    h5元素: 'h5 element',
    魔法笔: 'Magic',
    '白板初始化中...': 'Whiteboard initializing...',
    '初始化失败，请重试': 'Initialization failed, please try again',
    '服务鉴权失败，请先购买服务': 'Service authentication failed, please purchase service first',
    '白板加载失败，请重试': 'Whiteboard loading failed, please try again',
    '同步历史数据失败，请重试': 'Failed to synchronize historical data, please try again',
    '白板运行错误，请检查sdkAppId，userId, userSig是否正确': 'Whiteboard operation error, please check whether sdkAppId, userId, userSig are correct',
    '服务鉴权超时，请重试': 'Service authentication timed out, please try again',
    单课堂内白板页数已经到达上限: 'The number of whiteboard pages in a single classroom has reached the upper limit',
    'userSig过期了，请重新生成新的userSig，再重新初始化白板': 'userSig has expired. Please regenerate a new userSig and re-initialize the whiteboard.',
    '实时数据格式错误，请检查白板信令是否有进行二次包装': 'The real-time data format is wrong. Please check whether the whiteboard signaling has been repackaged.',
    重复添加文件: 'Add files repeatedly',
    '非法操作，请在历史数据完成回调后再调用sdk相关接口': 'Illegal operation, please call the SDK related interface after the historical data completes the callback.',
    涂鸦丢失: 'graffiti lost',
    自定义图形url为空: 'Custom graphic url is empty',
    图片组超大: 'Picture group is very large',
    '多媒体资源码率大于2048kb/s，网络不好情况下容易造成卡顿，建议对视频码率进行压缩': 'The bit rate of multimedia resources is greater than 2048kb/s, which can easily cause lags when the network is not good. It is recommended to compress the video bit rate.',
    '已经存在图片水印，不能重复添加': 'The image watermark already exists and cannot be added repeatedly.',
    数学公式库没有重新加载: 'Math formula library not reloaded',
    非法的数学公式: 'Illegal mathematical formula',
    '已经存在文本水印，不能重复添加': 'The text watermark already exists and cannot be added again.',
    元素类型不存在: 'Element type does not exist',
    元素ID不存在: 'Element ID does not exist',
    '视频播放/加载失败': 'Video play/load failed',
    '转码失败code:{slot1} message:{slot2}': 'Transcoding failed code:{slot1} message:{slot2}',
    转码失败: 'Transcoding failed',
    '上传中，当前进度:{slot1}%': 'Uploading, current progress: {slot1}%',
    创建转码任务: 'Create a transcoding task',
    正在排队等待转码: 'Queuing for transcoding',
    '转码中，当前进度:{slot1}%': 'Transcoding, current progress: {slot1}%',
    转码完成: 'Transcoding completed',
    无操作权限: 'No operation permission',
    导入数据成功: 'Import data successfully',
    导入数据失败: 'Import data failed',
    ppt加载中: 'ppt is loading',
    ppt加载完成: 'ppt loading completed',
    ppt取消加载: 'ppt cancel loading',
    ppt加载超时: 'ppt loading timeout',
    ppt资源加载失败: 'ppt resource failed to load',
    ppt内部运行错误: 'ppt internal running error',
    调用addTranscodeFile接口添加文件的时候超时: 'Timeout when calling the addTranscodeFile interface to add a file.',
    ppt内部的图片资源加载异常: 'Image resources inside ppt are loading abnormally',
    '缩放移动(shift+滚轮可放大缩小)': 'Zoom movement (shift+wheel can zoom in and out)',
    移动: 'Move',
    播放音视频: 'Play audio and video',
    可批量选中元素进行编辑: 'Elements can be selected in batches for editing',
    选框: 'Marquee',
    任意位置涂鸦: 'Graffiti anywhere',
    选择类型: 'Select type',
    荧光笔: 'Magic pen',
    调整大小: 'Resize',
    调整形状: 'Shape',
    调整颜色: 'Color',
    任意位置输入文字: 'Enter text anywhere',
    字号大小: 'Font size',
    字体颜色: 'Font color',
    擦除画笔或文字内容: 'Erase brush or text content',
    橡皮: 'Eraser',
    突出重点内容: 'Highlight key content',
    激光笔: 'Laser',
    '可上传图片、音频、视频': 'Can upload pictures, audio and video',
    图片资源: 'Picture',
    '音频（MP3）': 'Audio (MP3)',
    '视频（MP4）': 'Video (MP4)',
    多媒体: 'Media',
    '支持word、ppt、pdf': 'Support word, ppt, pdf',
    文件列表: 'List',
    '*您可从“云端资源”添加至此列表进行使用': '*You can add it to this list from "Cloud Resources" for use',
    空白白板: 'Blank whiteboard',
    '*点击添加，可以添加至“文件列表”并打开使用': '*Click Add to add it to the "File List" and open it for use',
    本节课堂资源: 'Course resourcesn',
    添加: 'Add',
    已添加: 'Added',
    暂无云端资源: 'No cloud resources yet',
    上传本地文件: 'Upload files',
    '*此列表仅展示已收藏的书本资源': '*This list only displays collected book resources',
    前往: 'Go to',
    '若需更多资源，前往资源广场>>': 'More resources >>',
    课件: 'Cloud',
    请选择转码方式: 'Please select a transcoding method',
    '保留动画效果，需要时间较久': 'It takes a long time to retain the animation effect',
    '转为静态图片，速度快': 'Convert to static images quickly',
    '您可以在这里快速上传/使用课件哦~': 'You can quickly upload/use courseware here~',
    我知道了: 'I see',
    取消成功: 'Cancellation successful',
    '文件已上传，即将为您打开': 'The file has been uploaded and will be opened for you soon',
    '文件上传失败，请重试': 'File upload failed, please try again',
    '测试文件-{slot1}': 'Test file-{slot1}',
    转码中: 'Transcoding',
    '上传图片只能是 【jpg】【png】【jpeg】 格式!': 'Upload pictures can only be in [jpg][png][jpeg] format!',
    '上传图片大小不能超过 1G!': 'The size of the uploaded image cannot exceed 1G!',
    '上传文件只能是 【mp3】格式!': 'Uploaded files can only be in [mp3] format!',
    '上传mp3大小不能超过 1G!': 'The size of uploaded mp3 cannot exceed 1G!',
    '上传文件只能是 【mp4】格式!': 'Uploaded files can only be in [mp4] format!',
    '上传mp4大小不能超过 1G!': 'The size of uploaded mp4 cannot exceed 1G!',
    '上传文件大小不能超过 200M!': 'The uploaded file size cannot exceed 200M!',
    当前有文件正在上传中: 'There are files currently being uploaded',
    '转码中0%': '0% transcoding',
    '请老师准备好，即将开始上课！': 'Teacher, please get ready, class is about to begin!',
    已结束学生: 'Ended student',
    结束学生连麦失败: 'Ending students’ failure to connect to wheat',
    同意开启麦克风: 'Agree to turn on microphone',
    同意开启摄像头: 'Agree to turn on the camera',
    同意连麦: 'Agree with Lianmai',
    不同意连麦: "Don't agree with Lian Mai",
    拒绝开启麦克风: 'Refuse to turn on the microphone',
    拒绝开启摄像头: 'Refuse to turn on the camera',
    取消请求连麦: 'Cancel request for continuous wheat',
    已结束连麦: 'The wheat streak has ended',
    '学生下台操作失败，请重试': 'The operation of student stepping down failed, please try again.',
    '同意学生{name}连麦': 'Agree with student {name} Lianmai',
    同意学生连麦失败: 'Agree that students failed to connect to wheat',
    '拒绝学生{userName}连麦成功': 'Rejected student {userName} successfully connected to wheat',
    拒绝学生连麦失败: 'Rejected students failed to connect to wheat',
    禁言所有学生发言成功: 'Succeeded in muting all students from speaking',
    禁言所有学生发言失败: 'Failed to mute all students from speaking',
    取消全体禁言成功: 'Unban all bans successfully',
    取消全体禁言失败: 'Failed to remove all bans',
    关闭学生: 'Close',
    发言成功: 'Speech successful',
    开启学生: 'Opens',
    切换学生摄像头失败: 'Failed to switch student camera',
    '您确定要下课吗, 是否继续?': 'Are you sure you want to end get out of class? Do you want to continue?',
    您已取消下课: 'You have canceled the get out of class',
    关闭老师麦克风成功: 'Turn off teacher microphone successfully',
    '正在开启老师麦克风中,请勿多次点击,请稍等...': "Opening the teacher's microphone, please do not click multiple times, please wait...",
    开启老师麦克风成功: 'Turn on teacher microphone successfully',
    关闭老师摄像头成功: "Turn off teacher's camera successfully",
    '开启摄像头后，共享屏幕将自动关闭': 'After turning on the camera, the shared screen will automatically turn off',
    '开启共享屏幕后，摄像头将自动关闭': 'After turning on screen sharing, the camera will automatically turn off',
    '正在开启老师共享视频中,请勿多次点击,请稍等...': "The teacher's shared video is being opened. Please do not click multiple times. Please wait...",
    '学生未上线,不能上台': 'Students are not online and cannot go on stage',
    其他学生已经处于连麦状态: 'Other students are already in the continuous wheat state',
    '已有学生在连麦,不能再邀请学生连麦': 'There are already students in Lianmai. No more students can be invited to Lianmai.',
    拒绝学生连麦成功: 'Rejected students successfully connected with wheat',
    已取消邀请学生: 'Student uninvited',
    取消邀请学生连麦失败: 'Uninviting students failed to connect to wheat',
    已邀请学生: 'Students have been invited',
    邀请学生连麦失败: 'Failed to invite students to connect to wheat',
    麦克风成功: 'Microphone successful',
    已通知学生: 'Students have been notified',
    开启麦克风: 'Open Mic',
    切换学生麦克风失败: 'Failed to switch student microphone',
    摄像头成功: 'Camera successful',
    开启摄像头: 'Open camera',
    邀请该学生请入教室成功: 'Invite the student into the classroom successfully',
    '踢出课堂后，{slot1}无法再进入本课堂是否确认?': 'After being kicked out of the class, {slot1} can no longer enter this class. Are you sure?',
    '该学生{slot1}请出教室成功': 'The student {slot1} successfully left the classroom',
    '通知学生{slot1}请出教室成功': 'Notify students {slot1} to please leave the classroom successfully',
    '通知学生{slot1}请出教室失败': 'Notify students that {slot1} failed to leave the classroom',
    '切换共享屏幕失败，可能将导致直播录制失败，请重新进入直播间重新推流，如若无法解决请联系人工客服': 'Failure to switch the shared screen may cause the live broadcast recording to fail. Please re-enter the live broadcast room and push the stream again. If the problem cannot be solved, please contact customer service.',
    重新进入直播间: 'Re-enter the live broadcast room',
    '超过下课时间半小时,自动下课': 'If the get out of class ends half an hour later, the get out of class will be dismissed automatically',
    '下课通知失败,请刷新页面': 'End of get out of class notification failed, please refresh the page',
    本次回放已保存: 'This replay has been saved',
    '应到：': 'Should arrive at:',
    '实到：': 'Actual:',
    '未到：': 'Not yet arrived:',
    共享屏幕未开启: 'Screen sharing is not enabled',
    正在演讲: 'Giving a speech',
    '学生:': 'Student:',
    '{stuName}发起了举手': '{stuName} initiated a raise of hands',
    回答学生上台失败: "Reply to student's failure to get on stage",
    已结束上台演讲: 'Finished speaking on stage',
    禁言所有学生麦克风成功: 'Succeeded in muting all student microphones',
    禁言所有学生麦克风失败: 'Failed to mute all student microphones',
    开启老师共享屏幕成功: 'Enable teacher screen sharing successfully',
    '已有学生上台，请先下台后再邀请学生上台': 'There are already students on stage. Please step down first and then invite students to come on stage.',
    上台演讲: 'Speech on stage',
    邀请学生上台失败: 'Failed to invite students to the stage',
    让学生重新进入房间失败: 'Failed to let students re-enter the room',
    上: 'Superior',
    下: 'Down',
    '老师{slot1}台失败': 'Teacher {slot1} failed',
    直播已结束: 'Live broadcast has ended',
    本次回放已保: 'This replay has been saved',
    写课堂反馈: 'Write class feedback',
    反馈完成: 'Feedback completed',
    麦克风加屏幕分享加摄像头: 'Microphone plus screen sharing plus camera',
    是否录制视频: 'Whether to record video',
    共享屏幕: 'share screen',
    学生管理: 'Stu management',
    切换至主视窗: 'Switch windows',
    邀请演讲: 'Invited speech',
    结束演讲: 'End speech',
    联系: 'Connect',
    关闭麦克风: 'Close Mic',
    关闭摄像头: 'Close camera',
    打开摄像头: 'Open camera',
    全体静音: 'Mute all',
    解除全体静音: 'Unmute all',
    移出课堂: 'Move out',
    通知下课失败: 'Notification of dismissal of get out of class failed',
    关闭老师麦克风失败: 'Failed to turn off teacher microphone',
    关闭老师摄像头失败: "Failed to turn off teacher's camera",
    '接听或发起新的连麦，当前连麦将会挂断，是否确定?': 'If you answer or initiate a new mic connection, the current mic connection will be hung up. Are you sure?',
    推送老师麦克风失败: 'Failed to push teacher microphone',
    '上课失败,请刷新页面!!!': 'Class failed, please refresh the page!!!',
    白板互动成功: 'Whiteboard interaction successful',
    切换学生白板互动失败: 'Failed to switch student whiteboard interaction',
    你正在共享屏幕: 'You are sharing your screen',
    结束共享: 'End sharing',
    切换主视窗: 'Switch main window',
    请求连麦: 'Request for continuous wheat',
    正在呼叫: 'Calling',
    推送老师摄像头失败: 'Failed to push teacher camera',
    开启老师麦克风失败: 'Failed to turn on teacher microphone',
    最多允许一名学生白板互动: 'Allow at most one student to interact with the whiteboard',
    '教师辛苦了，您可以对学生进行课堂反馈哦~': 'Thank you for your hard work, teachers. You can give class feedback to students~',
    当前设备未检测到摄像头: 'The current device does not detect the camera',
    '请检测摄像头是否损坏或接口松动，若无问题，请检查是否开启了浏览器授权': 'Please check whether the camera is damaged or the interface is loose. If there is no problem, please check whether browser authorization is turned on.',
    设置方法请查看教程: 'Please see the tutorial for how to set it up.',
    摄像头已关闭: 'camera is off',
    请进入教室上课: 'Please enter the classroom for class',
    结束白板: 'End whiteboard',
    共享白板: 'Share whiteboard',
    切换共享窗口: 'Switch sharing window',
    新的共享: 'new share',
    '聊天室（': 'chat room (',
    重新连接: 'reconnect',
    白板互动: 'Whiteboard',
    客服消息: 'Customer service message',
    '您反应的问题已由专人进行回复，': 'Your question has been answered by a dedicated person.',
    '查看详情>>': 'View details >>',
    '作业已失效，无法查看评论': 'The assignment has expired and comments cannot be viewed',
    '标题：': 'title:',
    '图片：': 'picture:',
    '图文内容：': 'Graphic content:',
    建议仅在题目较为复杂的时候使用: 'It is recommended to use it only when the topic is more complex.',
    '答题解析：': 'Answer analysis:',
    '难度：': 'Difficulty:',
    '题目类型：': 'Question type:',
    '3保存题目后，题目类型不可修改': '3After saving the question, the question type cannot be modified.',
    '文本答题：': 'Text answer:',
    '拍照答题：': 'Taking photos and answering questions:',
    标题为必填项: 'Title is required',
    题目类型为必填项: 'Question type is required',
    请至少选择一种答题方式: 'Please choose at least one way to answer the question',
    文本答题: 'Text',
    拍照答题: 'Take photos',
    '你还未设置确定答案。': "You haven't set a confirmed answer yet.",
    '选项标题均为必填内容。': 'Option titles are required.',
    '选择题至少需要一个选项。': 'Multiple choice questions require at least one option.',
    '请至少选择一种答题方式。': 'Please select at least one way to answer the question.',
    关联课程: 'Related courses',
    关联班级: 'Associated classes',
    关联章节: 'Related chapters',
    简单: 'Simple',
    一般: 'generally',
    困难: 'difficulty',
    语文: 'Chinese',
    数学: 'math',
    英语: 'English',
    一班: 'class one',
    二班: 'second shift',
    三班: 'Class three',
    第一章: 'Chapter 1',
    第二章: 'Chapter 2',
    第三章: 'Chapter 3',
    科目为必填项: 'Subject is required',
    开始时间为必填项: 'Start time is required',
    时长为必填项: 'Duration is required',
    请选择年级: 'Please select grade',
    '题目标题均为必填内容。': 'All questions and titles are required.',
    添加成功: 'Added successfully',
    '附件：': 'appendix:',
    图片上传: 'Image upload',
    附件上传: 'Attachment upload',
    '{slot1} 文件超过100MB，禁止上传': '{slot1} file exceeds 100MB, uploading is prohibited',
    上传已取消: 'Upload canceled',
    最多上传九份附件: 'Up to nine attachments can be uploaded',
    最多上传9张图片: 'Up to 9 pictures can be uploaded',
    类型: 'Type',
    课堂状态: 'Class status',
    题型: 'Question type',
    审核未通过: 'Review failed',
    教师评分: 'Teacher rating',
    请打分: 'Please rated',
    提交评分: 'Submit rating',
    该小题满分: 'Full marks for this question',
    '作业标题: ': 'Title:',
    '得分/满分': 'score/full score',
    提交时间: 'Submission time',
    试卷: 'test paper',
    作业类型: 'Type',
    日常作业1: 'Homework',
    修改评分: 'Modify rating',
    的: 'of',
    '本题得分： ': 'Score for this question:',
    系统已自动评分: 'The system has automatically scored',
    评分: 'Score',
    '当前总分：': 'Current total score:',
    未批改: 'Not corrected',
    未作答: 'No answer',
    提交批改: 'Submit for correction',
    教师评语: 'Teacher comments',
    请输入评语: 'Please enter a comment',
    '*每一次进步都值得被肯定': '*Every progress deserves recognition',
    '批改图片({slot1}/{slot2})': 'Correction of pictures ({slot1}/{slot2})',
    有题未批改: 'Questions not corrected',
    当前题目未批改: 'The current question has not been corrected',
    提交批改成功: 'Submission for correction successful',
    提交批改失败: 'Submission for review failed',
    发布作业: 'Post job',
    复制: 'copy',
    '确定删除作业？': 'Are you sure you want to delete the job?',
    '{slot1}删除成功': '{slot1} was deleted successfully',
    '描述：': 'Describe:',
    '作业内容：': 'Content:',
    '班级：': 'Class:',
    '作业类型：': 'Type:',
    '限制时长：': 'Limited time:',
    '作业标题：': 'Title:',
    '满分：': 'Full score:',
    '开始时间：': 'Start time:',
    '时长(分)：': 'Duration (minutes):',
    '示例：请写一篇作文，主题是...': 'Example: Please write an essay on the topic...',
    修改作业: 'Modify assignment',
    '是否确认发布？': 'Confirm release?',
    请选择课程: 'Please select a course',
    请选择班级: 'Please select a class',
    请选择章节: 'Please select a chapter',
    请选择试卷类型: 'Please select test paper type',
    作业内容为必填项: 'Assignment content is required',
    '请输入有效的分数，范围在0到1000之间': 'Please enter a valid score, ranging from 0 to 1000',
    管理题库: 'Manage question bank',
    新增: 'New',
    批量添加: 'Add in batches',
    题目类型: 'question type',
    '标题： ': 'Title:',
    学年: 'school year',
    '批量添加题库（待开发）': 'Add question banks in batches (to be developed)',
    '添加题目：': 'Add a question:',
    '题型：': 'Question type:',
    '总题数：': 'Total number of questions:',
    '总分：': 'Total score:',
    添加小题: 'Add',
    排序: 'Sort',
    答题解析: 'Answer analysis',
    分数: 'Fraction',
    '大题删除后，相应的小题也会同时删除！': 'After deleting the major question, the corresponding sub-questions will also be deleted at the same time!',
    试题标题: 'Question title',
    '年级/科目': 'Grade/Subject',
    教材章节: 'Textbook chapters',
    带回1: 'Bring back 1',
    学段: 'Stage',
    '前端提醒，此处的多选为bug，点击一次后切换有问题，流程测试时请注意': 'Front-end reminder, the multi-selection here is a bug. There is a problem with switching after clicking once. Please pay attention when testing the process.',
    带回0: 'Bring back 0',
    '关联课程：': 'Related courses:',
    '课程章节：': 'Course Chapters:',
    '科目：': 'Suject:',
    '教材：': 'Textbook:',
    '1保存题目后，题目类型不可修改': '1After saving the question, the question type cannot be modified.',
    必填项不能为空: 'Required fields cannot be empty',
    '添加小题-': 'Add a question -',
    '答案解析：': 'Answer analysis:',
    请输入答案解析: 'Please enter answer analysis',
    '更多 ': 'More ',
    正确选项: 'Correct option',
    '+ 添加选项': '+ Add options',
    '正确选项：': 'Correct option:',
    '选项标题：': 'Option title:',
    建议仅在较为复杂的情况使用: 'It is recommended to only use it in more complex situations',
    选项: 'Options',
    选项标题不能为空: 'Option title cannot be empty',
    '最多支持6个选项。': 'Supports up to 6 options.',
    新增选项: 'New options',
    '保存题目后，题目类型不可修改': 'After saving the question, the question type cannot be modified.',
    建议仅在题目较为复杂的时候使用2: 'It is recommended to use 2 only when the questions are more complex.',
    '拍照答题7：': 'Taking photos to answer question 7:',
    模板名称: 'Template name',
    作业名称: 'Job name',
    '作业名称： ': 'Job name:',
    课堂作业: 'Classwork',
    总分: 'total score',
    题数: 'Number of questions',
    '学生若开始做作业时，删除后作业立即失效，请谨慎操作！': 'If students start doing homework and the homework becomes invalid immediately after deleting it, please proceed with caution!',
    修改模板: 'Modify template',
    发布模板: 'publish template',
    '教材章节：': 'Textbook Chapter:',
    是否确认发布: 'Confirm release or not',
    同时保存为模板: 'Also save as template',
    添加题目: 'Add question',
    题目模板: 'question template',
    带回: 'bring back',
    导入题目: 'Import questions',
    Excel导入: 'Excel import',
    图片: 'picture',
    ' 编辑 ': ' edit ',
    上次使用: 'last used',
    该书本无目录: 'This book has no table of contents',
    暂无资源: 'No resources yet',
    其他教育: 'Other education',
    搜索资源: 'Search resources',
    学科教育: 'subject education',
    查看书本详情: 'Book details',
    书本: 'Book',
    '文件大小：': 'File size:',
    '文件格式：': 'File format:',
    '提供者：': 'Provider:',
    播放: 'Play',
    取消收藏: 'Cancel favorites',
    收藏: 'collect',
    添加到课堂: 'Add to class',
    取消收藏成功: 'Cancel collection successfully',
    收藏成功: 'Collection successful',
    '当前暂无执教班级，无法添加': 'There are currently no classes to teach and cannot be added.',
    选择班级: 'Select class',
    选择章节: 'Select chapter',
    选择位置: 'Select location',
    '*只能获取到自己正在执教中到班级': '*You can only get the class you are teaching in',
    上次添加: 'last added',
    请选择位置: 'Please select a location',
    '* 密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型': '* The password can use 8-16 digits, letters or common symbols, and contain at least three or more character types',
    '密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型': 'The password can use 8-16 digits, letters or common symbols, and contain at least three or more character types',
    '* 密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型。': '* The password can use 8-16 digits, letters or common symbols, and contain at least three or more character types.',
    修改绑定: 'Modify binding',
    请输入原手机号: 'Please enter your original mobile phone number',
    换绑成功: 'Successfully changed the binding',
    '账号：': 'Account:',
    账号隐私: 'Account privacy',
    我的姓名: 'Name',
    请输入姓名: 'Please enter name',
    个人简介: 'Profile',
    简介不能为空: 'Introduction cannot be empty',
    手机: 'cell phone',
    立即设置: 'Set up now',
    解除绑定: 'Unbind',
    账号密码: 'Account password',
    "历史课堂资源": "History",
    '正在进入课堂，请稍后...': 'Entering class, please wait...',
    '网络恢复，课堂连接正常': 'The network is restored and the classroom connection is normal.',
    '网络异常，课堂连接中断，正在尝试重新连接': 'Network abnormality, classroom connection interrupted, trying to reconnect',
    '网络异常，课堂自动重连失败，请检查网络连接或重新进入教室': 'The network is abnormal and the class fails to automatically reconnect. Please check the network connection or re-enter the classroom.',
    '网络恢复，im连接正常': 'The network is restored and the IM connection is normal.',
    '网络异常，im连接中断，正在尝试重新连接': 'Network abnormality, im connection interrupted, trying to reconnect',
    '网络异常，白板加载失败，请重新进入课堂': 'Network exception. Whiteboard loading failed. Trying to reconnect.',
    '忽略': 'Ignore',
    '重新进入教室': 'Re-enter',
    限制人数: 'Limit number of people',
    '当您与老师失去通信时（听不见声音或看不见画面），您可以尝试点击此按钮': 'When you lose communication with your teacher (cannot hear or see See picture), you can try to click this button',
    课堂: 'Course',
    '恢复通信': 'Restore communication',
    '请填写课堂名称': 'Please fill in the class name',
    '长度在 3 到 50 个字符': 'Length between 3 and 50 characters',
    '开课时间需大于当前时间': 'The opening time needs to be greater than the current time',
    '检测到该时段内，您已有排课，是否继续确认排课?': 'Detected that you have already scheduled a course in this period. Do you want to continue to confirm the course?',
    '结束时间不能小于开始时间': 'End time cannot be less than start time',
    '闽公网安备35010302000734号': 'Fujian Public Network Security File No. 35010302000734',
    '视频不支持插入图片': 'Video does not support inserting pictures'
  }