<template>
  <div class="dialog-pc" v-if="visible">
    <div class="dialog-main">
      <div class="dialog-title">{{ title }}</div>
      <div class="dialog-body">
        <p class="p1" v-if="content">{{ content }}</p>
        <p class="p1" v-if="contentHtml" v-html="contentHtml"></p>
        <p v-if="tip" class="p2">{{ tip }}</p>
      </div>
      <div class="dialog-footer">
        <div class="btn confirm" @click="handleConfirm">{{ confirm }}</div>
        <div class="btn cancel" @click="handleCancel">{{ cancel }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from '@/locale'

export default {
  data() {
    return {
      visible: true,
      confirm:i18n.t('确定'),
      cancel:i18n.t('取消')
    }
  },
  props: {
    title: {
      type: String,
      default() {
        return i18n.t('提示')
      }

    },
    content: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    contentHtml: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleConfirm() {
      this.visible = false
      this.$emit('confirm')
    },
    handleCancel() {
      this.visible = false
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog-pc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .dialog-main {
    width: 500px;
    background-color: #ffffff;
    border-radius: 30px;

    .dialog-title {
      width: 500px;
      height: 60px;
      background-color: #f5f5f5;
      border-radius: 30px 30px 0px 0px;
      box-sizing: border-box;
      padding: 0 30px;
      line-height: 60px;
      font-size: 18px;
      font-weight: bold;
    }

    .dialog-body {
      width: 500px;
      min-height: 120px;
      padding: 30px;
      box-sizing: border-box;

      .p1 {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }

      .p2 {
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;
        color: #999999;
      }
    }

    .dialog-footer {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 30px;
      box-sizing: border-box;

      .btn {
        width: 100px;
        height: 36px;
        border-radius: 18px;
        border: solid 1px #cccccc;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        color: #333333;
        cursor: pointer;

        &.confirm {
          margin-right: 20px;
          border: none;
          background-color: #ffc600;
          color: #723500;
        }
      }
    }
  }
}
</style>
