const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  refreshToken: state => state.user.refreshToken,
  userInfo: state => state.user.userInfo,
  roles: state => state.user.roles,
  isLogin: state => state.user.isLogin,
  loginStatus: state => state.user.loginStatus,
  deviceId: state => state.user.deviceId,
  areaCode: state => state.user.areaCode,
  permission_routes: state => state.permission.routes,
  mobileToken: state => state.mobileUser.token,
  Headers: state => state.mobileUser.Headers,
  MdeviceId: state => state.mobileUser.MdeviceId,
  MuserInfo: state => state.mobileUser.MuserInfo,
  MclientType: state => state.mobileUser.MclientType,
  MorgDesc: state => state.mobileUser.MorgDesc,
  McourseDetail: state => state.mobileUser.McourseDetail,
  Mtimezone: state => state.mobileUser.Mtimezone,
  Mlanguage: state => state.mobileUser.Mlanguage,
  Privacy: state => state.userdata.privacy, //用户隐私
  SecretKey: state => state.userdata.secretKey, //获取的临时秘钥（腾讯云）
  // roles: state => state.user.roles,
  buttons: state => state.user.buttons,
  imArr: state => state.live.imArr,
  chatRoomId: state => state.live.chatRoomId,
  studentList: state => state.live.studentList,
  isForbidChat: state => state.live.isForbidChat,
  tchInvitationchat: state => state.live.tchInvitationchat,
  unreadMessage: state => state.live.unreadMessage,
  imLogin: state => state.live.imLogin,
  classStartTime: state => state.live.classStartTime,
  liveAudioStatus: state => state.live.liveAudioStatus,
  imIsError: state => state.live.imIsError,
  imErrorMsg: state => state.live.imErrorMsg,

  //时区
  timezone: state => state.userdata.timezone,
  //组件数据
  classtexts: state => state.userdata.classtext,
  classinfor: state => state.userdata.classinfor,
  dataclass: state => state.userdata.dataclass,
  teacher: state => state.userdata.teacher,
  paperids: state => state.userdata.paperid,
  questionIds: state => state.userdata.questionId,
  questiondata: state => state.userdata.questiondata,
  revisepublic: state => state.userdata.revisepublic,
  publictea: state => state.userdata.publictea,
  publicInfo: state => state.userdata.publicInfo,
  reminderData: state => state.userdata.reminderData,
  publicData: state => state.userdata.publicData,
  createOrUpdate: state => state.userdata.createOrUpdate,
  classReminderData: state => state.userdata.classReminderData,

  // tch.js
  showHomeWorkDialog: state => state.tch.showHomeWorkDialog,
  showCorrectDialog: state => state.tch.showCorrectDialog,
  showFeedBackDialog: state => state.tch.showFeedBackDialog,
  showCloudDialog: state => state.tch.showCloudDialog,

  //组件状态
  maillistbool: state => state.userbool.maillistbool,
  classinforbool: state => state.userbool.classinforbool,
  course: state => state.userbool.course,
  materials: state => state.userbool.materials,
  homework: state => state.userbool.homework,
  question: state => state.userbool.question,
  dailywork: state => state.userbool.dailywork,
  correct: state => state.userbool.correct,
  feedback: state => state.userbool.feedback,
  notice: state => state.userbool.notice,
  createpublic: state => state.userbool.createpublic,
  reminderBool: state => state.userbool.reminderBool,
  publicBool: state => state.userbool.publicBool,
  cloudBool: state => state.userbool.cloudBool,
  // 直播
  liveType: state => state.live.liveType,
  showBarrage: state => state.live.showBarrage,
  //↓↓↓学生端数据↓↓↓
  stu_course: state => state.userdata.stu_course,
  stu_list: state => state.userdata.stu_list,//该账号的学生列表
  student: state => state.userdata.student
}
export default getters
