export default {
  确定: '确定',
  取消: '取消',
  提示: '提示',
  请选择: '请选择',
  手机区号: '手机区号',
  无匹配数据: '无匹配数据',
  上传图片: '上传图片',
  点击上传: '点击上传',
  '请等待所有图片上传成功。如果出现网络问题，请刷新页面重新上传!':
    '请等待所有图片上传成功。如果出现网络问题，请刷新页面重新上传!',
  上传视频: '上传视频',
  请先上传视频: '请先上传视频',
  图片批量上传: '图片批量上传',
  '正在转换中...': '正在转换中...',
  '目前仅支持docx文件格式的Word文档，若为doc格式的文档，请通过word另存为docx格式的文件后重试':
    '目前仅支持docx文件格式的Word文档，若为doc格式的文档，请通过word另存为docx格式的文件后重试',
  图片剪裁: '图片剪裁',
  '图片未加载完成，请稍后再试': '图片未加载完成，请稍后再试',
  上传: '上传',
  图片预览: '图片预览',
  浏览: '浏览',
  请上传正确的文件格式: '请上传正确的文件格式',
  上传文件大小不能超过: '上传文件大小不能超过',
  'MB哦!': 'MB哦!',
  '上传100%': '上传100%',
  上传成功: '上传成功',
  删除: '删除',
  预览: '预览',
  '支持.mp4,.avi,.mov,.mpg,.mpeg格式': '支持.mp4,.avi,.mov,.mpg,.mpeg格式',
  视频预览: '视频预览',
  '视频格式有误，上传失败': '视频格式有误，上传失败',
  '请上传文件大小不超过500MB的视频哦!': '请上传文件大小不超过500MB的视频哦!',
  成功: '成功',
  '确定要删除?': '确定要删除?',
  '将图片拖到此处，或': '将图片拖到此处，或',
  请选择封面图片: '请选择封面图片',
  选择封面图: '选择封面图',
  图片上传成功: '图片上传成功',
  请上传正确的图片格式: '请上传正确的图片格式',
  上传图片大小不能超过: '上传图片大小不能超过',
  内容中无有效的图片: '内容中无有效的图片',
  请先选择一张图片: '请先选择一张图片',
  取消上传: '取消上传',
  请上传正确的视频格式: '请上传正确的视频格式',
  上传视频大小不能超过: '上传视频大小不能超过',
  key值不存在: 'key值不存在',
  请完成安全验证: '请完成安全验证',
  验证成功: '验证成功',
  验证失败: '验证失败',
  '请依次点击【': '请依次点击【',
  向右滑动完成验证: '向右滑动完成验证',
  '{slot1}s验证成功': '{slot1}s验证成功',
  汉语可比: '汉语可比',
  官网: '官网',
  登入: '登入',
  微信登入: '微信登入',
  微信绑定: '微信绑定',
  隐私政策: '隐私政策',
  用户协议: '用户协议',
  退款规则: '退款规则',
  日常作业: '日常作业',
  日常作业批改: '日常作业批改',
  日常作业查看: '日常作业查看',
  写作业: '写作业',
  提交成功: '提交成功',
  作业点评: '作业点评',
  批改: '批改',
  课程简介: '课程简介',
  上课流程: '上课流程',
  学习报告: '学习报告',
  作业预览: '作业预览',
  下载: '下载',
  注销账号: '注销账号',
  华校: '华校',
  华校详情: '华校详情',
  华校详情简介: '华校详情简介',
  课程详情: '课程详情',
  家长监督: '家长监督',
  家长监督详情: '家长监督详情',
  购买课程: '购买课程',
  签订合同: '签订合同',
  首页: '首页',
  上课中心: '上课中心',
  我的课表: '我的课表',
  我的课程: '我的课程',
  设置中心: '设置中心',
  通知中心: '通知中心',
  作业中心: '作业中心',
  待完成: '待完成',
  已完成: '已完成',
  课程目录: '课程目录',
  我的资源: '我的资源',
  学生直播: '学生直播',
  总课表: '总课表',
  体系课: '体系课',
  公开课: '公开课',
  班级目录: '班级目录',
  作业系统: '作业系统',
  作业管理: '作业管理',
  作业编辑: '作业编辑',
  作业批改: '作业批改',
  模板中心: '模板中心',
  题库中心: '题库中心',
  批改作业: '批改作业',
  编辑作业: '编辑作业',
  编辑题库: '编辑题库',
  编辑模板: '编辑模板',
  资源中心: '资源中心',
  资源广场: '资源广场',
  资源搜索: '资源搜索',
  我的收藏: '我的收藏',
  书本详情: '书本详情',
  查看文件: '查看文件',
  课程回放: '课程回放',
  上课历史: '上课历史',
  账号设置: '账号设置',
  账号信息: '账号信息',
  账号绑定: '账号绑定',
  直播: '直播',
  登入成功: '登入成功',
  '验证失败, 请重新登录.': '验证失败, 请重新登录.',
  角色信息不能为空: '角色信息不能为空',
  退出: '退出',
  零: '零',
  一: '一',
  二: '二',
  三: '三',
  四: '四',
  五: '五',
  六: '六',
  七: '七',
  八: '八',
  九: '九',
  十: '十',
  '您已经登出，您可以取消留在本页，或再次登录':
    '您已经登出，您可以取消留在本页，或再次登录',
  确认注销: '确认注销',
  重新登录: '重新登录',
  后端接口连接异常: '后端接口连接异常',
  系统接口请求超时: '系统接口请求超时',
  系统接口: '系统接口',
  异常: '异常',
  'MM月DD日 HH:mm': 'MM月DD日 HH:mm',
  复制成功: '复制成功',
  复制失败: '复制失败',
  上传失败: '上传失败',
  '只支持上传 JPG/PNG 格式的图片': '只支持上传 JPG/PNG 格式的图片',
  最多上传9个文件: '最多上传9个文件',
  上传图片格式错误: '上传图片格式错误',
  上传文件格式错误: '上传文件格式错误',
  该资料下载地址无效: '该资料下载地址无效',
  下载文件失败: '下载文件失败',
  页面跳转: '页面跳转',
  德馨双语学校: '德馨双语学校',
  富兰克林教育: '富兰克林教育',
  龙之门教育: '龙之门教育',
  奥地利学校: '奥地利学校',
  '机构：': '机构：',
  元: '元',
  我要购买: '我要购买',
  更多课程: '更多课程',
  伯尔尼华夏中文学校: '伯尔尼华夏中文学校',
  罗马中华语言学校: '罗马中华语言学校',
  南非华教国际学校: '南非华教国际学校',
  毛里求斯教育机构: '毛里求斯教育机构',
  中华文化传播: '中华文化传播',
  汉字的演变: '汉字的演变',
  唐诗三百首: '唐诗三百首',
  轻松学拼音: '轻松学拼音',
  请前往汉语可比app中购买: '请前往汉语可比app中购买',
  请前往汉语可比app中查看更多课程: '请前往汉语可比app中查看更多课程',
  带: '带',
  你: '你',
  快: '快',
  乐: '乐',
  学: '学',
  中: '中',
  文: '文',
  带你快乐学中文: '带你快乐学中文',
  '请用手机扫码下载，或去应用市场搜索“Hycooby”下载即可':
    '请用手机扫码下载，或去应用市场搜索“Hycooby”下载即可',
  扫描二维码下载: '扫描二维码下载',
  iphnoe下载: 'iphnoe下载',
  Google下载: 'Google下载',
  服务协议: '服务协议',
  营业执照: '营业执照',
  增值电信业务经营许可证: '增值电信业务经营许可证',
  '汉语可比（福建）数字科技有限公司版权所有':
    '汉语可比（福建）数字科技有限公司  版权所有  ',
  'ICP许可证号：闽ICP备2023005036号-1': 'ICP许可证号  闽ICP备2023005036号-1',
  '违法和不良信息举报电话(同时受理未成年人保护举报相关的问题):0591-83771172举报邮箱: hrtt@52hrtt.com':
    '违法和不良信息举报电话(同时受理未成年人保护举报相关的问题):0591-83771172举报邮箱: hrtt@52hrtt.com',
  语言与文化密不可分: '语言与文化密不可分',
  '语言从来就是与文化紧密联系在一起的，学习一个民族的语言必须了解这个民族的文化，必须学习与这种语言有关的文化背景。':
    '语言从来就是与文化紧密联系在一起的，学习一个民族的语言必须了解这个民族的文化，必须学习与这种语言有关的文化背景。',
  少儿文化教育: '少儿文化教育',
  '少儿华文教育作为一种特殊的语言教学，同样离不开对文化的传授。华裔少儿学习者也只有从一定程度上理解了与汉语密切相关的中华文化，才能真正掌握并得体、地道地运用所获得的汉语知识。':
    '少儿华文教育作为一种特殊的语言教学，同样离不开对文化的传授。华裔少儿学习者也只有从一定程度上理解了与汉语密切相关的中华文化，才能真正掌握并得体、地道地运用所获得的汉语知识。',
  客户端下载: '客户端下载',
  立即登入: '立即登入',
  中文: '中文',
  '欢迎来到汉语可比!请选择语言开始使用~':
    '欢迎来到汉语可比!请选择语言开始使用~',
  简体中文: '简体中文',
  '*为了更好地展示页面，建议使用': '*为了更好地展示页面，建议使用',
  浏览器: '浏览器',
  身份验证: '身份验证',
  账号登录需验证手机号: '账号登录需验证手机号',
  '账号登录设备达到上线，请验证后登录': '账号登录设备达到上线，请验证后登录',
  请输入关键词: '请输入关键词',
  请输入手机号: '请输入手机号',
  请输入验证码: '请输入验证码',
  获取验证码: '获取验证码',
  提交并登录: '提交并登录',
  请输入手机号码: '请输入手机号码',
  长度在100以内: '长度在100以内',
  '长度在4-6以内': '长度在4-6以内',
  请输入账号: '请输入账号',
  请输入密码: '请输入密码',
  忘记密码: '忘记密码',
  登录: '登录',
  账号或密码错误: '账号或密码错误',
  找回密码: '找回密码',
  '验证中...': '验证中...',
  设置新密码: '设置新密码',
  请再次输入密码: '请再次输入密码',
  '密码由6-12位数字、字母、符号任意组合而成':
    '密码由6-12位数字、字母、符号任意组合而成',
  提交: '提交',
  '两次输入密码不一致!': '两次输入密码不一致!',
  请设置新密码: '请设置新密码',
  '长度在6-12以内': '长度在6-12以内',
  修改密码成功: '修改密码成功',
  '您已90天未更换密码，请及时更换': '您已90天未更换密码，请及时更换',
  密码登录: '密码登录',
  验证码登录: '验证码登录',
  '尊敬的用户，您好!': '尊敬的用户，您好!',
  '为了更好地展示页面效果，建议您使用以下浏览器(点击图标会跳转到相关浏览器的官方网站下载页面)。':
    '为了更好地展示页面效果，建议您使用以下浏览器(点击图标会跳转到相关浏览器的官方网站下载页面)。',
  关闭提示: '关闭提示',
  微信手机号绑定: '微信手机号绑定',
  绑定微信: '绑定微信',
  '确认关闭？': '确认关闭？',
  微信绑定成功: '微信绑定成功',
  学生端: '学生端',
  教师端: '教师端',
  学生登录: '学生登录',
  教师登录: '教师登录',
  账号登录: '账号登录',
  手机号登录: '手机号登录',
  第三方登录: '第三方登录',
  登录即代表您已同意: '登录即代表您已同意',
  '《用户协议》': '《用户协议》',
  和: '和',
  '《隐私政策》': '《隐私政策》',
  微信登录: '微信登录',
  全部: '全部',
  待上课: '待上课',
  课堂回顾: '课堂回顾',
  请输入课程号加入公开课: '请输入课程号加入公开课',
  分钟: '分钟',
  正在上课: '正在上课',
  进入教室: '进入教室',
  课堂表现: '课堂表现',
  看回放: '看回放',
  待调课: '待调课',
  上课时间: '上课时间',
  请提前十分钟进入教室: '请提前十分钟进入教室',
  请进入教室: '请进入教室',
  距开始: '距开始',
  无更多数据: '无更多数据',
  '正在加载中...': '正在加载中...',
  加入公开课: '加入公开课',
  您未参与该章节的学习: '您未参与该章节的学习',
  课程类型: '课程类型',
  课程状态: '课程状态',
  课次: '课次',
  '正在为您加速排班中，请耐心等候，有疑问请':
    '正在为您加速排班中，请耐心等候，有疑问请',
  '联系客服 ': '联系客服 ',
  '客服热线:': '客服热线:',
  进行中: '进行中',
  待开课: '待开课',
  已结束: '已结束',
  已过期: '已过期',
  今日: '今日',
  今: '今',
  日: '日',
  YYYY年MM月: 'YYYY年MM月',
  '暂时没有课程呢~': '暂时没有课程呢~',
  '课程号:': '课程号:',
  '*已默认为您添加至课程列表中': '*已默认为您添加至课程列表中',
  进入课堂: '进入课堂',
  课程已结束: '课程已结束',
  提醒: '提醒',
  '已完成？？？': '已完成？？？',
  去完成: '去完成',
  待批改: '待批改',
  '教师寄语：': '教师寄语：',
  创建下载成功: '创建下载成功',
  播放列表: '播放列表',
  暂无介绍: '暂无介绍',
  '该课程还未到开课时间,可自行预习该课程哦～':
    '该课程还未到开课时间,可自行预习该课程哦～',
  课程回放暂未生成: '课程回放暂未生成',
  '该课程已过期，学习视频无法查看': '该课程已过期，学习视频无法查看',
  '视频加载中...': '视频加载中...',
  章节学习: '章节学习',
  课后作业: '课后作业',
  未发布: '未发布',
  课程资料: '课程资料',
  置顶: '置顶',
  更新于: '更新于',
  暂无发布: '暂无发布',
  执教: '执教',
  年: '年',
  上课中: '上课中',
  已上课: '已上课',
  该章节还未到开课时间哦: '该章节还未到开课时间哦',
  下载教材: '下载教材',
  上次学到: '上次学到',
  观看至: '观看至',
  暂无观看: '暂无观看',
  课程通知: '课程通知',
  '更多 >': '更多 >',
  暂无公告: '暂无公告',
  已学完: '已学完',
  未观看: '未观看',
  师资介绍: '师资介绍',
  暂无教师: '暂无教师',
  已失效: '已失效',
  我的作业: '我的作业',
  未完成: '未完成',
  切换: '切换',
  岁: '岁',
  已选择: '已选择',
  消息中心: '消息中心',
  退出登录: '退出登录',
  '功能完善中，请前往app查看~': '功能完善中，请前往app查看~',
  HYCOOBY学生端: 'HYCOOBY学生端',
  刷新: '刷新',
  关闭: '关闭',
  关闭其他: '关闭其他',
  关闭所有: '关闭所有',
  常见问题: '常见问题',
  教师: '教师',
  '(我)': '(我)',
  发生错误信息: '发生错误信息',
  返回首页: '返回首页',
  禁言中: '禁言中',
  '请输入聊天内容...': '请输入聊天内容...',
  快捷语: '快捷语',
  发送: '发送',
  条新消息: '条新消息',
  聊天室连接错误: '聊天室连接错误',
  请: '请',
  点击重连: '点击重连',
  获取历史消息失败: '获取历史消息失败',
  学生: '学生',
  '处于禁言状态，无法发言': '处于禁言状态，无法发言',
  '处于全体禁言状态，无法发言': '处于全体禁言状态，无法发言',
  发送消息失败: '发送消息失败',
  网络优: '网络优',
  网络良: '网络良',
  网络一般: '网络一般',
  网络差: '网络差',
  未知: '未知',
  '教师请求开启摄像头，是否开启？': '教师请求开启摄像头，是否开启？',
  拒绝: '拒绝',
  开启: '开启',
  '教师请求开启麦克风，是否开启？': '教师请求开启麦克风，是否开启？',
  '教师邀请您连麦，是否连麦？': '教师邀请您连麦，是否连麦？',
  连麦: '连麦',
  弹幕: '弹幕',
  '未加入群组，请点击重新加入群组': '未加入群组，请点击重新加入群组',
  网络极佳: '网络极佳',
  网络较好: '网络较好',
  网络极差: '网络极差',
  断开: '断开',
  '下行丢包率: ': '下行丢包率: ',
  '上行丢包率: ': '上行丢包率: ',
  '音频码率:': '音频码率:',
  '视频码率:': '视频码率:',
  '视频帧率:': '视频帧率:',
  '房间加载中...': '房间加载中...',
  '课程类型错误:': '课程类型错误:',
  '直播类型错误:': '直播类型错误:',
  'im 登入失败 请重新连接': 'im 登入失败 请重新连接',
  '房间 Token 错误': '房间 Token 错误',
  '房间 Token 过期': '房间 Token 过期',
  房间内已经有同名用户: '房间内已经有同名用户',
  '设备不支持指定的音视频格式，无法进行连麦的操作':
    '设备不支持指定的音视频格式，无法进行连麦的操作',
  加入房间认证失败: '加入房间认证失败',
  '30s 内未能重连成功或者重连时房间已被销毁':
    '30s 内未能重连成功或者重连时房间已被销毁',
  '需要检查接口调用、设备状态、网络状态等是否正常':
    '需要检查接口调用、设备状态、网络状态等是否正常',
  请刷新页面重新进入房间: '请刷新页面重新进入房间',
  '(我)进入房间': '(我)进入房间',
  请点击确定重新播放直播: '请点击确定重新播放直播',
  '没有访问媒体设备的权限,请在设置-隐私与安全-网站设置-权限,重新打开摄像头权限':
    '没有访问媒体设备的权限,请在设置-隐私与安全-网站设置-权限,重新打开摄像头权限',
  '没有访问媒体设备的权限,请在设置-隐私与安全-网站设置-权限,重新打开麦克风权限':
    '没有访问媒体设备的权限,请在设置-隐私与安全-网站设置-权限,重新打开麦克风权限',
  '获取摄像头/麦克风权限被拒绝，请手动打开摄像头/麦克风权限后重新进入房间':
    '获取摄像头/麦克风权限被拒绝，请手动打开摄像头/麦克风权限后重新进入房间',
  '抱歉，您的浏览器不支持屏幕共享，请使用 Chrome 或者 Firefox':
    '抱歉，您的浏览器不支持屏幕共享，请使用 Chrome 或者 Firefox',
  请刷新页面手动重新发布: '请刷新页面手动重新发布',
  请手动选择系统声音: '请手动选择系统声音',
  '无法生成摄像头/麦克风/共享屏幕轨道，错误代码: {slot1}':
    '无法生成摄像头/麦克风/共享屏幕轨道，错误代码: {slot1}',
  主动退出房间: '主动退出房间',
  'qn-您被踢出房间': 'qn-您被踢出房间',
  您被踢出房间: '您被踢出房间',
  请刷新页面: '请刷新页面',
  '正在重连中...': '正在重连中...',
  重连成功: '重连成功',
  '老师{name}进入教室': '老师{name}进入教室',
  '{name}进入教室': '{name}进入教室',
  '{slot1}进入教室': '{slot1}进入教室',
  '{slot1}离开教室': '{slot1}离开教室',
  您的连麦已结束: '您的连麦已结束',
  连麦已结束: '连麦已结束',
  '{name}离开教室': '{name}离开教室',
  '{name}连麦已结束': '{name}连麦已结束',
  '{name}正在请求连麦已结束': '{name}正在请求连麦已结束',
  '请<span>刷新</span>试试': '请<span>刷新</span>试试',
  登入失败: '登入失败',
  获取房间号失败: '获取房间号失败',
  询问老师是否有人上台失败: '询问老师是否有人上台失败',
  多终端登录被踢: '多终端登录被踢',
  发起了举手: '发起了举手',
  '{stuName}结束了连麦': '{stuName}结束了连麦',
  '{stuName}请求连麦': '{stuName}请求连麦',
  教师未接听您的连麦: '教师未接听您的连麦',
  '老师拒绝了{stuName}连麦': '老师拒绝了{stuName}连麦',
  '{stuName}取消了连麦': '{stuName}取消了连麦',
  老师解除禁言: '老师解除禁言',
  老师解除了全体禁言: '老师解除了全体禁言',
  老师禁言了: '老师禁言了',
  老师开启了全体禁言: '老师开启了全体禁言',
  您已被老师请出教室: '您已被老师请出教室',
  该课程已经停用: '该课程已经停用',
  您已被移出课堂: '您已被移出课堂',
  多实例登录被踢: '多实例登录被踢',
  签名过期被踢: '签名过期被踢',
  'REST API kick 接口踢出': 'REST API kick 接口踢出',
  IM重连成功: 'IM重连成功',
  IM连接断开: 'IM连接断开',
  '无麦克风摄像头设备,无法进行连麦': '无麦克风摄像头设备,无法进行连麦',
  关闭连麦失败: '关闭连麦失败',
  拒绝老师连麦失败: '拒绝老师连麦失败',
  拒绝打开摄像头失败: '拒绝打开摄像头失败',
  拒绝打开麦克风失败: '拒绝打开麦克风失败',
  '当前有学生正在请求连麦中,无法再发起连麦':
    '当前有学生正在请求连麦中,无法再发起连麦',
  '其他同学正在连麦中,无法再发起连麦': '其他同学正在连麦中,无法再发起连麦',
  '{stuName}(我)取消了连麦': '{stuName}(我)取消了连麦',
  '{stuName}(我)取消了连麦失败': '{stuName}(我)取消了连麦失败',
  '{stuName}(我)发起了连麦': '{stuName}(我)发起了连麦',
  请求老师连麦失败: '请求老师连麦失败',
  '正在发起举手，请勿多次点击': '正在发起举手，请勿多次点击',
  '{stuName}（我）发起了举手': '{stuName}（我）发起了举手',
  '{stuName}（我）放下手失败': '{stuName}（我）放下手失败',
  '没有麦克风设备,请插入麦克风设备': '没有麦克风设备,请插入麦克风设备',
  '您还未发起连麦 故无法开启麦克风': '您还未发起连麦 故无法开启麦克风',
  '没有摄像头设备,请插入摄像头设备': '没有摄像头设备,请插入摄像头设备',
  '您还未发起连麦 故无法开启摄像头': '您还未发起连麦 故无法开启摄像头',
  '正在开启老师摄像头中,请勿多次点击,请稍等...':
    '正在开启老师摄像头中,请勿多次点击,请稍等...',
  开启老师摄像头成功: '开启老师摄像头成功',
  '没有麦克风设备,无法进行连麦': '没有麦克风设备,无法进行连麦',
  '没有摄像头设备,无法进行连麦': '没有摄像头设备,无法进行连麦',
  '{name}（教师）进入教室': '{name}（教师）进入教室',
  '{slot1}（教师）离开教室': '{slot1}（教师）离开教室',
  同意老师连麦失败: '同意老师连麦失败',
  '(我){stuName}取消了连麦': '(我){stuName}取消了连麦',
  '(我){stuName}取消了连麦失败': '(我){stuName}取消了连麦失败',
  '(我){stuName}发起了连麦': '(我){stuName}发起了连麦',
  '（我）发起举手失败': '（我）发起举手失败',
  '未和老师连麦 无法切换设备': '未和老师连麦 无法切换设备',
  '未开启麦克风,正在为您开启麦克风，请勿多次点击,请稍等...':
    '未开启麦克风,正在为您开启麦克风，请勿多次点击,请稍等...',
  '正在开启摄像头中,请勿多次点击,请稍等...':
    '正在开启摄像头中,请勿多次点击,请稍等...',
  开启摄像头成功: '开启摄像头成功',
  '教师：': '教师：',
  已下课: '已下课',
  退出教室: '退出教室',
  屏幕共享暂未开启: '屏幕共享暂未开启',
  '再等一会,教师正在快马加鞭赶到～': '再等一会,教师正在快马加鞭赶到～',
  在线: '在线',
  离线: '离线',
  '学生在线人数（': '学生在线人数（',
  '等待连麦(': '等待连麦(',
  连麦中: '连麦中',
  发起连麦: '发起连麦',
  举手: '举手',
  '举手倒计时(': '举手倒计时(',
  麦克风: '麦克风',
  摄像头: '摄像头',
  无麦克风设备: '无麦克风设备',
  音频设备: '音频设备',
  无摄像头设备: '无摄像头设备',
  视频设备: '视频设备',
  全屏: '全屏',
  '距离上课：': '距离上课：',
  '已上课：': '已上课：',
  摄像头预览: '摄像头预览',
  '摄像头-': '摄像头-',
  开: '开',
  关: '关',
  '麦克风-': '麦克风-',
  '直播初始化中...': '直播初始化中...',
  '预览失败 请刷新页面': '预览失败 请刷新页面',
  老师解除全体静音: '老师解除全体静音',
  老师邀请: '老师邀请',
  上台: '上台',
  结束上台: '结束上台',
  '老师{name}离开教室': '老师{name}离开教室',
  不同意开启摄像头失败: '不同意开启摄像头失败',
  '教师已开启全体禁言，故无法开启麦克风':
    '教师已开启全体禁言，故无法开启麦克风',
  重新申请加入房间成功: '重新申请加入房间成功',
  '播放失败,': '播放失败,',
  老师解除全体禁言: '老师解除全体禁言',
  生成本地视频失败: '生成本地视频失败',
  '（我）进入房间': '（我）进入房间',
  '无摄像头或者麦克风 无法进行上台': '无摄像头或者麦克风 无法进行上台',
  '没有麦克风设备,无法更换设备': '没有麦克风设备,无法更换设备',
  '没有摄像头设备,无法更换设备': '没有摄像头设备,无法更换设备',
  综合评价: '综合评价',
  较差: '较差',
  合格: '合格',
  良好: '良好',
  优秀: '优秀',
  完美: '完美',
  '你已经评价过了，不要重复操作': '你已经评价过了，不要重复操作',
  未开发: '未开发',
  '教室ID：': '教室ID：',
  已开课: '已开课',
  下课: '下课',
  请选择授课方式: '请选择授课方式',
  您可在上课期间随时调整授课方式: '您可在上课期间随时调整授课方式',
  麦克风加屏幕分享: '麦克风加屏幕分享',
  麦克风加摄像头: '麦克风加摄像头',
  开始授课: '开始授课',
  距开课还剩: '距开课还剩',
  ',请提前进入教室准备': ',请提前进入教室准备',
  '已经上课,请进入教室上课': '已经上课,请进入教室上课',
  还未选择直播类型: '还未选择直播类型',
  无法获取到麦克风: '无法获取到麦克风',
  无法获取到麦克风或者视频设备: '无法获取到麦克风或者视频设备',
  成员列表: '成员列表',
  学生未开启摄像头: '学生未开启摄像头',
  '屏幕共享暂未开启～': '屏幕共享暂未开启～',
  默认视图: '默认视图',
  全部视图: '全部视图',
  直播视图: '直播视图',
  '教师:': '教师:',
  演讲中: '演讲中',
  '当前浏览器不支持TRTC Web SDK，请使用最新版的 Chrome 浏览器。':
    '当前浏览器不支持TRTC Web SDK，请使用最新版的 Chrome 浏览器。',
  '强制进入房间失败,原因:': '强制进入房间失败,原因:',
  'im登入失败，请重新登入 ': 'im登入失败，请重新登入 ',
  教师已为您开启白板权限: '教师已为您开启白板权限',
  '播放视频失败，请联系老师': '播放视频失败，请联系老师',
  通知老师学生被顶号失败: '通知老师学生被顶号失败',
  您的白板权限已被关闭: '您的白板权限已被关闭',
  'qn-正在重连中...': 'qn-正在重连中...',
  直播间连接失败: '直播间连接失败',
  直播间连接中断: '直播间连接中断',
  '您的麦克风/摄像头接触不良导致错误，请您确认下摄像头/麦克风接口是否会松动':
    '您的麦克风/摄像头接触不良导致错误，请您确认下摄像头/麦克风接口是否会松动',
  直播发生错误: '直播发生错误',
  '直播发生错误,请刷新页面': '直播发生错误,请刷新页面',
  '您被踢出房间, reason:{slot1}, message:{slot2}':
    '您被踢出房间, reason:{slot1}, message:{slot2}',
  '正在开启麦克风中,请勿多次点击,请稍等...':
    '正在开启麦克风中,请勿多次点击,请稍等...',
  开启麦克风成功: '开启麦克风成功',
  开启麦克风失败: '开启麦克风失败',
  开启摄像头失败: '开启摄像头失败',
  学生询问老师所有状态失败: '学生询问老师所有状态失败',
  白板实时信令同步失败: '白板实时信令同步失败',
  聊天室: '聊天室',
  退出全屏: '退出全屏',
  '没有视频播放设备,请接入摄像头': '没有视频播放设备,请接入摄像头',
  '没有麦克风设备,请接入麦克风': '没有麦克风设备,请接入麦克风',
  '多实例登录被踢+666': '多实例登录被踢+666',
  学生询问老师是否当前有人上台失败: '学生询问老师是否当前有人上台失败',
  '教师已开启全体禁音，故无法开启麦克风':
    '教师已开启全体禁音，故无法开启麦克风',
  关闭麦克风成功: '关闭麦克风成功',
  更新学生状态失败: '更新学生状态失败',
  '您对本堂课感受如何?请为教师打个分吧~':
    '您对本堂课感受如何?请为教师打个分吧~',
  极差: '极差',
  'IM离线 本机网络掉线 请检查网络': 'IM离线 本机网络掉线 请检查网络',
  'IM离线本机网络掉线 请检查网络': 'IM离线本机网络掉线 请检查网络',
  'im 网络连接成功': 'im 网络连接成功',
  请求服务超时: '请求服务超时',
  'im-请求服务超时--': 'im-请求服务超时--',
  默认未区分类型的错误: '默认未区分类型的错误',
  'im-默认未区分类型的错误--': 'im-默认未区分类型的错误--',
  参数错误: '参数错误',
  'im-参数错误--': 'im-参数错误--',
  取消请求: '取消请求',
  'im-取消请求--': 'im-取消请求--',
  '登录失败：获取 token 接口请求失败或 Token 无效。':
    '登录失败：获取 token 接口请求失败或 Token 无效。',
  'im-登录失败：获取 token 接口请求失败或 Token 无效。--':
    'im-登录失败：获取 token 接口请求失败或 Token 无效。--',
  '鉴权失败：调用 API 时校验 App Key 失败，App Key 不合法。':
    '鉴权失败：调用 API 时校验 App Key 失败，App Key 不合法。',
  'im-鉴权失败：调用 API 时校验 App Key 失败，App Key 不合法。--':
    'im-鉴权失败：调用 API 时校验 App Key 失败，App Key 不合法。--',
  '获取 Chat token 失败：通过 Agora token 置换 Chat token 失败。':
    '获取 Chat token 失败：通过 Agora token 置换 Chat token 失败。',
  'im-获取 Chat token 失败：通过 Agora token 置换 Chat token 失败。--':
    'im-获取 Chat token 失败：通过 Agora token 置换 Chat token 失败。--',
  'WebSocket 断开连接：由于断网等原因 WebSocket 已经断开。':
    'WebSocket 断开连接：由于断网等原因 WebSocket 已经断开。',
  'im-WebSocket 断开连接：由于断网等原因 WebSocket 已经断开。--':
    'im-WebSocket 断开连接：由于断网等原因 WebSocket 已经断开。--',
  '未设置 App Key：设置的 App Key 错误。':
    '未设置 App Key：设置的 App Key 错误。',
  'im-未设置 App Key：设置的 App Key 错误。--':
    'im-未设置 App Key：设置的 App Key 错误。--',
  '未传 token：调用 API 时没有携带 token':
    '未传 token：调用 API 时没有携带 token',
  'im-未传 token：调用 API 时没有携带 token --':
    'im-未传 token：调用 API 时没有携带 token --',
  '消息发送成功的回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。':
    '消息发送成功的回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。',
  'im-消息发送成功的回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。--':
    'im-消息发送成功的回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。--',
  '当前用户未登录。': '当前用户未登录。',
  'im-当前用户未登录。--': 'im-当前用户未登录。--',
  '退出或未登录：未登录或掉线后发送消息。':
    '退出或未登录：未登录或掉线后发送消息。',
  'im-退出或未登录：未登录或掉线后发送消息。--':
    'im-退出或未登录：未登录或掉线后发送消息。--',
  用户鉴权失败: '用户鉴权失败',
  'im-用户鉴权失败 --': 'im-用户鉴权失败 --',
  '达到上限，例如 Reaction 数量已达到限制、翻译用量达到上限、应用的日活跃用户数（DAU）超限、在线用户数量超限和月活跃用户数（MAU）。':
    '达到上限，例如 Reaction 数量已达到限制、翻译用量达到上限、应用的日活跃用户数（DAU）超限、在线用户数量超限和月活跃用户数（MAU）。',
  'im-达到上限，例如 Reaction 数量已达到限制、翻译用量达到上限、应用的日活跃用户数（DAU）超限、在线用户数量超限和月活跃用户数（MAU）。--':
    'im-达到上限，例如 Reaction 数量已达到限制、翻译用量达到上限、应用的日活跃用户数（DAU）超限、在线用户数量超限和月活跃用户数（MAU）。--',
  没查到消息: '没查到消息',
  用户对当前操作没有权限: '用户对当前操作没有权限',
  不支持的操作: '不支持的操作',
  上传文件失败: '上传文件失败',
  '上传文件未携带 token': '上传文件未携带 token',
  用户不存在: '用户不存在',
  消息参数错误: '消息参数错误',
  用户在其他设备登录: '用户在其他设备登录',
  'im-用户在其他设备登录--': 'im-用户在其他设备登录--',
  用户已经被注销: '用户已经被注销',
  用户密码更新: '用户密码更新',
  用户被踢下线: '用户被踢下线',
  'im-用户被踢下线 --': 'im-用户被踢下线 --',
  用户被全局禁言: '用户被全局禁言',
  非好友禁止发消息: '非好友禁止发消息',
  服务器忙碌: '服务器忙碌',
  'im-服务器忙碌--': 'im-服务器忙碌--',
  消息含有非法内容: '消息含有非法内容',
  消息被拦截: '消息被拦截',
  消息发送失败未知错误: '消息发送失败未知错误',
  撤回消息时超出限定时间: '撤回消息时超出限定时间',
  服务未开启: '服务未开启',
  'im-服务未开启': 'im-服务未开启',
  用户未在白名单中: '用户未在白名单中',
  当前用户被禁言: '当前用户被禁言',
  '第三方内容审核服务的消息审核结果为“拒绝”':
    '第三方内容审核服务的消息审核结果为“拒绝”',
  '单个用户 ID 发送消息超出频率限制。': '单个用户 ID 发送消息超出频率限制。',
  '网络断开连接导致消息发送失败。': '网络断开连接导致消息发送失败。',
  'im-网络断开连接导致消息发送失败。--': 'im-网络断开连接导致消息发送失败。--',
  '消息体大小超过限制。': '消息体大小超过限制。',
  '已在群组内：当前用户已在该群组中。': '已在群组内：当前用户已在该群组中。',
  'im-不在群组内：用户发送群消息时未加入该群组。--':
    'im-不在群组内：用户发送群消息时未加入该群组。--',
  '不在群组内：用户发送群消息时未加入该群组。':
    '不在群组内：用户发送群消息时未加入该群组。',
  '用户无权限：例如如果用户被封禁，发送消息时会提示该错误。':
    '用户无权限：例如如果用户被封禁，发送消息时会提示该错误。',
  '消息回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。':
    '消息回调函数内部错误：在接收消息的回调及后续处理的函数中有错误。',
  '群组不存在：发送消息时群组 ID 不正确。':
    '群组不存在：发送消息时群组 ID 不正确。',
  '群组人数达到上限。': '群组人数达到上限。',
  '设置的群组最大人数超过限制：创建群组，群成员数量超出了群组设置的最大数量。':
    '设置的群组最大人数超过限制：创建群组，群成员数量超出了群组设置的最大数量。',
  '没有 token 或 App Key。': '没有 token 或 App Key。',
  'im-没有 token 或 App Key。--': 'im-没有 token 或 App Key。--',
  被操作的人员不在聊天室: '被操作的人员不在聊天室',
  '聊天室已满：聊天室已经达到人数上限。':
    '聊天室已满：聊天室已经达到人数上限。',
  聊天室不存在: '聊天室不存在',
  'Websocket 发送消息错误。': 'Websocket 发送消息错误。',
  '发布自定义在线状态时，参数长度超出限制。':
    '发布自定义在线状态时，参数长度超出限制。',
  'Reaction 重复添加': 'Reaction 重复添加',
  '创建 Reaction 时，其他人正在创建。': '创建 Reaction 时，其他人正在创建。',
  '用户对该 Reaction 没有操作权限': '用户对该 Reaction 没有操作权限',
  翻译参数错误: '翻译参数错误',
  翻译的文本过长: '翻译的文本过长',
  获取翻译服务失败: '获取翻译服务失败',
  '子区不存在：未找到该子区': '子区不存在：未找到该子区',
  '该消息 ID 下子区已存在，重复添加子区。':
    '该消息 ID 下子区已存在，重复添加子区。',
  数据异常: '数据异常',
  最近一周: '最近一周',
  最近一月: '最近一月',
  '查看详情 >>': '查看详情 >>',
  查看回复: '查看回复',
  课程: '课程',
  资源名称: '资源名称',
  请输入: '请输入',
  查询: '查询',
  重置: '重置',
  格式: '格式',
  资源类型: '资源类型',
  来源: '来源',
  添加时间: '添加时间',
  学科: '学科',
  操作: '操作',
  查看: '查看',
  设置密码: '设置密码',
  '请设置密码(由8-16位数字、字母、符号任意组合而成)':
    '请设置密码(由8-16位数字、字母、符号任意组合而成)',
  密码设置成功: '密码设置成功',
  请设置密码: '请设置密码',
  '密码长度需在8~16个字符之间': '密码长度需在8~16个字符之间',
  '密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符':
    '密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符',
  密码修改成功: '密码修改成功',
  修改密码: '修改密码',
  手机号验证: '手机号验证',
  密码验证: '密码验证',
  请输入原密码: '请输入原密码',
  '请输入新密码(由8-16位数字、字母、符号任意组合而成)':
    '请输入新密码(由8-16位数字、字母、符号任意组合而成)',
  请再次输入新密码: '请再次输入新密码',
  您的密码更改成功: '您的密码更改成功',
  退出登陆: '退出登陆',
  完成: '完成',
  下一步: '下一步',
  请输入新密码: '请输入新密码',
  长度在11以内: '长度在11以内',
  验证码已发送: '验证码已发送',
  修改手机号: '修改手机号',
  请输入新手机号: '请输入新手机号',
  修改手机号成功: '修改手机号成功',
  绑定成功: '绑定成功',
  '密码长度需在6~20个字符之间': '密码长度需在6~20个字符之间',
  '密码只能由字母、数字或下划线组成': '密码只能由字母、数字或下划线组成',
  待开发: '待开发',
  修改成功: '修改成功',
  '您当前绑定的手机号：': '您当前绑定的手机号：',
  请确认新密码: '请确认新密码',
  '* 密码由6-12位数字、字母、符号任意组合而成':
    '* 密码由6-12位数字、字母、符号任意组合而成',
  请输入区号: '请输入区号',
  请正确输入手机号: '请正确输入手机号',
  '当前手机号与绑定号码不一致，请检查': '当前手机号与绑定号码不一致，请检查',
  安全中心: '安全中心',
  头像管理: '头像管理',
  更换: '更换',
  身份管理: '身份管理',
  姓名不能为空: '姓名不能为空',
  姓名必须为字符: '姓名必须为字符',
  母亲: '母亲',
  父亲: '父亲',
  其他监护人: '其他监护人',
  昵称管理: '昵称管理',
  昵称不能为空: '昵称不能为空',
  昵称必须为字符: '昵称必须为字符',
  请输入昵称: '请输入昵称',
  姓名管理: '姓名管理',
  我的微信: '我的微信',
  保存: '保存',
  '上传头像图片只能是 JPG 格式!': '上传头像图片只能是 JPG 格式!',
  '上传头像图片大小不能超过 2MB!': '上传头像图片大小不能超过 2MB!',
  '保存成功，资料待审核': '保存成功，资料待审核',
  警告: '警告',
  我的账号: '我的账号',
  手机号码: '手机号码',
  '已绑定：': '已绑定：',
  未绑定: '未绑定',
  登录密码: '登录密码',
  已设置: '已设置',
  未设置: '未设置',
  微信: '微信',
  解绑账号: '解绑账号',
  去绑定: '去绑定',
  '此操作解除微信绑定?': '此操作解除微信绑定?',
  微信解绑成功: '微信解绑成功',
  已取消: '已取消',
  近期课程: '近期课程',
  今日课程: '今日课程',
  开始上课: '开始上课',
  请进入课堂开始上课: '请进入课堂开始上课',
  布置作业: '布置作业',
  学生考勤: '学生考勤',
  云端资源: '云端资源',
  '当前课程暂未开始，请在开课前十分钟进入':
    '当前课程暂未开始，请在开课前十分钟进入',
  当前课程暂未开始: '当前课程暂未开始',
  '您已缺课，请前往调课': '您已缺课，请前往调课',
  课表: '课表',
  班级: '班级',
  今日课堂: '今日课堂',
  我的学员: '我的学员',
  作业待批改: '作业待批改',
  作业新留言: '作业新留言',
  '温馨提示:浏览器向您获取摄像头、麦克风、音频等权限时，您需要允许或同意才能正常进入直播间。':
    '温馨提示:浏览器向您获取摄像头、麦克风、音频等权限时，您需要允许或同意才能正常进入直播间。',
  我已知晓: '我已知晓',
  上一题: '上一题',
  下一题: '下一题',
  我是标题1: '我是标题1',
  几百教师端卡哈萨克记得你好快建设爱江山大家好:
    '几百教师端卡哈萨克记得你好快建设爱江山大家好',
  我是标题2: '我是标题2',
  我是标题3: '我是标题3',
  获取题目数据失败: '获取题目数据失败',
  '确定要退出答题吗？': '确定要退出答题吗？',
  所授课程: '所授课程',
  '所授课程：': '所授课程：',
  学员: '学员',
  申请调课: '申请调课',
  '课程简介：': '课程简介：',
  '回放视频正在生成...': '回放视频正在生成...',
  '该课程已过期，学习视频无法查看...': '该课程已过期，学习视频无法查看...',
  管理作业: '管理作业',
  写反馈: '写反馈',
  待反馈: '待反馈',
  管理课程资料: '管理课程资料',
  管理云端资源: '管理云端资源',
  '教师专用  ': '教师专用  ',
  共: '共',
  份文件: '份文件',
  课程回放未生成: '课程回放未生成',
  新增排课: '新增排课',
  不可删除: '不可删除',
  上课日期: '上课日期',
  选择日期: '选择日期',
  起始时间: '起始时间',
  至: '至',
  结束时间: '结束时间',
  课堂名称: '课堂名称',
  排课管理: '排课管理',
  请选择日期: '请选择日期',
  请选择时间: '请选择时间',
  请填写活动形式: '请填写活动形式',
  的课: '的课',
  '无法删除,课程不得少于一节课': '无法删除,课程不得少于一节课',
  '是否确认删除<span style=_##_color:#ff8b02_##_>{slot1}</span>堂课?':
    '是否确认删除<span style="color:#ff8b02">{slot1}</span>堂课?',
  删除后无法恢复相关数据: '删除后无法恢复相关数据',
  第二节: '第二节',
  '1.分类思维法2': '1.分类思维法2',
  '已上课 2024.05.01 周一 20:00-21:00': '已上课 2024.05.01 周一 20:00-21:00',
  上传中: '上传中',
  暂无数据: '暂无数据',
  管理: '管理',
  '文件上传成功!': '文件上传成功!',
  '确 定': '确 定',
  '文件上传失败，请重新上传！': '文件上传失败，请重新上传！',
  '取 消': '取 消',
  重新上传: '重新上传',
  '文件上传中 0%，请不要关闭此页面': '文件上传中 0%，请不要关闭此页面',
  '文件上传中 {percent}%，请不要关闭此页面':
    '文件上传中 {percent}%，请不要关闭此页面',
  '正在转码中 0%，请不要关闭此页面': '正在转码中 0%，请不要关闭此页面',
  '正在转码中 {slot1}%，请不要关闭此页面':
    '正在转码中 {slot1}%，请不要关闭此页面',
  删除成功: '删除成功',
  '上传文件只能是 【ppt】【pptx】【doc】【docx】【pdf】 格式!':
    '上传文件只能是 【ppt】【pptx】【doc】【docx】【pdf】 格式!',
  '【doc】【docx】【pdf】格式上传文件大小不能超过 200MB!':
    '【doc】【docx】【pdf】格式上传文件大小不能超过 200MB!',
  '【ppt】【pptx】格式上传文件大小不能超过 500MB!':
    '【ppt】【pptx】格式上传文件大小不能超过 500MB!',
  班级作业批改: '班级作业批改',
  未提交: '未提交',
  一键提醒: '一键提醒',
  已批改: '已批改',
  '{slot1}提醒成功': '{slot1}提醒成功',
  一键提醒成功: '一键提醒成功',
  每节课只能使用一次: '每节课只能使用一次',
  通知: '通知',
  通讯录: '通讯录',
  教材: '教材',
  课程列表: '课程列表',
  '缺课，请前往调课': '缺课，请前往调课',
  '待完成：': '待完成：',
  ' 待完成： ': ' 待完成： ',
  '距开始：': '距开始：',
  '分 请至少提前十分钟进入课堂准备上课': '分 请至少提前十分钟进入课堂准备上课',
  '已开课，请在30分钟内进入课堂': '已开课，请在30分钟内进入课堂',
  ' 待完成：': ' 待完成：',
  分请至少提前十分钟进入课堂准备上课: '分请至少提前十分钟进入课堂准备上课',
  暂无课程安排: '暂无课程安排',
  该课程未上传教材: '该课程未上传教材',
  当前课程未开始: '当前课程未开始',
  '-发布日常作业': '-发布日常作业',
  作业标题: '作业标题',
  '示例：5.14作业': '示例：5.14作业',
  作业内容: '作业内容',
  '示例 :请写一篇作文，主题是...': '示例 :请写一篇作文，主题是...',
  满分: '满分',
  发布: '发布',
  请输入作业标题: '请输入作业标题',
  作业标题不能超过10个字符: '作业标题不能超过10个字符',
  请输入作业内容: '请输入作业内容',
  作业内容不能超过1000个字符: '作业内容不能超过1000个字符',
  请输入满分分数: '请输入满分分数',
  '请输入有效的分数，范围在0到100之间': '请输入有效的分数，范围在0到100之间',
  请等待文件上传完成: '请等待文件上传完成',
  发布成功: '发布成功',
  已上传: '已上传',
  '上传失败：': '上传失败：',
  未找到斜杠: '未找到斜杠',
  '当前限制选择{maxFilesLimit}个文件，本次选择了{selectedFilesCount}个文件，共选择了{totalFilesCount}个文件':
    '当前限制选择{maxFilesLimit}个文件，本次选择了{selectedFilesCount}个文件，共选择了{totalFilesCount}个文件',
  上传文件类型错误: '上传文件类型错误',
  '上传文件不能超过 500MB!': '上传文件不能超过 500MB!',
  未反馈: '未反馈',
  反馈: '反馈',
  已反馈: '已反馈',
  '测验/试卷': '测验/试卷',
  发布日常作业: '发布日常作业',
  '*快速发布简易作业': '*快速发布简易作业',
  '发布测验/试卷': '发布测验/试卷',
  '*多种题型选择、限时试卷设置等': '*多种题型选择、限时试卷设置等',
  '待批改(': '待批改(',
  '未提交(': '未提交(',
  '已批改(': '已批改(',
  分: '分',
  管理课后作业: '管理课后作业',
  提醒成功: '提醒成功',
  '您确认直接关闭?数据将无法保存': '您确认直接关闭?数据将无法保存',
  '确认删除作业吗？': '确认删除作业吗？',
  '学生若开始做作业时，删除后作业立即失效，请谨慎操作!':
    '学生若开始做作业时，删除后作业立即失效，请谨慎操作!',
  作业删除成功: '作业删除成功',
  详情: '详情',
  班级作业: '班级作业',
  '-管理作业': '-管理作业',
  '注意:学生若开始做作业时，删除后作业立即失效,请谨慎操作!':
    '注意:学生若开始做作业时，删除后作业立即失效,请谨慎操作!',
  '确定要删除作业吗？': '确定要删除作业吗？',
  课堂资料: '课堂资料',
  移除: '移除',
  '昵称：': '昵称：',
  '手机：': '手机：',
  '年龄：': '年龄：',
  '年级：': '年级：',
  '家长：': '家长：',
  '微信：': '微信：',
  '确认移除此学生吗？': '确认移除此学生吗？',
  任意时间点: '任意时间点',
  确认: '确认',
  请正确选择调课时间: '请正确选择调课时间',
  '请正确选择调课时间，不得早于当前': '请正确选择调课时间，不得早于当前',
  您本月调课次数为0次: '您本月调课次数为0次',
  '确定调课吗?': '确定调课吗?',
  调课成功: '调课成功',
  已取消调课: '已取消调课',
  发布公告: '发布公告',
  请输入发布内容: '请输入发布内容',
  消息推送: '消息推送',
  '当前内容量较少，请您确认': '当前内容量较少，请您确认',
  '当前内容量过少，是否发布？': '当前内容量过少，是否发布？',
  已取消发布: '已取消发布',
  '发布失败：{slot1}': '发布失败：{slot1}',
  '-发布测试/作业': '-发布测试/作业',
  该课程共有: '该课程共有',
  '个匹配的题库，请选择其中一个，选择完成后不可更改。':
    '个匹配的题库，请选择其中一个，选择完成后不可更改。',
  '题库介绍：': '题库介绍：',
  点击新建: '点击新建',
  预览题库: '预览题库',
  作业布置成功: '作业布置成功',
  请正确选择作业: '请正确选择作业',
  '人，缺课': '人，缺课',
  '人，迟到': '人，迟到',
  人: '人',
  '已反馈(': '已反馈(',
  '未反馈(': '未反馈(',
  无: '无',
  '课堂评分：': '课堂评分：',
  '说一说本堂课学生的表现吧~': '说一说本堂课学生的表现吧~',
  '期末寄语：': '期末寄语：',
  '最后一堂课了，评论下学生的表现吧~': '最后一堂课了，评论下学生的表现吧~',
  '*因学生缺课，故无课堂评分': '*因学生缺课，故无课堂评分',
  请输入教师评语: '请输入教师评语',
  请输入期末评语: '请输入期末评语',
  修改课堂名称: '修改课堂名称',
  '课程：': '课程：',
  '章节：': '章节：',
  '课次：': '课次：',
  '(共': '( 共',
  '题,': '题, ',
  '分)': '分 )',
  题目: '题目',
  答案解析: '答案解析',
  '[解析]': '[解析]',
  暂无解析: '暂无解析',

  学生答案: '学生答案',
  该学生本题未作答: '该学生本题未作答',
  作业交流: '作业交流',
  '确定删除留言吗？': '确定删除留言吗？',
  暂无交流: '暂无交流',
  发表留言: '发表留言',
  是否跳过选择题: '是否跳过选择题',
  单选题: '单选题',
  多选题: '多选题',
  朗读题: '朗读题',
  主观题: '主观题',
  留言不能为空: '留言不能为空',
  留言成功: '留言成功',
  请输入留言: '请输入留言',
  '查看图片({slot1}/{slot2})': '查看图片({slot1}/{slot2})',
  上课周期: '上课周期',
  新增时间: '新增时间',
  请选择开始上课时间: '请选择开始上课时间',
  周日: '周日',
  周一: '周一',
  周二: '周二',
  周三: '周三',
  周四: '周四',
  周五: '周五',
  周六: '周六',
  请选择上课时间: '请选择上课时间',
  '您有一份报告未填写，请尽快填写': '您有一份报告未填写，请尽快填写',
  新增通知: '新增通知',
  老师: '老师',
  于: '于',
  更新: '更新',
  编辑: '编辑',
  '请输入...': '请输入...',
  通知置顶: '通知置顶',
  编辑通知: '编辑通知',
  是否删除该公告: '是否删除该公告',
  修改公开课: '修改公开课',
  创建公开课: '创建公开课',
  是否周期课: '是否周期课',
  请选择是否周期课: '请选择是否周期课',
  科目选择: '科目选择',
  请选择科目: '请选择科目',
  课程名称: '课程名称',
  '请输入（15字以内）': '请输入（15字以内）',
  '请输入（20字以内）': '请输入（20字以内）',
  '请输入（50字以内）': '请输入（50字以内）',
  课程封面: '课程封面',
  '小班课上限人数16人;大班课上限人数500人':
    '小班课上限人数16人;大班课上限人数500人',
  '班级类型 ': '班级类型 ',
  请选择类型: '请选择类型',
  学生限制: '学生限制',
  请输入学生人数: '请输入学生人数',
  总课次: '总课次',
  开始时间: '开始时间',
  选择日期时间: '选择日期时间',
  '时长/课': '时长/课',
  请选择时长: '请选择时长',
  是: '是',
  否: '否',
  请输入课堂名称: '请输入课堂名称',
  课堂名称不能超过50个字符: '课堂名称不能超过50个字符',
  请上传课程封面: '请上传课程封面',
  请选择教材: '请选择教材',
  请填写课次: '请填写课次',
  请填写开课时间: '请填写开课时间',
  请正确填写上课时长: '请正确填写上课时长',
  请输入课堂简介: '请输入课堂简介',
  课堂名称不能超过20个字符: '课堂名称不能超过20个字符',
  请选择班级类型: '请选择班级类型',
  '请正确填写人数，且不得超过500': '请正确填写人数，且不得超过500',
  超出小班课人数限制: '超出小班课人数限制',
  超出大班课人数限制: '超出大班课人数限制',
  '上课时间冲突，请重新选择': '上课时间冲突，请重新选择',
  请设置上课时间: '请设置上课时间',
  创建成功: '创建成功',
  文件教材: '文件教材',
  课堂简介: '课堂简介',
  班级类型: '班级类型',
  请输入关键字: '请输入关键字',
  创建时间: '创建时间',
  顺序: '顺序',
  分享: '分享',
  修改: '修改',
  开课时间: '开课时间',
  正序: '正序',
  倒序: '倒序',
  去调课: '去调课',
  '分 请提前十分钟加入课堂准备': '分 请提前十分钟加入课堂准备',
  '已开课，请在30分钟内开课': '已开课，请在30分钟内开课',
  '【汉语可比】课程名称: ': '【汉语可比】课程名称: ',
  '#课程号：': '#课程号：',
  '复制该信息，打开手机汉语可比即可查看':
    '复制该信息，打开手机汉语可比即可查看',
  '使用[汉语可比]APP扫描二维码,添加课程至列表':
    '使用[汉语可比]APP扫描二维码,添加课程至列表',
  复制信息: '复制信息',
  复制课程号: '复制课程号',
  二维码生成失败: '二维码生成失败',
  只支持图片文件: '只支持图片文件',
  '确定删除该图片吗？': '确定删除该图片吗？',
  '最多上传{slot1}张图片': '最多上传{slot1}张图片',
  调课: '调课',
  '课堂介绍：': '课堂介绍：',
  已布置: '已布置',
  份: '份',
  管理课堂资料: '管理课堂资料',
  文件: '文件',
  更多: '更多',
  作业: '作业',
  时间: '时间',
  开始日期: '开始日期',
  结束日期: '结束日期',
  科目: '科目',
  年级: '年级',
  序号: '序号',
  章节: '章节',
  评价: '评价',
  五星: '五星',
  四星: '四星',
  三星: '三星',
  二星: '二星',
  一星: '一星',
  难度: '难度',
  状态: '状态',
  机构: '机构',
  查看课程: '查看课程',
  撤销: '撤销',
  恢复: '恢复',
  旋转: '旋转',
  清空: '清空',
  指针: '指针',
  手写: '手写',
  框选: '框选',
  文字: '文字',
  完成批改: '完成批改',
  双击可编辑: '双击可编辑',
  回放管理: '回放管理',
  视频管理: '视频管理',
  系统默认视频: '系统默认视频',
  手动上传视频: '手动上传视频',
  '支持MP4格式，限制1GB': '支持MP4格式，限制1GB',
  '操作提示：': '操作提示：',
  '1、视频来源二选一。默认来源为“系统默认视频”，系统自动生成视频；教师个人可选择“手动上传视频”，上传后需等待平台审核':
    '1、视频来源二选一。默认来源为“系统默认视频”，系统自动生成视频；教师个人可选择“手动上传视频”，上传后需等待平台审核',
  '2、“手动上传视频”时需等待视频上传完成后才能点击确定等待审核，上传途中关闭和退出页面将无法保存视频上传进度':
    '2、“手动上传视频”时需等待视频上传完成后才能点击确定等待审核，上传途中关闭和退出页面将无法保存视频上传进度',
  请先等待视频上传完成: '请先等待视频上传完成',
  '请上传文件大小不超过1GB的视频哦!': '请上传文件大小不超过1GB的视频哦!',
  请手动上传视频: '请手动上传视频',
  首次上课时间: '首次上课时间',
  类别: '类别',
  计划上课时间: '计划上课时间',
  展开: '展开',
  收起: '收起',
  计划时间: '计划时间',
  操作者: '操作者',
  回放审核状态: '回放审核状态',
  系统审核通过: '系统审核通过',
  人工审核通过: '人工审核通过',
  审核不通过: '审核不通过',
  待审核: '待审核',
  回放预览: '回放预览',
  自学课: '自学课',
  教学资源: '教学资源',
  课件名称: '课件名称',
  标题: '标题',
  审核状态: '审核状态',
  后台: '后台',
  已审核: '已审核',
  未通过: '未通过',
  文件类型: '文件类型',
  '云端（白板）资源': '云端（白板）资源',
  '此操作将永久删除该文件, 是否继续?': '此操作将永久删除该文件, 是否继续?',
  '删除成功!': '删除成功!',
  取消删除: '取消删除',
  '退出登入成功！': '退出登入成功！',
  HYCOOBY教师端: 'HYCOOBY教师端',
  '切换新的共享将会关闭当前共享内容，是否切换?':
    '切换新的共享将会关闭当前共享内容，是否切换?',
  不在提醒: '不在提醒',
  关闭画板: '关闭画板',
  鼠标: '鼠标',
  画笔: '画笔',
  '笔画变大 红': '笔画变大 红',
  '笔画变小 黑': '笔画变小 黑',
  上一步: '上一步',
  橡皮擦: '橡皮擦',
  橡皮擦大小: '橡皮擦大小',
  添加图片: '添加图片',
  添加文字: '添加文字',
  修改文字大小: '修改文字大小',
  修改文字颜色: '修改文字颜色',
  清除: '清除',
  销毁: '销毁',
  '下课时间到，您是否继续上课?': '下课时间到，您是否继续上课?',
  '超过下课时间半小时后，直播间将自动下课':
    '超过下课时间半小时后，直播间将自动下课',
  继续上课: '继续上课',
  结束上课: '结束上课',
  '（缺课）': '（缺课）',
  '请求连麦，请确认': '请求连麦，请确认',
  同意: '同意',
  当前有学生请求连麦: '当前有学生请求连麦',
  '忽略(': '忽略(',
  查看详情: '查看详情',
  '麦克风码率uplinkBitrate：': '麦克风码率uplinkBitrate：',
  '麦克风丢包率uplinkLostRate：': '麦克风丢包率uplinkLostRate：',
  '麦克风rtt：': '麦克风rtt：',
  '摄像头码率uplinkBitrate：': '摄像头码率uplinkBitrate：',
  '摄像头帧率uplinkFrameRate：': '摄像头帧率uplinkFrameRate：',
  '摄像头丢包率：': '摄像头丢包率：',
  '摄像头rtt：': '摄像头rtt：',
  '分享屏幕码率uplinkBitrate：': '分享屏幕码率uplinkBitrate：',
  '分享屏幕帧率uplinkFrameRate：': '分享屏幕帧率uplinkFrameRate：',
  '分享屏幕丢包率uplinkLostRate：': '分享屏幕丢包率uplinkLostRate：',
  '分享屏幕rtt：': '分享屏幕rtt：',
  '当前播放器播放质量差，是否更换播放器':
    '当前播放器播放质量差，是否更换播放器',
  '将会退出教室(包括学生），需重新进入教室':
    '将会退出教室(包括学生），需重新进入教室',
  切换播放器: '切换播放器',
  设置: '设置',
  提示音: '提示音',
  正在讲话: '正在讲话',
  只看老师和自己: '只看老师和自己',
  '正在讲话：': '正在讲话：',
  '年龄:': '年龄:',
  学生资料: '学生资料',
  '家长姓名：': '家长姓名：',
  '学生姓名:': '学生姓名:',
  请输入内容: '请输入内容',
  解除全体禁言: '解除全体禁言',
  全体禁言: '全体禁言',
  '在线(': '在线(',
  '举手(': '举手(',
  是否连麦: '是否连麦',
  是否禁言: '是否禁言',
  踢出管理: '踢出管理',
  挂断: '挂断',
  接听: '接听',
  邀请连麦: '邀请连麦',
  解除移出: '解除移出',
  移除课堂: '移除课堂',
  '上传中0%': '上传中0%',
  '撤销：ctrl+z': '撤销：ctrl+z',
  '恢复：ctrl+y': '恢复：ctrl+y',
  '清空：ctrl+c': '清空：ctrl+c',
  缩小: '缩小',
  放大: '放大',
  '上一页：←': '上一页：←',
  '下一页：→': '下一页：→',
  添加页面: '添加页面',
  删除页面: '删除页面',
  跳转页面: '跳转页面',
  跳转到指定页面: '跳转到指定页面',
  第: '第',
  页: '页',
  '首页无法删除只能清空，是否清空？': '首页无法删除只能清空，是否清空？',
  '是否删除当前页？': '是否删除当前页？',
  实线: '实线',
  虚线: '虚线',
  细: '细',
  粗: '粗',
  '画笔涂鸦元素(包括画笔，荧光笔)': '画笔涂鸦元素(包括画笔，荧光笔)',
  '图形涂鸦元素(包括直线，圆，椭圆，矩形，圆锥，三角形等几何图形)':
    '图形涂鸦元素(包括直线，圆，椭圆，矩形，圆锥，三角形等几何图形)',
  文本元素: '文本元素',
  学科公式: '学科公式',
  自定义图形元素: '自定义图形元素',
  图片元素: '图片元素',
  h5元素: 'h5元素',
  魔法笔: '魔法笔',
  '白板初始化中...': '白板初始化中...',
  '初始化失败，请重试': '初始化失败，请重试',
  '服务鉴权失败，请先购买服务': '服务鉴权失败，请先购买服务',
  '白板加载失败，请重试': '白板加载失败，请重试',
  '同步历史数据失败，请重试': '同步历史数据失败，请重试',
  '白板运行错误，请检查sdkAppId，userId, userSig是否正确':
    '白板运行错误，请检查sdkAppId，userId, userSig是否正确',
  '服务鉴权超时，请重试': '服务鉴权超时，请重试',
  单课堂内白板页数已经到达上限: '单课堂内白板页数已经到达上限',
  'userSig过期了，请重新生成新的userSig，再重新初始化白板':
    'userSig过期了，请重新生成新的userSig，再重新初始化白板',
  '实时数据格式错误，请检查白板信令是否有进行二次包装':
    '实时数据格式错误，请检查白板信令是否有进行二次包装',
  重复添加文件: '重复添加文件',
  '非法操作，请在历史数据完成回调后再调用sdk相关接口':
    '非法操作，请在历史数据完成回调后再调用sdk相关接口',
  涂鸦丢失: '涂鸦丢失',
  自定义图形url为空: '自定义图形url为空',
  图片组超大: '图片组超大',
  '多媒体资源码率大于2048kb/s，网络不好情况下容易造成卡顿，建议对视频码率进行压缩':
    '多媒体资源码率大于2048kb/s，网络不好情况下容易造成卡顿，建议对视频码率进行压缩',
  '已经存在图片水印，不能重复添加': '已经存在图片水印，不能重复添加',
  数学公式库没有重新加载: '数学公式库没有重新加载',
  非法的数学公式: '非法的数学公式',
  '已经存在文本水印，不能重复添加': '已经存在文本水印，不能重复添加',
  元素类型不存在: '元素类型不存在',
  元素ID不存在: '元素ID不存在',
  '视频播放/加载失败': '视频播放/加载失败',
  '转码失败code:{slot1} message:{slot2}':
    '转码失败code:{slot1} message:{slot2}',
  转码失败: '转码失败',
  '上传中，当前进度:{slot1}%': '上传中，当前进度:{slot1}%',
  创建转码任务: '创建转码任务',
  正在排队等待转码: '正在排队等待转码',
  '转码中，当前进度:{slot1}%': '转码中，当前进度:{slot1}%',
  转码完成: '转码完成',
  无操作权限: '无操作权限',
  导入数据成功: '导入数据成功',
  导入数据失败: '导入数据失败',
  ppt加载中: 'ppt加载中',
  ppt加载完成: 'ppt加载完成',
  ppt取消加载: 'ppt取消加载',
  ppt加载超时: 'ppt加载超时',
  ppt资源加载失败: 'ppt资源加载失败',
  ppt内部运行错误: 'ppt内部运行错误',
  调用addTranscodeFile接口添加文件的时候超时:
    '调用addTranscodeFile接口添加文件的时候超时',
  ppt内部的图片资源加载异常: 'ppt内部的图片资源加载异常',
  '缩放移动(shift+滚轮可放大缩小)': '缩放移动(shift+滚轮可放大缩小)',
  移动: '移动',
  播放音视频: '播放音视频',
  可批量选中元素进行编辑: '可批量选中元素进行编辑',
  选框: '选框',
  任意位置涂鸦: '任意位置涂鸦',
  选择类型: '选择类型',
  荧光笔: '荧光笔',
  调整大小: '调整大小',
  调整形状: '调整形状',
  调整颜色: '调整颜色',
  任意位置输入文字: '任意位置输入文字',
  字号大小: '字号大小',
  字体颜色: '字体颜色',
  擦除画笔或文字内容: '擦除画笔或文字内容',
  橡皮: '橡皮',
  突出重点内容: '突出重点内容',
  激光笔: '激光笔',
  '可上传图片、音频、视频': '可上传图片、音频、视频',
  图片资源: '图片资源',
  '音频（MP3）': '音频（MP3）',
  '视频（MP4）': '视频（MP4）',
  多媒体: '多媒体',
  '支持word、ppt、pdf': '支持word、ppt、pdf',
  文件列表: '文件列表',
  '*您可从“云端资源”添加至此列表进行使用':
    '*您可从“云端资源”添加至此列表进行使用',
  空白白板: '空白白板',
  '*点击添加，可以添加至“文件列表”并打开使用':
    '*点击添加，可以添加至“文件列表”并打开使用',
  本节课堂资源: '本节课堂资源',
  添加: '添加',
  已添加: '已添加',
  暂无云端资源: '暂无云端资源',
  上传本地文件: '上传本地文件',
  '*此列表仅展示已收藏的书本资源': '*此列表仅展示已收藏的书本资源',
  前往: '前往',
  '若需更多资源，前往资源广场>>': '若需更多资源，前往资源广场 >>',
  课件: '课件',
  请选择转码方式: '请选择转码方式',
  '保留动画效果，需要时间较久': '保留动画效果，需要时间较久',
  '转为静态图片，速度快': '转为静态图片，速度快',
  '您可以在这里快速上传/使用课件哦~': '您可以在这里快速上传/使用课件哦~',
  我知道了: '我知道了',
  取消成功: '取消成功',
  '文件已上传，即将为您打开': '文件已上传，即将为您打开',
  '文件上传失败，请重试': '文件上传失败，请重试',
  '测试文件-{slot1}': '测试文件-{slot1}',
  转码中: '转码中',
  '上传图片只能是 【jpg】【png】【jpeg】 格式!':
    '上传图片只能是 【jpg】【png】【jpeg】 格式!',
  '上传图片大小不能超过 1G!': '上传图片大小不能超过 1G!',
  '上传文件只能是 【mp3】格式!': '上传文件只能是 【mp3】格式!',
  '上传mp3大小不能超过 1G!': '上传mp3大小不能超过 1G!',
  '上传文件只能是 【mp4】格式!': '上传文件只能是 【mp4】格式!',
  '上传mp4大小不能超过 1G!': '上传mp4大小不能超过 1G!',
  '上传文件大小不能超过 200M!': '上传文件大小不能超过 200M!',
  当前有文件正在上传中: '当前有文件正在上传中',
  '转码中0%': '转码中0%',
  '请老师准备好，即将开始上课！': '请老师准备好，即将开始上课！',
  已结束学生: '已结束学生',
  结束学生连麦失败: '结束学生连麦失败',
  同意开启麦克风: '同意开启麦克风',
  同意开启摄像头: '同意开启摄像头',
  同意连麦: '同意连麦',
  不同意连麦: '不同意连麦',
  拒绝开启麦克风: '拒绝开启麦克风',
  拒绝开启摄像头: '拒绝开启摄像头',
  取消请求连麦: '取消请求连麦',
  已结束连麦: '已结束连麦',
  '学生下台操作失败，请重试': '学生下台操作失败，请重试',
  '同意学生{name}连麦': '同意学生{name}连麦',
  同意学生连麦失败: '同意学生连麦失败',
  '拒绝学生{userName}连麦成功': '拒绝学生{userName}连麦成功',
  拒绝学生连麦失败: '拒绝学生连麦失败',
  禁言所有学生发言成功: '禁言所有学生发言成功',
  禁言所有学生发言失败: '禁言所有学生发言失败',
  取消全体禁言成功: '取消全体禁言成功',
  取消全体禁言失败: '取消全体禁言失败',
  关闭学生: '关闭学生',
  发言成功: '发言成功',
  开启学生: '开启学生',
  切换学生摄像头失败: '切换学生摄像头失败',
  '您确定要下课吗, 是否继续?': '您确定要下课吗, 是否继续?',
  您已取消下课: '您已取消下课',
  关闭老师麦克风成功: '关闭老师麦克风成功',
  '正在开启老师麦克风中,请勿多次点击,请稍等...':
    '正在开启老师麦克风中,请勿多次点击,请稍等...',
  开启老师麦克风成功: '开启老师麦克风成功',
  关闭老师摄像头成功: '关闭老师摄像头成功',
  '开启摄像头后，共享屏幕将自动关闭': '开启摄像头后，共享屏幕将自动关闭',
  '开启共享屏幕后，摄像头将自动关闭': '开启共享屏幕后，摄像头将自动关闭',
  '正在开启老师共享视频中,请勿多次点击,请稍等...':
    '正在开启老师共享视频中,请勿多次点击,请稍等...',
  '学生未上线,不能上台': '学生未上线,不能上台',
  其他学生已经处于连麦状态: '其他学生已经处于连麦状态',
  '已有学生在连麦,不能再邀请学生连麦': '已有学生在连麦,不能再邀请学生连麦',
  拒绝学生连麦成功: '拒绝学生连麦成功',
  已取消邀请学生: '已取消邀请学生',
  取消邀请学生连麦失败: '取消邀请学生连麦失败',
  已邀请学生: '已邀请学生',
  邀请学生连麦失败: '邀请学生连麦失败',
  麦克风成功: '麦克风成功',
  已通知学生: '已通知学生',
  开启麦克风: '开启麦克风',
  切换学生麦克风失败: '切换学生麦克风失败',
  摄像头成功: '摄像头成功',
  开启摄像头: '开启摄像头',
  邀请该学生请入教室成功: '邀请该学生请入教室成功',
  '踢出课堂后，{slot1}无法再进入本课堂是否确认?':
    '踢出课堂后，{slot1}无法再进入本课堂是否确认?',
  '该学生{slot1}请出教室成功': '该学生{slot1}请出教室成功',
  '通知学生{slot1}请出教室成功': '通知学生{slot1}请出教室成功',
  '通知学生{slot1}请出教室失败': '通知学生{slot1}请出教室失败',
  '切换共享屏幕失败，可能将导致直播录制失败，请重新进入直播间重新推流，如若无法解决请联系人工客服':
    '切换共享屏幕失败，可能将导致直播录制失败，请重新进入直播间重新推流，如若无法解决请联系人工客服',
  重新进入直播间: '重新进入直播间',
  '超过下课时间半小时,自动下课': '超过下课时间半小时,自动下课',
  '下课通知失败,请刷新页面': '下课通知失败,请刷新页面',
  本次回放已保存: '本次回放已保存',
  '应到：': '应到：',
  '实到：': '实到：',
  '未到：': '未到：',
  共享屏幕未开启: '共享屏幕未开启',
  正在演讲: '正在演讲',
  '学生:': '学生:',
  '{stuName}发起了举手': '{stuName}发起了举手',
  回答学生上台失败: '回答学生上台失败',
  已结束上台演讲: '已结束上台演讲',
  禁言所有学生麦克风成功: '禁言所有学生麦克风成功',
  禁言所有学生麦克风失败: '禁言所有学生麦克风失败',
  开启老师共享屏幕成功: '开启老师共享屏幕成功',
  '已有学生上台，请先下台后再邀请学生上台':
    '已有学生上台，请先下台后再邀请学生上台',
  上台演讲: '上台演讲',
  邀请学生上台失败: '邀请学生上台失败',
  让学生重新进入房间失败: '让学生重新进入房间失败',
  上: '上',
  下: '下',
  '老师{slot1}台失败': '老师{slot1}台失败',
  直播已结束: '直播已结束',
  本次回放已保: '本次回放已保',
  写课堂反馈: '写课堂反馈',
  反馈完成: '反馈完成',
  麦克风加屏幕分享加摄像头: '麦克风加屏幕分享加摄像头',
  是否录制视频: '是否录制视频',
  共享屏幕: '共享屏幕',
  学生管理: '学生管理',
  切换至主视窗: '切换至主视窗',
  邀请演讲: '邀请演讲',
  结束演讲: '结束演讲',
  联系: '联系',
  关闭麦克风: '关闭麦克风',
  关闭摄像头: '关闭摄像头',
  打开摄像头: '打开摄像头',
  全体静音: '全体静音',
  解除全体静音: '解除全体静音',
  移出课堂: '移出课堂',
  通知下课失败: '通知下课失败',
  关闭老师麦克风失败: '关闭老师麦克风失败',
  关闭老师摄像头失败: '关闭老师摄像头失败',
  '接听或发起新的连麦，当前连麦将会挂断，是否确定?':
    '接听或发起新的连麦，当前连麦将会挂断，是否确定?',
  推送老师麦克风失败: '推送老师麦克风失败',
  '上课失败,请刷新页面!!!': '上课失败,请刷新页面!!!',
  白板互动成功: '白板互动成功',
  切换学生白板互动失败: '切换学生白板互动失败',
  你正在共享屏幕: '你正在共享屏幕',
  结束共享: '结束共享',
  切换主视窗: '切换主视窗',
  请求连麦: '请求连麦',
  正在呼叫: '正在呼叫',
  推送老师摄像头失败: '推送老师摄像头失败',
  开启老师麦克风失败: '开启老师麦克风失败',
  最多允许一名学生白板互动: '最多允许一名学生白板互动',
  '教师辛苦了，您可以对学生进行课堂反馈哦~':
    '教师辛苦了，您可以对学生进行课堂反馈哦~',
  当前设备未检测到摄像头: '当前设备未检测到摄像头',
  '请检测摄像头是否损坏或接口松动，若无问题，请检查是否开启了浏览器授权':
    '请检测摄像头是否损坏或接口松动，若无问题，请检查是否开启了浏览器授权',
  设置方法请查看教程: '设置方法请查看教程',
  摄像头已关闭: '摄像头已关闭',
  请进入教室上课: '请进入教室上课',
  结束白板: '结束白板',
  共享白板: '共享白板',
  切换共享窗口: '切换共享窗口',
  新的共享: '新的共享',
  '聊天室（': '聊天室（',
  重新连接: '重新连接',
  白板互动: '白板互动',
  客服消息: '客服消息',
  '您反应的问题已由专人进行回复，': '您反应的问题已由专人进行回复，',
  '查看详情>>': '查看详情>>',
  '作业已失效，无法查看评论': '作业已失效，无法查看评论',
  '标题：': '标题：',
  '图片：': '图片：',
  '图文内容：': '图文内容：',
  建议仅在题目较为复杂的时候使用: '建议仅在题目较为复杂的时候使用',
  '答题解析：': '答题解析：',
  '难度：': '难度：',
  '题目类型：': '题目类型：',
  '3保存题目后，题目类型不可修改': '3保存题目后，题目类型不可修改',
  '文本答题：': '文本答题：',
  '拍照答题：': '拍照答题：',
  标题为必填项: '标题为必填项',
  题目类型为必填项: '题目类型为必填项',
  请至少选择一种答题方式: '请至少选择一种答题方式',
  文本答题: '文本答题',
  拍照答题: '拍照答题',
  '你还未设置确定答案。': '你还未设置确定答案。',
  '选项标题均为必填内容。': '选项标题均为必填内容。',
  '选择题至少需要一个选项。': '选择题至少需要一个选项。',
  '请至少选择一种答题方式。': '请至少选择一种答题方式。',
  关联课程: '关联课程',
  关联班级: '关联班级',
  关联章节: '关联章节',
  简单: '简单',
  一般: '一般',
  困难: '困难',
  语文: '语文',
  数学: '数学',
  英语: '英语',
  一班: '一班',
  二班: '二班',
  三班: '三班',
  第一章: '第一章',
  第二章: '第二章',
  第三章: '第三章',
  科目为必填项: '科目为必填项',
  开始时间为必填项: '开始时间为必填项',
  时长为必填项: '时长为必填项',
  请选择年级: '请选择年级',
  '题目标题均为必填内容。': '题目标题均为必填内容。',
  添加成功: '添加成功',
  '附件：': '附件：',
  图片上传: '图片上传',
  附件上传: '附件上传',
  '{slot1} 文件超过100MB，禁止上传': '{slot1} 文件超过100MB，禁止上传',
  上传已取消: '上传已取消',
  最多上传九份附件: '最多上传九份附件',
  最多上传9张图片: '最多上传9张图片',
  类型: '类型',
  课堂状态: '课堂状态',
  题型: '题型',
  审核未通过: '审核未通过',
  教师评分: '教师评分',
  请打分: '请打分',
  提交评分: '提交评分',
  该小题满分: '该小题满分',
  '作业标题: ': '作业标题: ',
  '得分/满分': '得分/满分',
  提交时间: '提交时间',
  试卷: '试卷',
  作业类型: '作业类型',
  日常作业1: '日常作业1',
  修改评分: '修改评分',
  的: '的',
  '本题得分： ': '本题得分： ',
  系统已自动评分: '系统已自动评分',
  评分: '评分',
  '请输入留言...': '请输入留言...',
  '当前总分：': '当前总分：',
  未批改: '未批改',
  未作答: '未作答',
  提交批改: '提交批改',
  教师评语: '教师评语',
  请输入评语: '请输入评语',
  '*每一次进步都值得被肯定': '*每一次进步都值得被肯定',
  '批改图片({slot1}/{slot2})': '批改图片({slot1}/{slot2})',
  有题未批改: '有题未批改',
  当前题目未批改: '当前题目未批改',
  提交批改成功: '提交批改成功',
  提交批改失败: '提交批改失败',
  发布作业: '发布作业',
  复制: '复制',
  '确定删除作业？': '确定删除作业？',
  '{slot1}删除成功': '{slot1}删除成功',
  '描述：': '描述：',
  '作业内容：': '作业内容：',
  '班级：': '班级：',
  '作业类型：': '作业类型：',
  '限制时长：': '限制时长：',
  '作业标题：': '作业标题：',
  '满分：': '满分：',
  '开始时间：': '开始时间：',
  '时长(分)：': '时长(分)：',
  '示例：请写一篇作文，主题是...': '示例：请写一篇作文，主题是...',
  修改作业: '修改作业',
  '是否确认发布？': '是否确认发布？',
  请选择课程: '请选择课程',
  请选择班级: '请选择班级',
  请选择章节: '请选择章节',
  请选择试卷类型: '请选择试卷类型',
  作业内容为必填项: '作业内容为必填项',
  '请输入有效的分数，范围在0到1000之间': '请输入有效的分数，范围在0到1000之间',
  管理题库: '管理题库',
  新增: '新增',
  批量添加: '批量添加',
  题目类型: '题目类型',
  '标题： ': '标题： ',
  学年: '学年',
  '批量添加题库（待开发）': '批量添加题库（待开发）',
  '添加题目：': '添加题目：',
  '题型：': '题型：',
  '总题数：': '总题数：',
  '总分：': '总分：',
  添加小题: '添加小题',
  排序: '排序',
  答题解析: '答题解析',
  分数: '分数',
  '大题删除后，相应的小题也会同时删除！':
    '大题删除后，相应的小题也会同时删除！',
  试题标题: '试题标题',
  '年级/科目': '年级/科目',
  教材章节: '教材章节',
  带回1: '带回1',
  学段: '学段',
  '前端提醒，此处的多选为bug，点击一次后切换有问题，流程测试时请注意':
    '前端提醒，此处的多选为bug，点击一次后切换有问题，流程测试时请注意',
  带回0: '带回0',
  '关联课程：': '关联课程：',
  '课程章节：': '课程章节：',
  '科目：': '科目：',
  '教材：': '教材：',
  '1保存题目后，题目类型不可修改': '1保存题目后，题目类型不可修改',
  必填项不能为空: '必填项不能为空',
  '添加小题-': '添加小题-',
  '答案解析：': '答案解析：',
  请输入答案解析: '请输入答案解析',
  '更多 ': '更多 ',
  '3.1保存题目后，题目类型不可修改': '3.1保存题目后，题目类型不可修改',
  正确选项: '正确选项',
  '+ 添加选项': '+ 添加选项',
  '正确选项：': '正确选项：',
  '选项标题：': '选项标题：',
  建议仅在较为复杂的情况使用: '建议仅在较为复杂的情况使用',
  选项: '选项',
  选项标题不能为空: '选项标题不能为空',
  '最多支持6个选项。': '最多支持6个选项。',
  新增选项: '新增选项',
  '保存题目后，题目类型不可修改': '保存题目后，题目类型不可修改',
  建议仅在题目较为复杂的时候使用2: '建议仅在题目较为复杂的时候使用2',
  '拍照答题7：': '拍照答题7：',
  模板名称: '模板名称',
  作业名称: '作业名称',
  '作业名称： ': '作业名称： ',
  课堂作业: '课堂作业',
  总分: '总分',
  题数: '题数',
  '学生若开始做作业时，删除后作业立即失效，请谨慎操作！':
    '学生若开始做作业时，删除后作业立即失效，请谨慎操作！',
  修改模板: '修改模板',
  发布模板: '发布模板',
  '教材章节：': '教材章节：',
  是否确认发布: '是否确认发布',
  同时保存为模板: '同时保存为模板',
  添加题目: '添加题目',
  题目模板: '题目模板',
  带回: '带回',
  导入题目: '导入题目',
  Excel导入: 'Excel导入',
  图片: '图片',
  ' 编辑 ': ' 编辑 ',
  上次使用: '上次使用',
  该书本无目录: '该书本无目录',
  暂无资源: '暂无资源',
  其他教育: '其他教育',
  搜索资源: '搜索资源',
  学科教育: '学科教育',
  查看书本详情: '查看书本详情',
  书本: '书本',
  '文件大小：': '文件大小：',
  '文件格式：': '文件格式：',
  '提供者：': '提供者：',
  播放: '播放',
  取消收藏: '取消收藏',
  收藏: '收藏',
  添加到课堂: '添加到课堂',
  取消收藏成功: '取消收藏成功',
  收藏成功: '收藏成功',
  '当前暂无执教班级，无法添加': '当前暂无执教班级，无法添加',
  选择班级: '选择班级',
  选择章节: '选择章节',
  选择位置: '选择位置',
  '*只能获取到自己正在执教中到班级': '*只能获取到自己正在执教中到班级',
  上次添加: '上次添加',
  请选择位置: '请选择位置',
  '* 密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型':
    '* 密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型',
  '密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型':
    '密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型',
  '* 密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型。':
    '* 密码可使用8-16位数字、字母或常用符号，且至少包含三种或以上字符类型。',
  修改绑定: '修改绑定',
  请输入原手机号: '请输入原手机号',
  换绑成功: '换绑成功',
  '账号：': '账号：',
  账号隐私: '账号隐私',
  我的姓名: '我的姓名',
  请输入姓名: '请输入姓名',
  个人简介: '个人简介',
  简介不能为空: '简介不能为空',
  手机: '手机',
  立即设置: '立即设置',
  解除绑定: '解除绑定',
  账号密码: '账号密码',
  "历史课堂资源": "历史课堂资源",
  '正在进入课堂，请稍后...': '正在进入课堂，请稍后...',
  '网络恢复，课堂连接正常': '网络恢复，课堂连接正常',
  '网络异常，课堂连接中断，正在尝试重新连接': '网络异常，课堂连接中断，正在尝试重新连接',
  '网络异常，课堂自动重连失败，请检查网络连接或重新进入教室': '网络异常，课堂自动重连失败，请检查网络连接或重新进入教室',
  '网络恢复，im连接正常': '网络恢复，im连接正常',
  '网络异常，im连接中断，正在尝试重新连接': '网络异常，im连接中断，正在尝试重新连接',
  '网络异常，白板加载失败，请重新进入课堂': '网络异常，白板加载失败，请重新进入课堂',
  '忽略': '忽略',
  '重新进入教室': '重新进入教室',
  限制人数: '限制人数',
  '当您与老师失去通信时（听不见声音或看不见画面），您可以尝试点击此按钮': '当您与老师失去通信时（听不见声音或看不见画面），您可以尝试点击此按钮',
  '恢复通信': '恢复通信',
  课堂: '课堂',
  请填写课堂名称: '请填写课堂名称',
  '长度在 3 到 50 个字符': '长度在 3 到 50 个字符',
  '开课时间需大于当前时间': '开课时间需大于当前时间',
  '检测到该时段内，您已有排课，是否继续确认排课?': '检测到该时段内，您已有排课，是否继续确认排课?',
  '结束时间不能小于开始时间': '结束时间不能小于开始时间',
  '闽公网安备35010302000734号': '闽公网安备35010302000734号',
  '视频不支持插入图片': '视频不支持插入图片',
}
