import i18n from '@/locale'
import Layout from '@/views/student/layout/index.vue'
/**
 * hidden: true                   为true 就不会在右边的侧边栏显示
 * alwaysShow: true               为true 就算只有一个子路由 也会渲染成下拉形式
 * redirect: noRedirect           重定向路由
 * name:'router-name'             路由名字 必须设置！！！ <keep-alive> 缓存页面需要
 * meta : {
    roles: ['admin','editor']    角色
    title: 'title'               左侧栏名字和面包屑导航需要
    icon: 'svg-name'/'el-icon-x' 左侧栏图标
    noCache: true                如果为true 页面就会被<keep-alive>缓存起来
    affix: true                  如果为true 这个tag就会被固定住 不会被删除
    breadcrumb: false            如果设置为false，该项将隐藏在breadcrumb中（默认值为true）
    activeMenu: '/example/list'  如果设置了路径，侧边栏将突出显示您设置的路径 动态路径 固定左侧导航激活项
  }
 */
export const asyncStudent = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/student/redirect/index.vue'),
      },
    ],
  },
  {
    path: '/student',
    redirect: '/class-center',
    hidden: true,
  },
  {
    path: '/class-center',
    component: Layout,
    redirect: '/class-center/index/classcourse',
    meta: {
      title: i18n.t('首页'),
      img: 'class-center',
    },
    // alwaysShow: true,
    children: [
      {
        path: 'index',
        name: 'class-center',
        component: () => import('@/views/student/class-center/index.vue'),
        meta: {
          title: i18n.t('上课中心'),
        },

        children: [
          {
            path: '/class-center/index/classcourse',
            name: 'classcourse',
            component: () =>
              import('@/views/student/class-center/base/classCourse.vue'),
            meta: {
              title: i18n.t('上课中心'),
            },
          },
          {
            path: '/class-center/index/curriculum',
            name: 'curriculum',
            component: () =>
              import(
                '@/views/student/class-center/base/my-curriculum/index.vue'
              ),
            meta: {
              title: i18n.t('我的课表'),
            },
          },
          {
            path: '/class-center/index/course',
            name: 'course',
            component: () =>
              import('@/views/student/class-center/base/my-course/index.vue'),
            meta: {
              title: i18n.t('我的课程'),
              img: 'class-center',
            },
          },
        ],
      },
      {
        component: Layout,
        path: '/set-center',
        // redirect: '/set-center/information',
        hidden: true,
        component: () => import('@/views/student/set-center/index.vue'),
        meta: {
          title: i18n.t('设置中心'),
        },
      },
      {
        component: Layout,
        path: '/notice-center',
        // redirect: '/notice-center/information',
        hidden: true,
        component: () => import('@/views/student/notice-center/index.vue'),
        meta: {
          title: i18n.t('通知中心'),
        },
      },
      {
        component: Layout,
        path: '/homework-center',
        redirect: '/homework-center/wait',
        hidden: true,
        component: () => import('@/views/student/homework-center/index.vue'),
        meta: {
          title: i18n.t('作业中心'),
        },
        children: [
          {
            path: '/homework-center/wait',
            name: 'wait',
            component: () =>
              import('@/views/student/homework-center/base/wait.vue'),
            meta: {
              title: i18n.t('待完成'),
            },
          },
          {
            path: '/homework-center/finish',
            name: 'finish',
            component: () =>
              import('@/views/student/homework-center/base/finish.vue'),
            meta: {
              title: i18n.t('已完成'),
            },
          },
          {
            path: '/homework-center/lapse',
            name: 'lapse',
            component: () =>
              import('@/views/student/homework-center/base/lapse.vue'),
            meta: {
              title: i18n.t('待完成'),
              img: 'class-center',
            },
          },
        ],
      },
      {
        component: Layout,
        path: '/class-directory',
        hidden: true,
        component: () => import('@/views/student/class-directory/index.vue'),
        meta: {
          title: i18n.t('课程目录'),
        },
      },
      {
        component: Layout,
        path: '/class-detail',
        hidden: true,
        component: () => import('@/views/student/class-detail/index.vue'),
        meta: {
          title: i18n.t('课程详情'),
        },
      },
      {
        path: '/my-resource',
        name: 'my-resource',
        component: () =>
          import('@/views/student/resource-center/ResourceCenter.vue'),

        meta: {
          title: i18n.t('我的资源'),
          img: 'my-resource',
          activeImg: 'my-resource1',
        },
      },
    ],
  },
  {
    path: '/live',
    name: 'live',
    component: () => import('@/views/student/live/index.vue'),
    hidden: true,
    meta: {
      title: i18n.t('学生直播'),
    },
  },
]
