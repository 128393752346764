// dialog.js
import Vue from 'vue';
import DialogComponent from './dialog-pc.vue';
export default function showDialog({ title, content,tip,contentHtml }) {
    return new Promise((resolve, reject) => {
  
        const dialogInstance = new Vue({
            render(h) {
                return h(DialogComponent, {
                    props: {
                        title:title,
                        content:content,
                        tip:tip,
                        contentHtml:contentHtml,
                    },
                    on: {
                        confirm: () => {
                            dialogInstance.$destroy(); document.body.removeChild(dialogInstance.$el);
                            resolve(true);
                        },
                        cancel: () => {
                            dialogInstance.$destroy();
                            document.body.removeChild(dialogInstance.$el);
                            reject(false);
                        }
                    }
                });
            }
        }).$mount(); document.body.appendChild(dialogInstance.$el);
    });
}


