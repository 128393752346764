const state = {
  timezone: '',
  teacher: '',
  clicktime: '',
  classtext: '',
  classinfor: '',
  dataclass: '',
  homework: '',
  question: '',
  chapter: '',
  privacy: '',
  query: '',
  paperid: '',
  questionId: '',
  questiondata: 0,
  reminder: false,
  publictea: 0,
  publicData: '',
  classReminderData: '',
  //下面是学生端的信息
  student: '',
  stubug: 0,
  stu_course: {}, //点击的课程信息
  revisepublic: {},//要修改的公开课信息
  createOrUpdate: '',//创建或修改

};

const mutations = {

  // 时区
  SET_TIMEZONE: (state) => {
    state.timezone = new Date().getTimezoneOffset() / 60 * -1
    // localStorage.setItem('timezone', state.timezone)
  },
  //获取的教师user信息
  SET_TEACHER: (state, data) => {
    state.teacher = data
  },
  // 点击日历后选择的日期
  SET_CLICKTIME: (state, data) => {
    state.clicktime = data
  },
  // 点击的班级信息
  CLASS_TEXT: (state, data) => {
    state.classtext = data
  },
  // 点击章节后对应的章节信息
  SET_CLASSINFOR: (state, data) => {
    state.classinfor = data
  },
  //切换日期
  SET_DATE_CLASS: (state, data) => {
    state.dataclass = data
  },
  // 点击作业管理的信息
  SET_HOMEWORK: (state, data) => {
    state.homework = data
  },
  // 点击作业管理的信息
  SET_QUESTION: (state, data) => {
    state.question = data
  },
  // 通过日历的课程点击进入的详细信息
  SET_CHAPTER: (state, data) => {
    state.chapter = data
  },
  //设置中心的隐私组件的教师信息
  SET_PRIVACY: (state, data) => {
    state.privacy = data
  },

  //作业管理的查询条件信息
  SET_QUERY: (state, data) => {
    state.query = data
  },
  //点击查看章节下的班级作业信息
  SET_PAPERID: (state, data) => {
    state.paperid = data
  },
  //点击批改的试卷id
  SET_QUESTIONPAPER: (state, data) => {
    state.questionId = data
  },
  //这里的数据是为了记录从作业中心跳转到上课中心的标记
  SET_QUESTIONDATA: (state, data) => {
    state.questiondata = data
  },
  //下面是学生端信息
  //设置学生
  SET_STUDENT: (state, data) => {
    state.student = data
    localStorage.setItem('student', data.stuId);
  },
  //点击查看的课程
  SET_STU_COURSE: (state, data) => {
    state.stu_course = data
  },
  //这里是账号下的学生列表
  SET_STU_STULIST: (state, data) => {
    state.stu_list = data
  },
  //是否超过90天未登录
  SET_REMINDER: (state, data) => {
    state.reminder = data
  },
  //用于解决学生端登录后刷新从而影响90天提醒的问题、
  SET_STU_BUG: (state, data) => {
    state.stubug = data
  },
  //修改公开课
  SET_REVISEPUBLIC: (state, data) => {
    state.revisepublic = data
  },
  //公开课创建完或者改完后
  SET_PUBLICTEA: (state, data) => {
    state.publictea += data
  },
  //当前展示的公开课信息
  SET_PUBLIC_INFO: (state, data) => {
    state.publicInfo = data
  },
  //展示分享信息
  SET_PUBLIC_DATA: (state, data) => {
    state.publicData = data
    state.createOrUpdate = data.createOrUpdate
  },
  //初次进入课堂提示信息
  SET_CLASSREMINDERDATA: (state, data) => {
    state.classReminderData = data
  },
  SET_SECRETKEY: (state, data) => {
    state.secretKey = data
  },

};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
